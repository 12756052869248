import React, { useContext, useEffect, useState } from 'react'
import { Pagination, SearchAndAddBtn, THead } from '../../Components';
import { Card, CardBody, IconButton, Spinner } from "@material-tailwind/react";
import { NewContext } from '../../Context/NewContext';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoEyeOutline } from 'react-icons/io5';
import { BiEdit } from 'react-icons/bi';
import AssetsModel from '../../Components/Modals/AssetsModel';
import { get_asset } from '../../Controller/actions';


const Asset = ({ title }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    const { isLoading, setIsLoading, setDeleteUpdateYes, setAddEditDeleteTag, handleOpen, setFormData, setDeleteUpdateItem, setConfirmationModalShow, confirmationModalShow, setCurrentPageData, currentPageData, deleteUpdateYes, deleteUpdateTag, perPage, currentPage, totalPage, setCurrentPage, setPerPage, setTotalPage, tokenType, permissions
    } = useContext(NewContext);
    const simpleFields = [
        { name: "Index", sortable: false, field: 'Index' },
        { name: "Title", sortable: false, field: 'title' },
        { name: "Description", sortable: false, field: 'Description' },
    ];
    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });

    const getAssetsData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getSurvey = await get_asset(_currentPage, perPage);
            if (getSurvey) {
                const { status, data, page, perPage, totalPage } = getSurvey;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(page);
                    setPerPage(perPage);
                    setTotalPage(totalPage);
                } else {
                    setCurrentPageData([]);
                }
                setIsLoading(false);
            } else {
                setCurrentPageData([]);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getAssetsData(currentPage);
    }, [deleteUpdateTag, currentPage, perPage, deleteUpdateYes, pagePermissions]);



    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Assets Manager');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])

    const totalFields = simpleFields.length + 2;

    return (
        <div>
            <SearchAndAddBtn title={`Assets`} isSearch={false} countryFilter={false} perPageCount={true} addBtn={pagePermissions?.CU} />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left">
                        <THead simpleFields={simpleFields} isSelectAll={false} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50 '>

                                                    <td className={classes}>
                                                        <div className="flex items-center ml-4">
                                                            <span className={'font-normal text-[17px]'}>{i + 1}</span>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center break-words">
                                                            <span className={'font-normal text-[17px]'}>{_ele?.title || `None`}</span>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center break-words">
                                                            <span className={'font-normal text-[17px] truncate break-words w-96'}>{_ele?.description || `None`}</span>
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton onClick={() => { setDeleteUpdateYes("update-assets"); setAddEditDeleteTag(`update`); setFormData(_ele); handleOpen() }} disabled={!pagePermissions?.CU} className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'>
                                                                <BiEdit />
                                                            </IconButton>
                                                            <IconButton onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }} className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' >
                                                                <IoEyeOutline />
                                                            </IconButton>
                                                            <IconButton onClick={() => { setDeleteUpdateYes("delete-asset"); setDeleteUpdateItem({ id: _ele._id }); setConfirmationModalShow(!confirmationModalShow) }} disabled={!pagePermissions?.D} className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none'>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card >
            <Pagination totalPage={totalPage} currentPage={currentPage} getData={getAssetsData} />
            <AssetsModel />
        </div >
    )
}

Asset.defaultProps = {
    title: "My Photo Keyboard | Asset"
};

export default Asset
