import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody } from "@material-tailwind/react";
import RadioChart from './charts/RadioChart';
import CheckboxChart from './charts/CheckboxChart';
import RatingChart from './charts/RatingChart';
import NumberScaleChart from './charts/NumberScaleChart';
import OpenEndedQuestion from './charts/OpenEndedQuestion';
import { NewContext } from "../../Context/NewContext";
import { get_survey, survey_analysis } from '../../Controller/actions';
import { AiOutlineQuestion } from "react-icons/ai";
import { TbPointFilled } from "react-icons/tb";

const SurveyAnalysis = () => {
    const {
        setIsLoading,
        setCurrentPageData,
        currentPageData,
    } = useContext(NewContext);

    const [filterDropdown, setFilterDropdown] = useState({});
    const [surveyFilterDropdown, setSurveyFilterDropdown] = useState([]);
    const [openEndedAnswers, setopenEndedAnswers] = useState([]);

    const getSurveyData = async () => {
        setIsLoading(true);
        try {
            const getSurvey = await survey_analysis(filterDropdown);
            if (getSurvey) {
                const { status, data, openEndedAnswers } = getSurvey;
                if (status) {
                    setCurrentPageData(data);
                    setopenEndedAnswers(openEndedAnswers)
                } else {
                    setCurrentPageData([]);
                }
                setIsLoading(false);
            } else {
                setCurrentPageData([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const surveyFilterDropDown = async () => {
        setIsLoading(true);
        try {
            const getSurvey = await get_survey();
            if (getSurvey) {
                const { status, data } = getSurvey;
                if (status) {
                    setSurveyFilterDropdown(data);
                } else {
                    setSurveyFilterDropdown([]);
                }
                setIsLoading(false);
            } else {
                setSurveyFilterDropdown([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getSurveyData();
    }, [filterDropdown]);

    useEffect(() => {
        surveyFilterDropDown();
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterDropdown((prevFilterDropdown) => ({
            ...prevFilterDropdown,
            [name]: value,
        }));
    };

    const renderCharts = (question, openEndedAnswers, surveyId, questionId) => {
        switch (question.type) {
            case 'radio':
                return <RadioChart question={question} />;
            case 'checkbox':
                return <CheckboxChart question={question} />;
            case 'rating':
                return <RatingChart question={question} />;
            case 'numberscale':
                return <NumberScaleChart question={question} />;
            case 'text':
                return <OpenEndedQuestion openEndedAnswers={openEndedAnswers} surveyId={surveyId} questionId={questionId} />;
            default:
                return null;
        }
    };

    return (
        <div className="p-2.5 bg-[#FFFFFF]">
            <Card className={`overflow-hidden w-full rounded-md shadow-none col-span-12 `}>
                <div className='flex col-span-1 gap-5 my-2'>
                    <div className='ml-2 text-3xl text-black '>
                        Survey Analysis
                    </div>
                    <div className='w-80'>
                        <select value={filterDropdown.surveyName} onChange={handleFilterChange} name="surveyName" className="w-80 break-words bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="">Select Survey</option>
                            {surveyFilterDropdown?.map((survey) => (
                                <option size="1" key={survey.id} value={survey.name}>
                                    {survey.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <CardBody className="w-100 py-2.5 px-[5px]">
                    <div className="grid grid-cols-12 gap-2 ">
                        {currentPageData?.map((survey) => (
                            survey.question?.map((question) => (
                                <div key={question._id} className='col-span-6 border'>
                                    <div className='m-2 justify-item-center'>
                                        <div className='text-black font-bold capitalize text-xl'>
                                            <div className='flex gap-2' style={{ wordBreak: "break-word" }}>
                                                <AiOutlineQuestion className='mt-1' /> {question.text}
                                            </div>
                                        </div>
                                        <div className='ml-6 mt-5'>
                                            {question.answer?.map((answer) => (
                                                <div className='flex gap-2' style={{ wordBreak: "break-word" }}>
                                                    <TbPointFilled className='mt-1' /> {answer.text}
                                                </div>))}
                                        </div>
                                    </div>
                                    <div className='col-span-6'>
                                        {renderCharts(question, openEndedAnswers, survey.surveyId, question.quid)}
                                    </div>
                                </div>
                            ))
                        ))}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};
SurveyAnalysis.defaultProps = {
    title: "My Photo Keyboard | Survey Analysis"
};

export default SurveyAnalysis;
