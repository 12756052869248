import React, { useContext, useEffect, useState } from "react";
import { Button, Input } from "@material-tailwind/react";
import { v4 as uuidv4 } from "uuid";
import { MdDelete } from "react-icons/md";
import { NewContext } from "../../Context/NewContext";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { toast } from "react-toastify";

const QuestionModal = ({ show, handleClose, handleAddQuestion, selectedQuestion, questions, setQuestions, setShowQuestionModal, surveyName, questionType }) => {
    const initialQuestionState = selectedQuestion || {
        _id: uuidv4(),
        text: "",
        type: questionType || "",
        answer: [],
    };

    const {
        setMenuOpen,
        addEditDeleteTag,
    } = useContext(NewContext);
    const [question, setQuestion] = useState(initialQuestionState);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (question.text.trim() === "") {
            toast.error("Please enter a Question");
            return;
        }

        if (question.answer.some(option => option.text.trim() === "")) {
            toast.error("Please enter all answer options");
            return;
        }

        if (selectedQuestion) {
            const updatedQuestions = questions.map((q) =>
                q._id === selectedQuestion._id ? question : q
            );
            setQuestions(updatedQuestions);
        } else {
            handleAddQuestion(question);
        }
        setShowQuestionModal(false);
        setQuestion(initialQuestionState);
    };

    const handleAnswerOptionChange = (answerId, text) => {
        setQuestion((prevQuestion) => ({
            ...prevQuestion,
            answer: prevQuestion.answer.map((option) =>
                option._id === answerId ? { ...option, text } : option
            ),
        }));
    };

    const removeAnswerOption = (answerId) => {
        setQuestion((prevQuestion) => ({
            ...prevQuestion,
            answer: prevQuestion.answer.filter(
                (option) => option._id !== answerId
            ),
        }));
    };

    const addAnswerOption = () => {
        setQuestion((prevQuestion) => ({
            ...prevQuestion,
            answer: [
                ...prevQuestion.answer,
                { _id: uuidv4(), text: "" },
            ],
        }));
    };

    useEffect(() => {
        if (!selectedQuestion) {
            if (questionType === "radio") {
                setQuestion((prevQuestion) => ({
                    ...prevQuestion,
                    answer: Array.from({ length: 2 }, () => ({
                        _id: uuidv4(),
                        text: "",
                    })),
                }));
            } else if (questionType === "checkbox") {
                setQuestion((prevQuestion) => ({
                    ...prevQuestion,
                    answer: Array.from({ length: 4 }, () => ({
                        _id: uuidv4(),
                        text: "",
                    })),
                }));
            } else if (questionType === "numberscale") {
                const optionsCount = parseInt(question.text) || 5;
                setQuestion((prevQuestion) => ({
                    ...prevQuestion,
                    answer: Array.from({ length: optionsCount }, (_, index) => ({
                        text: `${index + 1}`,
                        count: 0
                    }))
                }));
            } else if (questionType === "rating") {
                setQuestion((prevQuestion) => ({
                    ...prevQuestion,
                    answer: [
                        { text: "1", count: 0 },
                        { text: "2", count: 0 },
                        { text: "3", count: 0 },
                        { text: "4", count: 0 },
                        { text: "5", count: 0 },
                    ],
                }));
            }
        } else {
            setQuestion(selectedQuestion);
        }
    }, [selectedQuestion, questionType]);

    return show ? (
        <>
            <div className=" w-full">
                <div className="my-4 font-medium text-gray-800 flex justify-center">
                    {surveyName || 'Please Add Survey Name'}
                </div>
            </div>
            <form onSubmit={handleSubmit} className="add-edit-form">
                <div className="col-span-6 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor={`question`}
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Question:
                        </label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <Input
                        id={`question-${question._id}`}
                        placeholder="Enter question"
                        value={question.text}
                        onChange={(e) =>
                            setQuestion((prevQuestion) => ({
                                ...prevQuestion,
                                text: e.target.value
                            }))
                        }
                        required={true}
                        maxLength={100}
                        autoComplete='off'
                    />
                </div>
                {questionType === "checkbox" || questionType === "radio" ? (
                    <div>
                        <div className="flex">
                            <label
                                htmlFor={`answerOptions-${question._id}`}
                                className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                            >
                                Options:
                                <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        {question.answer.map((option, index) => (
                            <div key={option._id} className="flex items-center mb-2">
                                <Input
                                    className=" block w-full border border-gray-300 rounded-md shadow-sm  sm:text-sm break-words"
                                    id={`answerOption-${question._id}-${option._id}`}
                                    placeholder={`Enter answer option ${index + 1}`}
                                    value={option.text}
                                    required={true}
                                    onChange={(e) =>
                                        handleAnswerOptionChange(
                                            option._id,
                                            e.target.value
                                        )
                                    }
                                />
                                {question.answer.length > 1 && (
                                    <button
                                        type="button"
                                        className="ml-2 text-red-500 focus:outline-none text-2xl"
                                        onClick={() =>
                                            removeAnswerOption(option._id)
                                        }
                                    >
                                        <MdDelete />
                                    </button>
                                )}
                            </div>
                        ))}
                        <Button type="button" onClick={addAnswerOption} className="flex items-center mr-1 mt-2 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base">
                            {'Add Options'}
                        </Button>
                    </div>
                ) : null}

                {questionType === "numberscale" && (
                    <div className="mt-4">
                        <label htmlFor={`numberscaleInput-${question._id}`} className="block text-sm font-medium leading-6 text-gray-900">
                            Choice Rating Between 1 to 10:
                        </label>
                        <Box sx={{ width: 300 }}>
                            <Slider
                                aria-label="Scale"
                                defaultValue={5}
                                valueLabelDisplay="auto"
                                shiftStep={1}
                                step={1}
                                marks
                                min={1}
                                max={10}
                                value={question?.answer?.length}
                                onChange={(e, value) =>
                                    setQuestion((prevQuestion) => ({
                                        ...prevQuestion,
                                        answer: Array.from({ length: value }, (_, index) => ({
                                            text: `${index + 1}`,
                                            count: 0,
                                        })),
                                    }))
                                }
                            />
                        </Box>
                    </div>
                )}

                <div className="flex justify-end mt-10">
                    <Button onClick={() => { handleClose(); setMenuOpen(false); }} className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base">
                        Cancel
                    </Button>
                    <Button type="submit" className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base">
                        {addEditDeleteTag === "update" ? `Save Changes` : `Add Question`}
                    </Button>
                </div>
            </form>
        </>
    ) : null;
};

export default QuestionModal;
