import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Input, Spinner } from "@material-tailwind/react";
import { update_Community_Reason, get_country_data, get_region_data, add_Community_Reason } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import { BiLink } from 'react-icons/bi';
import { MdContentCopy } from 'react-icons/md';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const ReasonModel = () => {
    const { isModalLoading, setDeleteUpdateTag, deleteUpdateYes, handleCopyData, handleSelectMultiCountry, handleSelectMultiRegion, handleSwitch, setIsModalLoading, handleClose, handleChange, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, multiCountry, setMultiCountry, multiRegion, setMultiRegion } = useContext(NewContext);

    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);

    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (countryData.status && regionData.status) {
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                regionData.data.map(_ele => !region.find(_e => _e?._id === _ele?._id) && setRegion(_prev => [..._prev, { _id: _ele?._id, value: _ele?.name?.toLowerCase(), label: capitalalizeFirstletter(_ele?.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                }
            } else {
                toast.error(countryData.status && regionData.status);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };

    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setDeleteUpdateTag(deleteUpdateYes);
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_Community_Reason({ ...formData, country: countryIdAry || [], region: formData?.region || [] }, formData?._id)
        } else {
            createUpdateSlider = await add_Community_Reason(formData)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                // setValidated(false);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "447px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Reason` : `Add New Reason`}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="col-span-6 sm:col-span-6">
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Reason:</label>
                                            <div className="mt-1.5">
                                                <Input id='reason' placeholder='Enter reason' name='reason' onChange={handleChange} value={formData?.reason} autoComplete='off' />
                                            </div>
                                        </div>


                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className='flex justify-end mt-8'>
                                        <Button
                                            onClick={handleClose}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            className="mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                            disabled={isModalLoading}
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                : null
                            // <div>
                            //     <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                            //         <div className="sm:col-span-6">
                            //             <label htmlFor="link" className="block text-lg font-medium leading-5 text-[#1E3354]">Link:</label>
                            //             <div className="flex items-center justify-between">
                            //                 <div className="mt-2 flex items-center text-[#2288FF]">
                            //                     <span className='mr-[5px]'><BiLink className='text-xl leading-none' /></span>
                            //                     <Typography>{formData?.link || `None`}</Typography>
                            //                 </div>
                            //                 {/* <div className=""> */}
                            //                 <Button variant='text' disabled={!formData?.link} className='p-3 rounded-full' onClick={() => handleCopyData(`Link`, formData?.link)}><MdContentCopy className='text-xl leading-none' /></Button>
                            //                 {/* </div> */}
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Reason:</label>
                            //             <div className="mt-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="from_flg" className="block text-lg font-medium leading-5 text-[#1E3354]">From Flag:</label>
                            //             <div className="mt-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.from_flg || `None`}</Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Priority:</label>
                            //             <div className="mt-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.priority}</Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Slider Tag:</label>
                            //             <div className="mt-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.slider_tag || `None`}</Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Region:</label>
                            //             <div className="mt-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                            //                     {formData?.region && formData?.region?.length !== 0 ? formData?.region?.map((_ele, key) => key === _ele.region?.length - 1 ? _ele : `${_ele}, `) : `None`}
                            //                 </Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Country:</label>
                            //             <div className="mt-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                            //                     {formData?.country && formData?.country?.length !== 0 ? formData?.country?.map((_ele, key) => key === _ele.country?.length - 1 ? _ele : `${_ele}, `) : `None`}
                            //                 </Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="city" className="block text-lg font-medium leading-5 text-[#1E3354]">Orientation:</label>
                            //             <div className="mt-2 grid grid-cols-2">
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.orientation || `None`}</Typography>
                            //             </div>
                            //         </div>
                            //         <div className="sm:col-span-3">
                            //             <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                            //             <div className="mt-2 ml-2">
                            //                 {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                            //                 <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "Off" : "On"}</Typography>
                            //             </div>
                            //         </div>
                            //     </div>
                            //     <div className='flex justify-end mt-10'>
                            //         <Button
                            //             onClick={handleClose}
                            //             className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                            //         >
                            //             <span>Cancel</span>
                            //         </Button>
                            //     </div>
                            // </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReasonModel
