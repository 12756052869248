import React, { useContext } from 'react'
import { Typography, Button, Tooltip } from "@material-tailwind/react";
import { NewContext } from '../../Context/NewContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const CommentsModel = () => {
    const { isLoading, setDeleteUpdateTag, deleteUpdateYes, handleSwitch, setIsLoading, handleClose, handleChange, formData, open, addEditDeleteTag } = useContext(NewContext);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "550px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    Comment view
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="mt-1 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                            <div className="sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">UserName:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Blog title:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.blogName}</Typography>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Email:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.email || `none`}</Typography>
                                </div>
                            </div>

                            {/* <div className="sm:col-span-6">
                                <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Website:</label>
                                <div className="mt-2 flex">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.website}</Typography>
                                </div>
                            </div> */}
                            <div className="sm:col-span-6">
                                <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Comment:</label>
                                <div className="mt-2 mb-2 max-h-[140px] h-[100%] overflow-auto">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.comment || `None`}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <Button
                                onClick={() => { handleClose(); }}
                                className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                            >
                                <span>Cancel</span>
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CommentsModel
