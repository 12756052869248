import React, { useContext } from 'react'
import { toast } from 'react-toastify';
import { Button, Input, Spinner } from "@material-tailwind/react";
import { update_blog_categories, add_blog_categories } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import Switch from '../Switch/Switch';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';

export const BlogCategoriesModel = () => {
    const { isLoading, setDeleteUpdateTag, deleteUpdateYes, handleSwitch, setIsLoading, handleClose, handleChange, formData, open, addEditDeleteTag } = useContext(NewContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let createUpdateSlider;
        setIsLoading(true);
        if (addEditDeleteTag === `update`) {
            createUpdateSlider = await update_blog_categories({ ...formData }, formData?._id)
        } else {
            createUpdateSlider = await add_blog_categories(formData)
        }
        setIsLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
            } else {
                toast.error(message);
            };
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "447px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {/* {addEditDeleteTag === 'update' ? `Edit Blog Categories` : `Add Blog Categories`} */}
                    {addEditDeleteTag === 'add' ? `Add Blog Categories` : `Update Blog Categories`}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <>
                                    {
                                        addEditDeleteTag === 'update' ? <div className="">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Last update : {moment(formData?.updatedAt).format('DD/MM/YYYY,hh:mm A')}</label>
                                        </div> : null
                                    }
                                    <form onSubmit={handleSubmit}>
                                        <div className="mt-1 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                            <div className="col-span-6 sm:col-span-6">
                                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">name:</label>
                                                <div className="mt-1">
                                                    <Input id='name' placeholder='Enter name' name='name' onChange={handleChange} value={formData?.name} autoComplete='off' required={true} />
                                                </div>
                                            </div>

                                            {
                                                addEditDeleteTag === 'update' ? <div className="col-span-2 sm:col-span-1">
                                                    <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                                    <div className="mt-1.5">
                                                        <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} defaultChecked={true} defaultValue={true} />
                                                    </div>
                                                </div> : null
                                            }

                                        </div>
                                        <div className='flex justify-end mt-8'>
                                            <Button
                                                onClick={handleClose}
                                                className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                            <Button
                                                className="inline-flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                                type='submit'
                                                disabled={isLoading}
                                            >
                                                {isLoading && <Spinner className="h-4 w-4" />}
                                                <span className={isLoading && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                            </Button>
                                        </div>
                                    </form>
                                </>
                                : null
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default BlogCategoriesModel
