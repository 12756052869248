import { useContext, useEffect, useState } from "react";
import { Button, Input } from '@material-tailwind/react'
import { toast } from 'react-toastify'
import { Box, Dialog, DialogContent, DialogTitle, Modal } from '@mui/material'
import { get_Community_Reason } from '../../Controller/actions';
import { NewContext } from "../../Context/NewContext";

export const ReasonListModel = () => {
    const { formData, update_approvedTheme, setDeleteUpdateItem, disapproveReason, setDisapproveReason, setDeleteUpdateYes, confirmationModalShow, setConfirmationModalShow, setIsLoading, openReason, handleopenReasonClose, isSingleThemeRejected, checkedValues } = useContext(NewContext);
    const [Themereson, setThemereson] = useState([]);
    const [show, setShow] = useState();
    // const [disapproveReason, setDisapproveReason] = useState();

    function onChangeValue(e) {
        // console.log(e.target.value, '==========EEE======');
        setDisapproveReason(e.target.value)

    }
    const handleRadioButton = (item) => {
        setDisapproveReason(item)
    }
    function handleshow() {
        setDisapproveReason("");
        setShow(!show)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let createUpdateReason = await update_approvedTheme(...formData, formData?._id)
        if (createUpdateReason) {
            const { message, status } = createUpdateReason;
            if (status) {
                toast.success(message);
                handleopenReasonClose();
            } else {
                toast.error(message);
            };
        }
    }
    useEffect(() => {
        setShow(false);
        get_Community_Reason().then((res) => {
            setThemereson(res.data)
        })
    }, [])

    return (
        <>
            <Dialog
                open={openReason}
                onClose={handleopenReasonClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "565px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle >
                    <div className="mb-[10px] text-[#1E1627] text-[28px] font-medium leading-none">
                        {'Reason'}
                    </div>
                </DialogTitle>
                <DialogContent >
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="mb-2 grid grid-cols-12">
                                <div className="col-span-12" style={{ height: "250px", overflow: "auto" }}>
                                    <table className='w-full max-w-max text-left table-auto'>
                                        <tbody className='text-[17px] text-[#75869F] leading-8'>
                                            {
                                                show ?
                                                    <tr>
                                                        <td></td>
                                                        <div className="col-span-6 sm:col-span-6">
                                                            <div className="mt-1.5">
                                                                <Input id='Reason' placeholder='Enter Other Reason...' type='text' name='Reason' onChange={onChangeValue} />
                                                            </div>
                                                        </div>
                                                    </tr> :
                                                    <tr>
                                                        <td></td>
                                                        <td><button onClick={() => handleshow()}>Other...</button></td>
                                                    </tr>
                                            }
                                            {
                                                Themereson?.map((item, i) => {
                                                    return (
                                                        <>
                                                            {
                                                                item.isActive == true ?
                                                                    <tr className="cursor-pointer" onClick={() => { handleRadioButton(item?.reason) }}>
                                                                        <td className='text-[#1E1627] p-[5.5px] '>
                                                                            <div className="">
                                                                                <input type="radio" className="p-2" value={item?.reason} name="reson" checked={item?.reason == disapproveReason} />
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.reason}</td>
                                                                    </tr> : null
                                                            }

                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className='flex flex-row justify-end  gap-3'>
                                <Button onClick={() => { handleopenReasonClose(); }} className="px-5 py-1.5 bg-transparent shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-xs md:text-base text-center">
                                    Cancel
                                </Button>
                                {
                                    isSingleThemeRejected ?
                                        <Button disabled={!disapproveReason} className="px-5 py-1.5 bg-transparent shadow-none bg-[#0047FF] text-white font-medium border border-[#75869F] hover:shadow-none capitalize text-xs md:text-base text-center" onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: formData?._id, themeStatus: 'reject', disapproveReason }); setConfirmationModalShow(!confirmationModalShow); handleopenReasonClose(); }} >
                                            Save
                                        </Button> : <Button disabled={!disapproveReason} className="px-5 py-1.5 bg-transparent shadow-none bg-[#0047FF] text-white font-medium border border-[#75869F] hover:shadow-none capitalize text-xs md:text-base text-center" onClick={() => { setDeleteUpdateYes("all-rejected-theme"); setDeleteUpdateItem({ id: checkedValues, disapproveReason }); setConfirmationModalShow(!confirmationModalShow); handleopenReasonClose(); }} >
                                            Save
                                        </Button>
                                }
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReasonListModel
