import { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, IconButton, Input, Option, Radio, Select, Spinner, Typography } from '@material-tailwind/react'
import { toast } from 'react-toastify'
import { Box, Dialog, DialogContent, Modal } from '@mui/material'

import { NewContext } from "../../../Context/NewContext";
import { get_non_active_slider_data, add_banners, add_custom_button, add_functionality_fonts, add_slider, add_sticker_store, add_tab, get_country_data, get_region_data, get_slider_tag, update_banners, update_custom_button, update_functionality_fonts, update_slider, update_sticker_store, update_tab, get_non_active_functionality_data, get_non_active_theme_data, get_non_active_gamezone_data, get_non_custom_button_data, add_slider_tags } from '../../../Controller/actions'
import FileInput from '../../FileInput/FileInput';
import Switch from '../../Switch/Switch';
import { MultiSelect } from "react-multi-select-component";
import THead from "../../Table/THead";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Pagination } from "../../Pagination";

const style = {
    position: 'absolute',
    top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2, px: 4, pb: 3,
};

export const ChildModal = ({ homeActiveLength }) => {
    const { homeActivityRef, imgObj, setImgObj, handleSingleSelectChild, setAddEditDeleteTag, updateStateAryChildData, childModalTotalPage, childModalperPage, childModalTableData, setChildModalTableData, childModalCurrentPage, setChildModalCurrentPage, setchildModalTotalPage, setChildModalPerPage, isSubModalLoading, setIsSubModalLoading, handleSwitchUpdate, setDeleteUpdateYes, setMultiCountry, updateStateAryData, handleSelectMultiCountry, capitalalizeFirstletter, setMultiRegion, multiRegion, multiCountry, childFormData, childOpen, handleChildClose, handleChildChange, handleChildSwitch, setDeleteUpdateTag, deleteUpdateYes, addEditDeleteTag, currentHomeModalKey, setChildFormData } = useContext(NewContext);
    const [fileInputProps, setFileInputProps] = useState({})
    const [previewField, setPreviewField] = useState("");
    const [dbPreviewField, setDbPreviewField] = useState("");
    const [isUpdateSubModalLoading, setIsUpdateSubModalLoading] = useState(false);
    const [tag, setTag] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [multiTag, setMultiTag] = useState([]);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const handleSelectMultiTags = (e) => {
        if (e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item.value);
                // if (_ele.name === 'slider_tag') {
                setMultiTag([{ ...e[0], disabled: false }]);
                setChildFormData({ ...childFormData, [_ele.name]: multiSelectAry[0] });
                if (e.length == 1) {
                    let selectedSliderTag = tag.filter(_ele => e[0]._id === _ele._id);
                    let nonSelectedSliderTag = tag.filter(_ele => e[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                    setTag(selectedSliderTag.concat(nonSelectedSliderTag));
                }
                // }
            })
        } else {
            let tags = tag.map(_ele => ({ ..._ele, disabled: false }));
            setTag(tags);
            setMultiTag([]);
            setChildFormData({ ...childFormData, slider_tag: [] })
        }
        homeActivityRef.current.focus();
    }
    const handleNewField = async (_value) => {
        let tagName;
        if (currentHomeModalKey === `slider`) {
            tagName = 'slider_tag'
        } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) {
            tagName = 'banner_view_type'
        } else {
            tagName = 'activity_name'
        }
        let { status, data } = await add_slider_tags({ name: _value });
        if (status) {
            tag.unshift({ _id: data._id, value: data.name, label: data.name, name: tagName, disabled: false });
            homeActivityRef.current.focus();
            return {
                _id: data._id,
                name: tagName,
                disabled: false,
                label: data.name,
                value: data.name,
            }
        }
    };
    const simpleFields = [
        { name: "Preview", },
        { name: ['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey) ? "Redirect To" : "Name" },
        { name: "Active", },
    ];
    currentHomeModalKey === 'wallpaper' && simpleFields.splice(-1);
    const totalFields = simpleFields.length + 2;
    const getDataFunc = async () => {
        setIsUpdateSubModalLoading(true);
        try {
            const tagData = await get_slider_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (tagData.status && countryData.status && regionData.status) {
                let tagName, tagValueName;
                if (currentHomeModalKey === `slider`) {
                    tagName = 'slider_tag'
                    tagValueName = 'slider_tag'
                } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) {
                    tagName = 'banner_view_type'
                    tagValueName = 'banner_view_type'
                } else {
                    tagName = 'activity_name'
                    tagValueName = 'activity_name'
                }

                tagData.data.map(_ele => {
                    if (tag.find(_e => _e._id === _ele._id)) {
                        if (tag.find(_e => _e.name !== _ele.name)) {
                            let filterData = tag.map(_t => ({ ..._t, name: tagName }))
                            setTag(filterData);
                        }
                    } else {
                        _ele.name && setTag(_prev => [..._prev, { _id: _ele?._id, value: _ele?.name && _ele?.name?.toLowerCase(), label: _ele?.name, name: tagName }])
                    }
                })
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update-c`) {
                    let oldTagData = updateStateAryChildData([childFormData[tagValueName]], tagData.data, tagName);
                    let oldCountryData = updateStateAryChildData(childFormData?.country, countryData.data, 'country');
                    setMultiTag(oldTagData);
                    setMultiCountry(oldCountryData);
                    if (oldTagData.length == 1) {
                        let newCategoryData = tagData.data.map(_ele => ({ _id: _ele._id, value: _ele.name && _ele.name?.toLowerCase(), label: _ele.name, name: tagName }));
                        let selectedSliderTag = newCategoryData.filter(_ele => oldTagData[0]._id === _ele._id);
                        let nonSelectedSliderTag = newCategoryData.filter(_ele => oldTagData[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                        setTag(selectedSliderTag.concat(nonSelectedSliderTag));
                    }
                }
            } else {
                toast.error(countryData.message || regionData.message || tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsUpdateSubModalLoading(false);
    };
    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag, currentHomeModalKey, childOpen]); // childFormData, currentHomeModalKey
    const handleSubmit = async (e) => {
        e.preventDefault();
        let createUpdateSlider;
        setIsSubModalLoading(true);
        if (currentHomeModalKey === `slider`) {
            if (addEditDeleteTag === `update-c`) {
                createUpdateSlider = await update_slider(childFormData, childFormData?._id)
            } else {
                createUpdateSlider = await add_slider(childFormData)
            }
        } else if (currentHomeModalKey === `custom_second_sec`) {
            if (addEditDeleteTag === `update-c`) {
                createUpdateSlider = await update_custom_button(childFormData, childFormData?._id)
            } else {
                createUpdateSlider = await add_custom_button(childFormData)
            }
        } else if (currentHomeModalKey === `functionality`) {
            if (addEditDeleteTag === `update-c`) {
                createUpdateSlider = await update_functionality_fonts(childFormData, childFormData?._id)
            } else {
                createUpdateSlider = await add_functionality_fonts(childFormData)
            }
        } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) {
            if (addEditDeleteTag === `update-c`) {
                createUpdateSlider = await update_banners(childFormData, childFormData?._id)
            } else {
                createUpdateSlider = await add_banners(childFormData)
            }
        } else if (currentHomeModalKey === `theme`) {
            if (addEditDeleteTag === `update-c`) {
                createUpdateSlider = await update_tab(childFormData, childFormData?._id)
            } else {
                createUpdateSlider = await add_tab(childFormData)
            }
        } else if (currentHomeModalKey === `sticker`) {
            if (addEditDeleteTag === `update-c`) {
                createUpdateSlider = await update_sticker_store(childFormData, childFormData?._id)
            } else {
                createUpdateSlider = await add_sticker_store(childFormData)
            }
        } else {
            setIsSubModalLoading(false);
            return toast.error('API Not Provided!')
        }
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleChildClose();
                setDeleteUpdateTag(deleteUpdateYes);
                setAddEditDeleteTag("update");
                setTag([]);
                setChildFormData({});
                setMultiTag([]);
                setImgObj({});
            } else {
                toast.error(message);
            };
        }
        setIsSubModalLoading(false);
    }
    useEffect(() => {
        if (currentHomeModalKey === `slider`) {
            setFileInputProps({ previewField: `previewSlider`, dbPreviewField: `preview_img`, width: 1080, height: 411, acceptedExtensions: /\.(jpg|png|webp)$/ });
        } else if (currentHomeModalKey === `custom_second_sec`) {
            setFileInputProps({ previewField: `customPreview`, dbPreviewField: `preview`, width: 680, height: 324, acceptedExtensions: /\.(jpg|png|webp)$/ });
        } else if (currentHomeModalKey === `functionality`) {
            setFileInputProps({ previewField: `functionalityPreview`, dbPreviewField: `preview_img`, width: 128, height: 128, acceptedExtensions: /\.(jpg|png)$/ });
        } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) {
            setFileInputProps({ previewField: `homescreenBanneres`, dbPreviewField: `banner_image_url`, width: 1080, height: 342, acceptedExtensions: /\.(jpg|png|webp)$/ });
        } else if (currentHomeModalKey === `theme`) {
            setFileInputProps({ previewField: `themeTabPreview`, dbPreviewField: `preview_img`, width: 48, height: 48, acceptedExtensions: /\.(jpg|png)$/ });
        } else if (currentHomeModalKey === `sticker`) {
            setFileInputProps({ previewField: `smallPreviewStickerStore`, dbPreviewField: `small_preview`, width: 640, height: 437, acceptedExtensions: /\.(jpg|png|webp)$/ });
        }
    }, [currentHomeModalKey])
    const handleDeleteUpdateYes = (_tag) => {
        if (currentHomeModalKey === `slider`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-slider");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-slider");
            }
        } else if (currentHomeModalKey === `functionality`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-functionality");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-functionality");
            }
        } else if (currentHomeModalKey === `custom_second_sec`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-customsection");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-customsection");
            }
        } else if (currentHomeModalKey === `theme`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-themetab");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-themetab");
            }
        } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-banner");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-banner");
            }
        }
    }
    const getSliderData = async (_currentPage = 1) => {
        if (childOpen) {
            setIsSubModalLoading(true);
            try {
                let getSliders
                if (currentHomeModalKey === `slider`) {
                    getSliders = await get_non_active_slider_data(_currentPage, childModalperPage);
                } else if (currentHomeModalKey === `functionality`) {
                    getSliders = await get_non_active_functionality_data(_currentPage, childModalperPage);
                } else if (currentHomeModalKey === `theme`) {
                    getSliders = await get_non_active_theme_data(_currentPage, childModalperPage);
                } else if (currentHomeModalKey === 'custom_second_sec') {
                    getSliders = await get_non_custom_button_data(_currentPage, childModalperPage);
                } else if (currentHomeModalKey?.toLowerCase() === 'game') {
                    getSliders = await get_non_active_gamezone_data(_currentPage, childModalperPage);
                } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) {
                    // getSliders = await get_non_active_gamezone_data(_currentPage, childModalperPage);
                }
                // const getTags = await get_slider_tag(1, "", "", { isActive: true });
                if (getSliders) {
                    const { status, data, currentPage, perPage, totalPage } = getSliders;
                    if (status) {
                        setChildModalTableData(data);
                        setChildModalCurrentPage(currentPage);
                        setChildModalPerPage(perPage);
                        setchildModalTotalPage(totalPage);
                    } else {
                        setChildModalTableData([]);
                    }
                }
            } catch (error) {
                console.error(error);
            }
            setIsSubModalLoading(false);
        }
    }
    useEffect(() => {
        getSliderData(childModalCurrentPage);
        setDeleteUpdateTag("");
    }, [childModalperPage, currentHomeModalKey, childOpen]);

    return (
        <Dialog
            open={childOpen}
            onClose={() => { handleChildClose(); setTag([]); setMultiTag([]); setAddEditDeleteTag(`add`); setImgObj({ ...imgObj, [fileInputProps.previewField]: undefined, [fileInputProps.dbPreviewField]: undefined }) }} //  setTag([]); setMultiTag([]);
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: `${currentHomeModalKey === 'slider' ? "800px" : "567px"}`,
                        borderRadius: "12px"
                    },
                },
            }}
        >
            <DialogContent>
                {
                    addEditDeleteTag === 'view' ?
                        <>
                            <Card className="overflow-hidden w-full rounded-md shadow-none">
                                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                                    <table className="w-full min-w-max text-left">
                                        <THead simpleFields={simpleFields} actionField={false} isSelectAll={false} />
                                        <tbody className='!min-h-16 !overflow-y-auto'>
                                            {
                                                isSubModalLoading ?
                                                    <tr>
                                                        <td colSpan={totalFields} className='py-12'>
                                                            <div className="flex justify-center items-center">
                                                                <Spinner />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    : childModalTableData?.length === 0 ?
                                                        <tr>
                                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                                <div className="flex justify-center items-center">
                                                                    No Data Found
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        childModalTableData?.map((_ele, i) => {
                                                            const isLast = i === childModalTableData?.length - 1;
                                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                                            return (
                                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                                    <td className={classes}>
                                                                        <img key={i} src={_ele.preview_img || _ele.preview || _ele.banner_image_url || _ele.mediumPreview640 || _ele.small_preview || _ele?.urls?.regular || _ele?.thumbnail_small_path || `../../../assets/images/no-image.png`} alt={_ele.name || _ele.alt_description} className='h-[55px]' />
                                                                    </td>
                                                                    <td className={classes}>
                                                                        <span className={`font-normal text-[17px] capitalize`}>{_ele.name || _ele.banner_view_type || _ele.theme_type || _ele.alt_description || _ele.title || `None`}</span>
                                                                    </td>
                                                                    {
                                                                        currentHomeModalKey !== 'wallpaper' && ["isActive"].map((_is_ele, i) => (
                                                                            <td className={classes} key={i}>
                                                                                {
                                                                                    currentHomeModalKey === `theme` ?
                                                                                        <Switch id={_ele._id} name={`show_in_homescreen`} onChange={(e) => { handleDeleteUpdateYes(`update`); handleSwitchUpdate(e); }} checked={_ele.show_in_homescreen} />
                                                                                        : <Switch id={_ele._id} name={_is_ele} onChange={(e) => { handleDeleteUpdateYes(`update`); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                                                                }
                                                                            </td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            );
                                                        })
                                            }
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                            <div className="flex justify-between">
                                {
                                    childModalTotalPage > 1 ?
                                        <Pagination currentPage={childModalCurrentPage} totalPage={childModalTotalPage} getData={getSliderData} />
                                        : <div></div>
                                }
                                <Button
                                    onClick={() => { handleChildClose(); setTag([]); setMultiTag([]); setAddEditDeleteTag(`add`); }} // setTag([]); setMultiTag([]);
                                    className="mt-4 mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                >
                                    <span>Cancel</span>
                                </Button>
                            </div>
                        </>
                        :
                        <form onSubmit={handleSubmit}>
                            <div className="">
                                <div className="">
                                    <Typography className="text-lg text-[#1E1627] font-medium">Preview {!['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey) && `(${fileInputProps.width} x ${fileInputProps.height})`}:</Typography>
                                    <div className='w-auto'>
                                        <FileInput name={fileInputProps.previewField} imgClass={`h-full`} previewFields={fileInputProps.dbPreviewField || ''} accept={'image/*'} isChildModal={true} isImage={true} validation={["slider", "custom_second_sec", "functionality", "theme"].includes(currentHomeModalKey)} width={fileInputProps.width} height={fileInputProps.height} acceptedExtensions={fileInputProps.acceptedExtensions} />
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                    {
                                        !['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey) &&
                                        <>
                                            <div className={`col-span-6 sm:col-span-${(currentHomeModalKey === 'slider' && '2') || (currentHomeModalKey === 'theme' && '3') || '6'}`}>
                                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                                <div className="mt-1.5">
                                                    <Input disabled={['theme'].includes(currentHomeModalKey) && addEditDeleteTag === 'update-c'} id='name' placeholder='Enter Name' name='name' onChange={handleChildChange} value={childFormData?.name || ''} autoComplete='off' required />
                                                </div>
                                            </div>
                                        </>
                                    }{
                                        currentHomeModalKey === 'slider' && <>
                                            {
                                                ((addEditDeleteTag !== 'update-c') || (['insta', 'gamezone'].includes(childFormData?.slider_tag)) && (addEditDeleteTag === 'update-c')) &&
                                                <div className="col-span-6 sm:col-span-2">
                                                    {/* <label htmlFor="link" className="block text-sm font-medium leading-6 text-gray-900">Redirect Link:</label> */}
                                                    <label htmlFor="link" className="block text-sm font-medium leading-6 text-gray-900">External URL Redirection:</label>
                                                    <div className="mt-1.5">
                                                        <Input id='link' placeholder='Enter External URL Redirection' name='link' onChange={handleChildChange} value={childFormData?.link || ''} />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (['theme'].includes(childFormData?.slider_tag)) &&
                                                <div className="col-span-6 sm:col-span-2">
                                                    {/* <label htmlFor="from_flg" className="block text-sm font-medium leading-6 text-gray-900">From Flag:</label> */}
                                                    <label htmlFor="from_flg" className="block text-sm font-medium leading-6 text-gray-900">Recommanded Theme Tag Name:</label>
                                                    <div className="mt-1.5">
                                                        <Input id='from_flg' placeholder='Enter Recommanded Theme Tag Name' name='from_flg' onChange={handleChildChange} value={childFormData?.from_flg || ''} />
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="slider_tag" className="block text-sm font-medium leading-6 text-gray-900">Redirect Page Key:</label>
                                                <div className="mt-1.5">
                                                    <MultiSelect
                                                        labelledBy='Select Slider Tag'
                                                        options={tag}
                                                        value={multiTag}
                                                        onChange={handleSelectMultiTags}
                                                        hasSelectAll={false}
                                                        isCreatable={true}
                                                        onCreateOption={handleNewField}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                (['theme', 'sticker'].includes(childFormData?.slider_tag)) &&
                                                <div className="col-span-6 sm:col-span-2">
                                                    <label htmlFor="theme_tagname" className="block text-sm font-medium leading-6 text-gray-900">Redirect To (Theme Or Sticker):</label>
                                                    <div className="mt-1.5">
                                                        <Input id='theme_tagname' placeholder='Redirect To (Theme Or Sticker)' name='theme_tagname' onChange={handleChildChange} value={childFormData?.theme_tagname || ''} />
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="priority" className="block text-sm font-medium leading-6 text-gray-900">Priority:</label>
                                                <div className="mt-1.5">
                                                    <Input id='priority' placeholder='Enter Priority' type='number' name='priority' onChange={handleChildChange} value={childFormData?.priority ? childFormData?.priority : homeActiveLength} />
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                                <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                                    {
                                                        country && country.length > 0 &&
                                                        <MultiSelect
                                                            labelledBy='Select Country'
                                                            options={country}
                                                            value={multiCountry}
                                                            onChange={handleSelectMultiCountry}
                                                            hasSelectAll={false}
                                                            ClearSelectedIcon={null}
                                                            isOpen={isOpenCountryDropDown}
                                                        />
                                                    }
                                                    <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                                </div>
                                            </div>
                                            {
                                                ((addEditDeleteTag !== 'update-c') || (['game'].includes(childFormData?.slider_tag)) && (addEditDeleteTag === 'update-c')) &&
                                                <div className="col-span-6 sm:col-span-2">
                                                    <label htmlFor="orientation" className="block text-sm font-medium leading-6 text-gray-900">Orientation:</label>
                                                    <div className="mt-1.5 grid grid-cols-2">
                                                        <div className='flex'>
                                                            <Radio
                                                                id="portrait"
                                                                name="orientation"
                                                                ripple={false}
                                                                containerProps={{ className: "p-0" }}
                                                                value="portrait"
                                                                onChange={handleChildChange}
                                                                checked={childFormData?.orientation === "portrait"}
                                                                label={`Portrait`}
                                                                labelProps={{ className: "font-medium ml-2" }}
                                                            />
                                                        </div>
                                                        <div className='flex'>
                                                            <Radio
                                                                name="orientation"
                                                                id="horizontal-list-vue"
                                                                ripple={false}
                                                                containerProps={{ className: "p-0" }}
                                                                value="landscape"
                                                                onChange={handleChildChange}
                                                                checked={childFormData?.orientation === "landscape"}
                                                                label={`Landscape`}
                                                                labelProps={{ className: "font-medium ml-2" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-span-3 sm:col-span-1">
                                                <label htmlFor="isPremium" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                                <div className="mt-1.5">
                                                    <Switch color='green' id='isPremium' name='isPremium' onChange={handleChildSwitch} checked={childFormData?.isPremium} />
                                                </div>
                                            </div>
                                        </>
                                    }{
                                        currentHomeModalKey !== 'slider' && <>
                                            {
                                                (currentHomeModalKey === 'functionality' || !['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) &&
                                                <>
                                                    {
                                                        ((addEditDeleteTag !== 'update-c' && currentHomeModalKey === 'theme') || currentHomeModalKey === 'functionality') &&
                                                        <div className={`col-span-6 sm:col-span-${(currentHomeModalKey === 'theme' && '3') || '6'}`}>
                                                            <label htmlFor="priority" className="block text-sm font-medium leading-6 text-gray-900">Priority:</label>
                                                            <div className="mt-1.5">
                                                                <Input id='priority' placeholder='Enter Priority' type='number' name='priority' onChange={handleChildChange} value={childFormData?.priority} />
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {
                                                (currentHomeModalKey !== 'theme' && currentHomeModalKey !== 'functionality' && currentHomeModalKey !== 'custom_second_sec' && !['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) &&
                                                <div className="col-span-6 sm:col-span-6">
                                                    <label htmlFor="activity_name" className="block text-sm font-medium leading-6 text-gray-900">Redirect To:</label>
                                                    <div className="mt-1.5 relative">
                                                        {/* <Select onChange={(e) => handleSingleSelectChild(['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound'].includes(currentHomeModalKey) ? 'banner_view_type' : 'activity_name', e)} value={childFormData?.activity_name?.toLowerCase() || childFormData?.banner_view_type?.toLowerCase() || ''}>
                                                            {tag?.map((_ele, i) => <Option key={i} value={_ele.name?.toLowerCase()}>{_ele?.name}</Option>)}
                                                        </Select> */}
                                                        <MultiSelect
                                                            labelledBy='Select Slider Tag'
                                                            options={tag}
                                                            value={multiTag}
                                                            onChange={handleSelectMultiTags}
                                                            hasSelectAll={false}
                                                            isCreatable={true}
                                                            onCreateOption={handleNewField}
                                                        />
                                                        <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (currentHomeModalKey !== 'theme' && currentHomeModalKey === 'functionality' || currentHomeModalKey === 'custom_second_sec' || ['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)) &&
                                                <>
                                                    <div className="col-span-6 sm:col-span-6">
                                                        <label htmlFor="activity_name" className="block text-sm font-medium leading-6 text-gray-900">Redirect To:</label>
                                                        <div className="mt-1.5 relative">
                                                            {/* <Select onChange={(e) => handleSingleSelectChild(['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound'].includes(currentHomeModalKey) ? 'banner_view_type' : 'activity_name', e)} value={childFormData?.activity_name?.toLowerCase() || childFormData?.banner_view_type?.toLowerCase() || ''}>
                                                                {tag?.map((_ele, i) => <Option key={i} value={_ele.name?.toLowerCase()}>{_ele?.name}</Option>)}
                                                            </Select> */}
                                                            <MultiSelect
                                                                labelledBy='Select Slider Tag'
                                                                options={tag}
                                                                value={multiTag}
                                                                onChange={handleSelectMultiTags}
                                                                hasSelectAll={false}
                                                                isCreatable={true}
                                                                onCreateOption={handleNewField}
                                                            />
                                                            <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6 sm:col-span-6">
                                                        <label htmlFor="redirect_link" className="block text-sm font-medium leading-6 text-gray-900">Redirect Link:</label>
                                                        <div className="mt-1.5">
                                                            <Input id='redirect_link' placeholder='Enter Redirect Link' name='redirect_link' onChange={handleChildChange} value={childFormData?.redirect_link || ''} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }{
                                        currentHomeModalKey === 'theme' &&
                                        <>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="data_limit_for_homescreen" className="block text-sm font-medium leading-6 text-gray-900">Homescreen Limit:</label>
                                                <div className="mt-1.5">
                                                    <Input id='data_limit_for_homescreen' placeholder='Enter Home Screen Limit' type='number' name='data_limit_for_homescreen' onChange={handleChildChange} value={childFormData?.data_limit_for_homescreen} />
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="priority_for_homescreen" className="block text-sm font-medium leading-6 text-gray-900">Homescreen Priority:</label>
                                                <div className="mt-1.5">
                                                    <Input id='priority_for_homescreen' placeholder='Enter Home Screen Priority' type='number' name='priority_for_homescreen' onChange={handleChildChange} value={childFormData?.priority_for_homescreen} />
                                                </div>
                                            </div>
                                            {/* <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">Region:</label>
                                        <div className="mt-1.5">
                                            {
                                                region && region.length > 0 &&
                                                <MultiSelect
                                                    labelledBy='Select Region'
                                                    options={region}
                                                    value={multiRegion}
                                                    onChange={handleSelectMultiRegion}
                                                    hasSelectAll={false}
                                                    ClearSelectedIcon={null}
                                                />
                                            }
                                        </div>
                                    </div> */}
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                                <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }} >
                                                    {
                                                        country && country.length > 0 &&
                                                        <MultiSelect
                                                            labelledBy='Select Country'
                                                            options={country}
                                                            value={multiCountry}
                                                            onChange={handleSelectMultiCountry}
                                                            hasSelectAll={false}
                                                            ClearSelectedIcon={null}
                                                            isOpen={isOpenCountryDropDown}
                                                        />
                                                    }
                                                    <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="show_in_homescreen" className="block text-sm font-medium leading-6 text-gray-900">Show Homescreen:</label>
                                                <div className="mt-1.5">
                                                    <Switch color='green' id='show_in_homescreen' name='show_in_homescreen' onChange={handleChildSwitch} checked={childFormData?.show_in_homescreen} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-span-3 sm:col-span-1">
                                        <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='isActive' name='isActive' onChange={handleChildSwitch} checked={childFormData?.isActive} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end mt-10'>
                                    <Button
                                        onClick={() => { handleChildClose(); setTag([]); setMultiTag([]); setAddEditDeleteTag(`add`); setImgObj({ ...imgObj, [fileInputProps.previewField]: undefined, [fileInputProps.dbPreviewField]: undefined }) }} // setTag([]); setMultiTag([]);
                                        className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                    <Button
                                        disabled={isSubModalLoading || isUpdateSubModalLoading}
                                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                        type='submit'
                                    >
                                        {(isSubModalLoading || isUpdateSubModalLoading) && <Spinner className="h-4 w-4" />}
                                        <span className={(isSubModalLoading || isUpdateSubModalLoading) && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                    </Button>
                                </div>
                            </div>
                        </form >
                }
            </DialogContent>
        </Dialog >
    );
}

export default ChildModal;