import React, { useContext, useState, useEffect } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";
import { IoEyeOutline, IoImageOutline } from 'react-icons/io5';

import { CommunityBtn, Dropdowns, Pagination, SearchAndAddBtn, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_Community_Theme_data } from '../../../Controller/actions';
import { RiDeleteBinLine } from 'react-icons/ri';
import ApprovedThemesModal from '../../../Components/Modals/ApprovedThemesModal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { HiDownload } from 'react-icons/hi';
import Zoom from 'react-medium-image-zoom';
import ReasonListModel from '../../../Components/Modals/ReasonListModel';

const FilterDropdowns = ({ dropData }) => {
  return (
    <div className='flex gap-4'>
      <Dropdowns title={`Backgorund Type`} name={"backgroundType"} Icon={IoImageOutline} dropData={[{ id: 2, label: 'Custom' }, { id: 3, label: 'Background' }, { id: 4, label: 'Gif' }, { id: 5, label: 'Unsplash' }]} />
      {/* <Dropdowns title={`Backgorund Type`} name={"backgroundType"} Icon={IoImageOutline} dropData={dropData} /> */}
      <Dropdowns title={`Total Downloads`} name={"download"} Icon={HiDownload} dropData={[]} download={true} />
    </div>
  )
}

export const ApprovedThemes = ({ title }) => {
  const { permissions, tokenType, capitalalizeFirstletter, isLoading, deleteUpdateTag, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setAddEditDeleteTag, setFormData, setDeleteUpdateItem, setDeleteUpdateYes, confirmationModalShow, setConfirmationModalShow, handleSwitchUpdate, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen, handleOpenReason, setSingleThemeRejected } = useContext(NewContext);
  const simpleFields = [
    { name: "Theme", },
    { name: "Title", sortable: true, field: 'Title' },
    { name: "Background", sortable: true, field: 'Background' },
    { name: "User Email", sortable: true, field: 'email' },
    { name: "User Name", sortable: true, field: 'name' },
    { name: "Likes", sortable: true, field: 'Likes' },
    { name: "Dislike", sortable: true, field: 'Dislike' },
    { name: "Downloads", sortable: true, field: 'Downloads' },

  ]; const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  const dropdownFields = [{ name: "Active", }];
  const totalFields = simpleFields.length + dropdownFields.length + 2;


  const [Background, setBackground] = useState([]);
  const getbackgroundTypeData = async () => {
    const BackgroundData = await get_Community_Theme_data(1, "", "", { isActive: true });
    BackgroundData.data?.map(_ele => setBackground(_prev => !Background.includes(_prev) && [..._prev, { id: _ele?._id, label: _ele?.backgroundType }]))
  }

  useEffect(() => {
    getbackgroundTypeData();
  }, [])
  const getCommunityThemeData = async (_currentPage = 1) => {
    setIsLoading(true);
    try {
      const getCommunityTheme = await get_Community_Theme_data(_currentPage, perPage, search, { ...filterDropdown, themeStatus: "approved" });
      if (getCommunityTheme) {
        const { status, data, currentPage, perPage, totalPage } = getCommunityTheme;
        if (status) {
          setCurrentPageData(data);
          setCurrentPage(currentPage);
          setPerPage(perPage);
          setTotalPage(totalPage);
        } else {
          // toast.error(message);
          setCurrentPageData([])
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCommunityThemeData(currentPage);
  }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Approved Themes');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])



  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
      <SearchAndAddBtn title={`Approved Themes`} addBtn={false} movePending={true} movePendingTag='all-pending-theme' isSingleTheme={false} moveRejected={true} deleteUpdateTag='all-delete-approvedTheme' FilterDropdowns={<FilterDropdowns dropData={[{ id: 1, label: "All", ...Background }]} />} countryFilter={false} />
      {/* <SearchAndAddBtn title={`Approved Themes`} addBtn={false} movePending={true} movePendingTag='all-pending-theme' isSingleTheme moveRejectedTag='all-rejected-theme' moveRejected={true} deleteUpdateTag='all-delete-approvedTheme'  FilterDropdowns={<FilterDropdowns dropData={[{ id: 1, label: "All", ...Background }]} />} countryFilter={false} /> */}
      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
          <table className="w-full min-w-max table-auto text-left ">
            <THead simpleFields={simpleFields} dropdownFields={false} />
            <tbody>
              {
                isLoading ?
                  <tr>
                    <td colSpan={totalFields} className='py-12'>
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                  : currentPageData.length === 0 ?
                    <tr>
                      <td colSpan={totalFields} className='py-12 bg-gray-200'>
                        <div className="flex justify-center items-center">
                          No Data Found
                        </div>
                      </td>
                    </tr>
                    : currentPageData?.map((_ele, i) => {
                      const isLast = i === currentPageData?.length - 1;
                      const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                      // const UserName = _ele?.userId?.map((item) => item?.name);
                      // const UserEmail = _ele?.userId?.map((item) => item?.email);
                      const UserName = _ele?.userId?.name;
                      const UserEmail = _ele?.userId?.email;
                      return (
                        <tr key={i} className='border-b border-blue-gray-50'>
                          <td className={classes}>
                            <Checkbox
                              className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                              id={`slider-${_ele?._id}`}
                              value={`${_ele?._id}`}
                              disabled={!pagePermissions?.CU}
                              checked={checkedValues.includes(`${_ele?._id}`)}
                              onChange={() => handleCheckboxChange(`${_ele?._id}`)}
                            />
                          </td>
                          <td className={`${classes} relative`}>
                            <img src={_ele.preview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 h-[55px] rounded-lg block absolute' />
                            <Zoom>
                              {
                                _ele?.backgroundType == "Gif" ?
                                  <div className='relative'>
                                    <img src={_ele?.preview || `../../../assets/images/no-image.png`} alt="/" className='mr-0 h-[55px] rounded-lg relative z-10' />
                                    <img src={_ele?.gifFile} alt="/" className='mr-0 h-[55px] rounded-lg absolute top-0' />
                                  </div> :
                                  <img src={_ele?.preview || `../../../assets/images/no-image.png`} alt="/" className='mr-0 h-[55px] rounded-lg relative' />
                              }
                            </Zoom>
                          </td>

                          {/* <td className={classes}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] ${!_ele?.title && `text-gray-500`}`}>{_ele?.title || `None`}</span>
                            </div>
                          </td> */}

                          <td className={`${classes}`}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] !w-28 truncate ${!_ele?.title && `text-gray-500`}`}>{_ele?.title || `None`}</span>
                              {
                                _ele?.title && _ele.title?.length > 12 &&
                                <Tooltip placement="right-start" content={
                                  <div className="w-auto">
                                    {_ele.title || `None`}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </div>
                          </td>

                          <td className={classes}>
                            <span className={`font-normal text-[17px] ${!_ele?.backgroundType && `text-gray-500`}`}>{_ele?.backgroundType || `None`}</span>
                          </td>

                          {/* <td className={classes}>
                            <span className={`font-normal text-[17px] ${!UserEmail && `text-gray-500`}`}>{UserEmail || `None`}</span>
                          </td> */}

                          <td className={`${classes}`}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] !w-28 truncate ${!UserEmail && `text-gray-500`}`}>{UserEmail || `None`}</span>
                              <Tooltip placement="right-start" content={
                                <div className="w-auto">
                                  {UserEmail || `None`}
                                </div>
                              }>
                                <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                              </Tooltip>

                            </div>
                          </td>

                          <td className={classes}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] !w-28 truncate ${!UserName && `text-gray-500`}`}>{UserName || `None`}</span>
                              {
                                UserName && UserName?.[0]?.length > 12 &&
                                <Tooltip placement="right-start" content={
                                  <div className="w-auto">
                                    {UserName || `None`}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }

                            </div>
                          </td>
                          <td className={classes}>
                            <span className={`font-normal text-[17px] ${!_ele?.like?.length && `text-gray-500`}`}>{_ele?.like?.length || `0`}</span>
                          </td>
                          <td className={classes}>
                            <span className={`font-normal text-[17px] ${!_ele?.dislike?.length && `text-gray-500`}`}>{_ele?.dislike?.length || `0`}</span>
                          </td>
                          <td className={classes}>
                            <span className={`font-normal text-[17px] ${!_ele?.downloadCount && `text-gray-500`}`}>{_ele?.downloadCount || `0`}</span>
                          </td>


                          <td className={classes}>
                            <div className="flex items-center gap-2">
                              <CommunityBtn pendingBtn={pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: _ele._id, themeStatus: 'pending' }); setConfirmationModalShow(!confirmationModalShow) }} />
                              <CommunityBtn rejectBtn={pagePermissions?.CU} onClick={() => { setSingleThemeRejected(true); setFormData(_ele); handleOpenReason(); }} />
                              {/* { 
                                _ele?.themeStatus === "approved" ?  <CommunityBtn  rejectBtn={true} /> : 
                                _ele?.themeStatus === "reject" ? <><CommunityBtn  approvedBtn={true} /> <CommunityBtn  pendingBtn={true} /> </> : 
                                _ele?.themeStatus === "pending" ? <><CommunityBtn  approvedBtn={true} /> <CommunityBtn  rejectBtn={true} /> </> : null
                              }
                              */}
                              <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                <IoEyeOutline />
                              </IconButton>
                              <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-approvedTheme"); setDeleteUpdateItem({ id: _ele?._id }); setConfirmationModalShow(!confirmationModalShow) }}>
                                <RiDeleteBinLine />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      );
                    })
              }
            </tbody>
          </table>
        </CardBody>
      </Card>
      {
        totalPage > 1 &&
        <Pagination currentPage={currentPage} totalPage={totalPage} getData={getCommunityThemeData} />
      }
      <ApprovedThemesModal />
      <ReasonListModel />
    </>
  )
}
ApprovedThemes.defaultProps = {
  title: "My Photo Keyboard | Approved Themes"
};

export default ApprovedThemes
