import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { NewContext } from "../../Context/NewContext";
import {
  delete_slider,
  delete_theme_category,
  delete_theme,
  delete_store,
  delete_tag,
  delete_fontlist,
  delete_effects,
  delete_sounds,
  delete_background,
  delete_background_category,
  delete_sticker_store,
  delete_all_themes,
  delete_all_slider,
  delete_all_theme_category,
  delete_all_tag,
  delete_all_background,
  delete_all_background_category,
  delete_all_effects,
  delete_all_fontlist,
  delete_all_sounds,
  delete_all_sticker_store,
  delete_all_wallpaper,
  delete_wallpaper,
  delete_wallpaper_category,
  delete_all_wallpaper_category,
  delete_all_slider_tag,
  delete_slider_tag,
  update_slider_tags,
  update_slider,
  update_theme,
  update_theme_category,
  update_tags,
  update_background,
  update_store,
  update_background_category,
  update_effects,
  update_fontlist,
  update_sounds,
  update_sticker_store,
  update_wallpaper_category,
  delete_all_history,
  delete_textart,
  delete_textart_categories,
  delete_kamoji,
  delete_fancy_fonts,
  delete_all_textart,
  delete_all_textart_categories,
  delete_CommunityReason,
  deleteAll_CommunityReason,
  delete_all_kamoji,
  delete_all_fancy_fonts,
  update_textart,
  update_textart_categories,
  update_kamoji,
  update_fancy_fonts,
  delete_tab,
  delete_all_tab,
  update_tab,
  delete_section_settings,
  update_keys,
  update_section_settings,
  delete_all_section_settings,
  update_section_settings_priority,
  delete_custom_button,
  delete_all_custom_button,
  update_custom_button,
  delete_banners,
  delete_all_approvedTheme,
  delete_all_banners,
  update_banners,
  delete_functionality_fonts,
  delete_all_functionality_fonts,
  update_functionality_fonts,
  delete_approvedTheme,
  update_Community_Reason,
  update_approvedTheme,
  delete_communityUser,
  delete_Report,
  delete_all_community_user,
  delete_all_community_Report,
  delete_keys,
  delete_all_keys,
  delete_all_storage,
  delete_storage,
  pending_all_theme,
  reject_all_theme,
  approved_all_theme,
  remove_all_community_extra_files,
  remove_common_storage,
  update_blog_categories,
  update_blog,
  deleteAll_blog_categories,
  deleteAll_blog,
  deleteAll_reply,
  delete_blog_categories,
  delete_blog,
  delete_reply,
  update_faq,
  delete_faq,
  delete_survey,
  update_survey,
  delete_asset,
  delete_all_store,
} from "../../Controller/actions";
import { DialogActions } from "@mui/material";

export const ConfirmationModal = () => {
  const navigate = useNavigate();
  const {
    handleChildClose,
    currentHomeModalKey,
    addEditDeleteTag,
    setAddEditDeleteTag,
    setOpen,
    isLoading,
    setIsLoading,
    confirmationModalShow,
    setConfirmationModalShow,
    setDeleteUpdateTag,
    setCheckedValues,
    deleteUpdateItem,
    setDeleteUpdateItem,
    deleteUpdateYes,
    setDeleteUpdateYes,
  } = useContext(NewContext);
  const handleConfirmationClose = () => {
    setConfirmationModalShow(!confirmationModalShow);
    setDeleteUpdateTag("");
    setDeleteUpdateItem({});
    setCheckedValues([]);
    if (!currentHomeModalKey) {
      setOpen(false);
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    toast.success("Logout successfully.");
    navigate("/signin");
  };
  const handleYes = async () => {
    let deleteUpdateByTag;
    setIsLoading(true);
    setDeleteUpdateTag(deleteUpdateYes);
    if (deleteUpdateYes === "logout") {
      handleLogout();
    }
    if (deleteUpdateYes === "delete-slider") {
      deleteUpdateByTag = await delete_slider(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-slidertag") {
      deleteUpdateByTag = await delete_slider_tag(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-themes") {
      deleteUpdateByTag = await delete_theme(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-store") {
      deleteUpdateByTag = await delete_store(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-themecategories") {
      deleteUpdateByTag = await delete_theme_category(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-tags") {
      deleteUpdateByTag = await delete_tag(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-tab") {
      deleteUpdateByTag = await delete_tab(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-approvedTheme") {
      deleteUpdateByTag = await delete_approvedTheme(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-storage") {
      deleteUpdateByTag = await delete_storage(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "remove-all-community-extra-files") {
      deleteUpdateByTag = await remove_all_community_extra_files();
    }
    if (deleteUpdateYes === "remove-common-storage") {
      deleteUpdateByTag = await remove_common_storage({
        label: deleteUpdateItem.label,
      });
    }
    if (deleteUpdateYes === "delete-background") {
      deleteUpdateByTag = await delete_background(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-backgroundcategories") {
      deleteUpdateByTag = await delete_background_category(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-effects") {
      deleteUpdateByTag = await delete_effects(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-fontlist") {
      deleteUpdateByTag = await delete_fontlist(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-keys") {
      deleteUpdateByTag = await delete_keys(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-sounds") {
      deleteUpdateByTag = await delete_sounds(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-stickersStore") {
      deleteUpdateByTag = await delete_sticker_store(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-wallpaper") {
      deleteUpdateByTag = await delete_wallpaper(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-wallpapercategories") {
      deleteUpdateByTag = await delete_wallpaper_category(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-textart") {
      deleteUpdateByTag = await delete_textart(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-textartcategories") {
      deleteUpdateByTag = await delete_textart_categories(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-kamoji") {
      deleteUpdateByTag = await delete_kamoji(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-reason") {
      deleteUpdateByTag = await delete_CommunityReason(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-fancyfonts") {
      deleteUpdateByTag = await delete_fancy_fonts(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-homeactivity") {
      deleteUpdateByTag = await delete_section_settings(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-customsection") {
      deleteUpdateByTag = await delete_custom_button(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-functionality") {
      deleteUpdateByTag = await delete_functionality_fonts(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-banner") {
      deleteUpdateByTag = await delete_banners(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-communityUser") {
      deleteUpdateByTag = await delete_communityUser(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-Report") {
      deleteUpdateByTag = await delete_Report(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-blog-categories") {
      deleteUpdateByTag = await delete_blog_categories(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-blogs") {
      deleteUpdateByTag = await delete_blog(deleteUpdateItem.id);
    }
    if (deleteUpdateYes === "delete-reply") {
      deleteUpdateByTag = await delete_reply(deleteUpdateItem.id);
    }

    // FAQ
    if (deleteUpdateYes === "delete-FAQ") {
      deleteUpdateByTag = await delete_faq(deleteUpdateItem.id);
    }

    if (deleteUpdateYes === "update-FAQ") {
      deleteUpdateByTag = await update_faq(
        deleteUpdateItem,
        deleteUpdateItem.id
      );

    }
    //survey
    if (deleteUpdateYes === "delete-survey") {
      deleteUpdateByTag = await delete_survey(deleteUpdateItem.id);
    }

    if (deleteUpdateYes === "update-survey") {
      deleteUpdateByTag = await update_survey(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }

    // asset
    if (deleteUpdateYes === "delete-asset") {
      deleteUpdateByTag = await delete_asset(deleteUpdateItem.id);
    }

    if (deleteUpdateYes === "all-delete-sliders") {
      deleteUpdateByTag = await delete_all_slider(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-slidertags") {
      deleteUpdateByTag = await delete_all_slider_tag(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-themes") {
      deleteUpdateByTag = await delete_all_themes(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-store") {
      deleteUpdateByTag = await delete_all_store(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-themecategories") {
      deleteUpdateByTag = await delete_all_theme_category(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-tags") {
      deleteUpdateByTag = await delete_all_tag(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-tab") {
      deleteUpdateByTag = await delete_all_tab(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-background") {
      deleteUpdateByTag = await delete_all_background(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-backgroundcategories") {
      deleteUpdateByTag = await delete_all_background_category(
        deleteUpdateItem
      );
    }
    if (deleteUpdateYes === "all-delete-effects") {
      deleteUpdateByTag = await delete_all_effects(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-fontlists") {
      deleteUpdateByTag = await delete_all_fontlist(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-keys") {
      deleteUpdateByTag = await delete_all_keys(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-sounds") {
      deleteUpdateByTag = await delete_all_sounds(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-stickerstores") {
      deleteUpdateByTag = await delete_all_sticker_store(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-wallpapers") {
      deleteUpdateByTag = await delete_all_wallpaper(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-wallpapercategories") {
      deleteUpdateByTag = await delete_all_wallpaper_category(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-textart") {
      deleteUpdateByTag = await delete_all_textart(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-textartcategories") {
      deleteUpdateByTag = await delete_all_textart_categories(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-kamoji") {
      deleteUpdateByTag = await delete_all_kamoji(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-fancyfonts") {
      deleteUpdateByTag = await delete_all_fancy_fonts(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-history") {
      deleteUpdateByTag = await delete_all_history(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-homeactivity") {
      deleteUpdateByTag = await delete_all_section_settings(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-customsection") {
      deleteUpdateByTag = await delete_all_custom_button(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-functionality") {
      deleteUpdateByTag = await delete_all_functionality_fonts(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-banner") {
      deleteUpdateByTag = await delete_all_banners(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-approvedTheme") {
      deleteUpdateByTag = await delete_all_approvedTheme(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-pending-theme") {
      deleteUpdateByTag = await pending_all_theme(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-rejected-theme") {
      deleteUpdateByTag = await reject_all_theme(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-approved-theme") {
      deleteUpdateByTag = await approved_all_theme(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-communityUser") {
      deleteUpdateByTag = await delete_all_community_user(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-communityReport") {
      deleteUpdateByTag = await delete_all_community_Report(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-communityReason") {
      deleteUpdateByTag = await deleteAll_CommunityReason(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-storage") {
      deleteUpdateByTag = await delete_all_storage(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-blog-categories") {
      deleteUpdateByTag = await deleteAll_blog_categories(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "all-delete-blogs") {
      deleteUpdateByTag = await deleteAll_blog(deleteUpdateItem);
    }
    if (deleteUpdateYes === "all-delete-reply") {
      deleteUpdateByTag = await deleteAll_reply(deleteUpdateItem);
    }

    if (deleteUpdateYes === "update-slider") {
      deleteUpdateByTag = await update_slider(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-slidertag") {
      deleteUpdateByTag = await update_slider_tags(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-themes") {
      deleteUpdateByTag = await update_theme(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-store") {
      deleteUpdateByTag = await update_store(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-themecategories") {
      deleteUpdateByTag = await update_theme_category(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-assets") {
      deleteUpdateByTag = await update_theme_category(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-themetags") {
      deleteUpdateByTag = await update_tags(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-themetab") {
      deleteUpdateByTag = await update_tab(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-background") {
      deleteUpdateByTag = await update_background(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-backgroundcategories") {
      deleteUpdateByTag = await update_background_category(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-effects") {
      deleteUpdateByTag = await update_effects(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-fontlist") {
      deleteUpdateByTag = await update_fontlist(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-keys") {
      deleteUpdateByTag = await update_keys(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-sound") {
      deleteUpdateByTag = await update_sounds(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-stickerstore") {
      deleteUpdateByTag = await update_sticker_store(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-wallpapercategories") {
      deleteUpdateByTag = await update_wallpaper_category(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-textart") {
      deleteUpdateByTag = await update_textart(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-textartcategories") {
      deleteUpdateByTag = await update_textart_categories(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-kamoji") {
      deleteUpdateByTag = await update_kamoji(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-fancyfonts") {
      deleteUpdateByTag = await update_fancy_fonts(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-homeactivity") {
      deleteUpdateByTag = await update_section_settings(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-customsection") {
      deleteUpdateByTag = await update_custom_button(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-functionality") {
      deleteUpdateByTag = await update_functionality_fonts(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-banner") {
      deleteUpdateByTag = await update_banners(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-sectionsettings-priority") {
      deleteUpdateByTag = await update_section_settings_priority(
        deleteUpdateItem
      );
    }
    if (deleteUpdateYes === "update-approvedTheme") {
      deleteUpdateByTag = await update_approvedTheme(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-Reason") {
      deleteUpdateByTag = await update_Community_Reason(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-blog-categories") {
      deleteUpdateByTag = await update_blog_categories(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }
    if (deleteUpdateYes === "update-blogs") {
      deleteUpdateByTag = await update_blog(
        deleteUpdateItem,
        deleteUpdateItem.id
      );
    }

    if (deleteUpdateByTag) {
      const { message, status } = deleteUpdateByTag;
      if (status) {
        toast.success(message);
        setDeleteUpdateYes("");
        setDeleteUpdateTag("");
        setCheckedValues([]);
        setIsLoading(false);
      } else {
        toast.error(message);
      }
    }
    if (currentHomeModalKey) {
      handleChildClose();
      setAddEditDeleteTag(`update`);
    } else {
      setAddEditDeleteTag(`add`);
    }
    handleConfirmationClose();
  };

  return (
    <>
      <Dialog
        className="p-[30px]"
        open={confirmationModalShow}
        handler={handleConfirmationClose}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "466px",
              padding: "30px",
              borderRadius: "12px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="flex justify-center p-0 text-[#1E1627]"
        >
          <Typography variant="h5">Are You Sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className="text-center font-normal leading-3 text-[17px] text-[#1E1627]">
            Do you really want to{" "}
            {deleteUpdateYes?.split("-").includes("update")
              ? `update`
              : `delete`}{" "}
            these records?
            <br />
            This process cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions className="flex gap-2.5 !justify-center p-0">
          <Button
            variant="outlined"
            color="blue-gray"
            className="capitalize shadow-none hover:shadow-none border-[#75869F] font-medium text-base leading-none"
            onClick={handleConfirmationClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleYes}
            className={`${deleteUpdateYes?.split("-").includes(`delete`)
              ? `bg-[#F22F42]`
              : ``
              } flex items-center capitalize shadow-none hover:shadow-none font-medium text-base leading-none !ml-0`}
          >
            {isLoading && <Spinner className="h-4 w-4" />}
            <span className={isLoading ? "ml-4" : ""}>
              {deleteUpdateYes?.split("-").includes(`delete`)
                ? `Delete`
                : `Yes`}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
