import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Select, Option, Input, Radio, Spinner } from "@material-tailwind/react";
import { add_banners, add_slider, add_slider_tags, add_store, get_country_data, get_region_data, get_slider_tag, update_banners, update_slider, update_store } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const StoreModal = () => {
    const { isModalLoading, setDeleteUpdateTag, deleteUpdateYes, setIsModalLoading, handleClose, handleFileInput, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, setMultiCountry, setMultiRegion } = useContext(NewContext);

    const [tag, setTag] = useState([]);
    const [multiTag, setMultiTag] = useState([]);
    const [country, setCountry] = useState([]);

    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const tagData = await get_slider_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (tagData.status && countryData.status && regionData.status) {
                setTag(tagData.data);
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCountry(oldCountryData);
                }
            } else {
                toast.error(tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };

    useEffect(() => {
        if (open) {
            getDataFunc();
        }
    }, [addEditDeleteTag, open]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            createUpdateSlider = await update_store({ ...formData }, formData?._id)
        } else {
            createUpdateSlider = await add_store(formData)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setTag([]); setMultiTag([]); }}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "550px",
                            borderRadius: "12px"
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Store` : `Add New Store`}
                </DialogTitle>
                <DialogContent>
                    <div className="">
                        <Typography className="text-lg text-[#1E1627] font-medium">Preview:</Typography>
                        <div className='w-auto'>
                            <input id='homescreenStore' type='file' name='homescreenStore' onChange={e => handleFileInput(e)} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' required={addEditDeleteTag !== `update`} />
                        </div>
                    </div>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={() => { handleClose(); setTag([]); setMultiTag([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            disabled={isModalLoading || !formData?.homescreenStore}
                                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={() => { handleClose(); setTag([]); setMultiTag([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </DialogContent>
            </Dialog >
        </>
    )
}

export default StoreModal
