import React, { useContext, useEffect, useState } from 'react'
// import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import { toast } from 'react-toastify';

import { Button, Input, Spinner } from "@material-tailwind/react";
import { add_tags, get_country_data, get_region_data, update_tags } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MultiSelect } from 'react-multi-select-component';

export const ThemeTagModal = () => {
    const { homeActivityRef, isModalLoading, handleSelectMultiCountry, handleSelectMultiRegion, multiCountry, multiRegion, updateStateAryData, capitalalizeFirstletter, setMultiCountry, setMultiRegion, setDeleteUpdateTag, deleteUpdateYes, handleSwitch, setIsModalLoading, handleClose, handleChange, formData, open, addEditDeleteTag } = useContext(NewContext);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (countryData.status && regionData.status) {
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                }
            } else {
                toast.error(countryData.message || regionData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };
    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_tags({ ...formData, country: countryIdAry || [], region: formData?.region || [] }, formData?._id)
        } else {
            createUpdateSlider = await add_tags(formData)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                // setValidated(false);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "349px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627] '>
                    {
                        addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Themes Tags` : `Add Themes Tags`
                    }
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            <form onSubmit={handleSubmit}>
                                <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                        <div className="mt-2">
                                            <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name} autoComplete='false' />
                                        </div>
                                    </div>
                                    {/* <div className="sm:col-span-6">
                                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">Region:</label>
                                        <div className="mt-1.5">
                                            {
                                                region && region.length > 0 &&
                                                <MultiSelect
                                                    labelledBy='Select Region'
                                                    options={region}
                                                    value={multiRegion}
                                                    onChange={handleSelectMultiRegion}
                                                    hasSelectAll={false}
                                                    ClearSelectedIcon={null}
                                                />
                                            }
                                        </div>
                                    </div> */}
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                        <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                            {
                                                country && country.length > 0 &&
                                                <MultiSelect
                                                    labelledBy='Select Country'
                                                    options={country}
                                                    value={multiCountry}
                                                    onChange={handleSelectMultiCountry}
                                                    hasSelectAll={false}
                                                    ClearSelectedIcon={null}
                                                    isOpen={isOpenCountryDropDown}
                                                />
                                            }
                                            <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="isTrending" className="block text-sm font-medium leading-6 text-gray-900">Trending:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='isTrending' name='isTrending' onChange={handleSwitch} checked={formData?.isTrending} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end mt-10'>
                                    <Button
                                        onClick={handleClose}
                                        className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                    <Button
                                        disabled={isModalLoading}
                                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                        type='submit'
                                    >
                                        {isModalLoading && <Spinner className="h-4 w-4" />}
                                        <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                    </Button>
                                </div>
                            </form>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ThemeTagModal
