import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";
import { BiEdit } from 'react-icons/bi';
import { Pagination, SearchAndAddBtn, Switch, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_blog_comments } from '../../Controller/actions';
import { RiDeleteBinLine } from 'react-icons/ri';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import CommentsModel from '../../Components/Modals/CommentsModel';
import { IoEyeOutline } from 'react-icons/io5';

export const Comments = ({ title }) => {
    const { permissions, tokenType, setDeleteUpdateTag, setDeleteUpdateItem, setAddEditDeleteTag, setFormData, deleteUpdateYes, confirmationModalShow, setConfirmationModalShow, handleSwitchUpdate, deleteUpdateTag, isLoading, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "comment", sortable: true, field: 'comment' },
        { name: "UserName", sortable: true, field: 'name' },
        { name: "Email", sortable: true, field: 'email' },
        // { name: "Website", sortable: true, field: 'website' },
        { name: "Blog Title", sortable: true, field: 'title' },
    ];

    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });

    const dropdownFields = [];
    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const getCommentsData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getFontList = await get_blog_comments(_currentPage, perPage, search, filterDropdown);
            if (getFontList) {
                const { status, data, currentPage, perPage, totalPage } = getFontList;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(currentPage);
                    setPerPage(perPage);
                    setTotalPage(totalPage);
                } else {
                    setCurrentPageData([]);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getCommentsData(currentPage);
        setDeleteUpdateTag("")
    }, [deleteUpdateTag, search, perPage, filterDropdown, deleteUpdateYes, pagePermissions]);

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Blog Comments');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])

    useEffect(() => {
        document.title = title;
    }, [title])


    return (
        <>
            <SearchAndAddBtn title={`Blog Comments`} addLink={false} addBtn={false} deleteUpdateTag='all-delete-reply' countryFilter={false} />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        <THead simpleFields={simpleFields} countryFilter={false} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            disabled={!pagePermissions?.CU}
                                                            value={`${_ele._id}`}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px]  !w-40 truncate ${!_ele.comment && `text-gray-500`}`}>{_ele.comment || `None`}</span>
                                                            {
                                                                _ele.comment && _ele?.comment?.length > 12 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-36 2xl:w-[400px] overflow-y-auto ">
                                                                        {_ele.comment}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-10 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele?.name && `text-gray-500`}`}>{_ele?.name || `none`}</span>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele?.email && `text-gray-500`}`}>{_ele?.email || `none`}</span>
                                                    </td>
                                                    {/* <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px]  !w-40 truncate ${!_ele?.website && `text-gray-500`}`}>{_ele?.website || `None`}</span>
                                                            {
                                                                _ele?.website && _ele?.website?.length > 12 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-36 2xl:w-[400px] overflow-y-auto ">
                                                                        {_ele?.website}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-10 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td> */}
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px]  !w-40 truncate ${!_ele?.blogName && `text-gray-500`}`}>{_ele?.blogName || `None`}</span>
                                                            {

                                                                _ele?.blogName && _ele?.blogName?.length > 12 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-36 2xl:w-[400px] overflow-y-auto ">
                                                                        {_ele?.blogName}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-10 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton disabled={!pagePermissions?.CU} className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' onClick={() => { setDeleteUpdateYes("delete-reply"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                            <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                                                <IoEyeOutline />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            <Pagination currentPage={currentPage} totalPage={totalPage} getData={getCommentsData} />
            <CommentsModel />
        </>
    )
}
Comments.defaultProps = {
    title: "My Photo Keyboard | Blog Comments"
};
export default Comments;
