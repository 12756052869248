import React from 'react'
import { Avatar, Card, CardBody, Typography } from '@material-tailwind/react'
import moment from 'moment'

export const DashboradCard = ({ cardData }) => {
    return (
        <Card className="border border-[#BCC2CC] shadow-none">
            {/* <CardHeader color="blue-gray" className="relative m-0">
                <img src={cardData.imgUrl} alt="img-blur-shadow" layout="fill" />
            </CardHeader> */}
            <CardBody className=''>
                {/* <Typography variant="h5" color="blue-gray" className="leading-none mb-1">{cardData.title}</Typography> */}
                <div className='flex justify-between items-center'>
                    <div className="flex items-center">
                        <Avatar src={"../../assets/images/Collection.png"} alt="avatar" variant="rounded" className='w-14 h-14 rounded-[3px] mr-[9px]' />
                        <Typography className="text-darkblue leading-none font-medium text-2xl" >{cardData?.name}</Typography>
                    </div>
                    {/* <Menu placement='bottom-end'>
                        <MenuHandler>
                            <Button variant="text" className='p-[9px] bg-[#0047FF] hover:bg-[#0047FF] focus:bg-[#0047FF]'><FiChevronRight className='text-white' size={'21px'} /></Button>
                        </MenuHandler>
                    </Menu> */}
                </div>
                <div className="mt-[15px] mb-[30px]">
                    <Typography className="text-darkblue leading-none font-normal text-sm">Total Items: <span className='text-[#75869F] font-semibold'>{cardData?.total}</span></Typography>
                    <Typography className="text-darkblue leading-none my-1.5 font-normal text-sm">Active Items: <span className='text-[#009D3F] font-semibold'>{cardData?.active}</span></Typography>
                    <Typography className="text-darkblue leading-none font-normal text-sm">Inactive Items: <span className='text-[#F62929] font-semibold'>{cardData?.inactive}</span></Typography>
                </div>
                <div className="">
                    <Typography className="text-darkblue leading-none mb-1.5 font-light text-sm">Last Updated Item: <span className={`text-darkblue leading-none text-base font-semibold`}>{cardData?.lastData?.name ? cardData?.lastData?.name : "None"}</span></Typography>
                    <Typography className="text-darkblue leading-none font-light text-sm">Last Updated At: <span className='text-darkblue leading-none text-base font-semibold'>{moment(cardData?.lastData?.updatedAt).format('DD/MM/YYYY, hh:mm A')}</span></Typography>
                </div>
            </CardBody>
        </Card>
    )
}

export default DashboradCard    
