import { Button } from '@material-tailwind/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className='h-screen grid'>
            <div className='h-full flex justify-center items-center text-center'>
                <div className='notfound_img h-[650px] overflow-hidden'>
                    <img src="../../assets/images/notfound.gif" style={{ transform: "scale(1.1)" }} className='mx-auto h-full' />
                </div>
                <div className='notfound_info relative'>
                    <h1 className="text-6xl font-medium">4 0 4</h1>
                    <h2 className='my-2'>Look like you're lost</h2>
                    <p className='mb-4'>the page you are looking for not avaible!</p>
                    <Button onClick={() => navigate("/dashboard")}>Go to Home</Button>
                </div>
            </div>
        </div>
    )
}

export default NotFound