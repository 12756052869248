import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Input, Spinner } from "@material-tailwind/react";
import { add_sticker_store, get_country_data, get_region_data, update_sticker_store } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import { BiLink } from 'react-icons/bi';
import { MdContentCopy } from 'react-icons/md';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileInput from '../FileInput/FileInput';


export const StickerstoreModal = () => {
    const { truncateString, homeActivityRef, isModalLoading, setDeleteUpdateTag, deleteUpdateYes, handleCopyData, handleSelectMultiCountry, handleSelectMultiRegion, handleSwitch, handleFileInput, setIsModalLoading, handleClose, handleChange, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, multiCountry, setMultiCountry, multiRegion, setMultiRegion } = useContext(NewContext);

    // const [tag, setTag] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            // const tagData = await get_slider_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (countryData.status && regionData.status) {
                // setTag(tagData.data);
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                }
            } else {
                // toast.error(tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };

    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateSlider;
        setIsModalLoading(true);
        const obj = {
            name: formData?.name ? formData?.name : "",
            bigPreviewStickerStore: formData?.bigPreviewStickerStore ? formData?.bigPreviewStickerStore : [],
            country: formData?.country ? formData?.country : [],
            region: formData?.region ? formData?.region : [],
            smallPreviewStickerStore: formData?.smallPreviewStickerStore ? formData?.smallPreviewStickerStore : [],
            stickerZip: formData?.stickerZip ? formData?.stickerZip : "",
            isActive: formData?.isActive ? true : false,
            isHot: formData?.isHot ? true : false,
            isTop: formData?.isTop ? true : false,
            isLatest: formData?.isLatest ? true : false,
            isPremium: formData?.isPremium ? true : false,
        }
        if (addEditDeleteTag == `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_sticker_store({ ...obj, country: countryIdAry || [], region: formData?.region || [] }, formData?._id)
        } else {
            createUpdateSlider = await add_sticker_store(obj)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                // setValidated(false);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "676px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627] '>
                    {
                        addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Sticker store` : `Add New Sticker store`
                    }
                </DialogTitle>
                <DialogContent>
                    <div className="">
                        <Typography className="text-lg text-[#1E1627] font-medium">Small Preview (640 x 437):</Typography>
                        <div className='w-auto'>
                            <FileInput name={`smallPreviewStickerStore`} imgClass={`h-full`} previewFields={'small_preview'} accept={'image/*'} width={640} height={437} acceptedExtensions={/\.(webp)$/} />
                        </div>
                    </div>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-3 md:gap-5 add-edit-form">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                            <div className="mt-2">
                                                <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name} />
                                            </div>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="bigPreviewStickerStore" className="block text-sm font-medium leading-6 text-gray-900">Stickers (Multiple Files):</label>
                                            <div className="mt-1.5 relative">
                                                <input id='bigPreviewStickerStore' type='file' accept='image/*' name='bigPreviewStickerStore' onChange={e => handleFileInput(e)} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' multiple required={addEditDeleteTag !== `update`} />
                                                {
                                                    addEditDeleteTag === `update` && formData.big_preview && typeof formData.big_preview === 'string' && !formData.bigPreviewStickerStore &&
                                                    <label htmlFor="bigPreviewStickerStore" className='absolute sm:left-[36%] left-[33.5%] right-[2px] w-auto rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData.big_preview.split('/')[formData.big_preview.split('/').length - 2], 24)}</label>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="stickerZip" className="block text-sm font-medium leading-6 text-gray-900">Sticker Zip:</label>
                                            <div className="mt-1.5 relative">
                                                <input id='stickerZip' type='file' accept='application/x-zip-compressed' name='stickerZip' onChange={handleFileInput} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' required={addEditDeleteTag !== `update`} />
                                                {
                                                    addEditDeleteTag === `update` && formData.zip_data && typeof formData.zip_data === 'string' && !formData.stickerZip &&
                                                    <label htmlFor="bigPreviewStickerStore" className='absolute sm:left-[34%] left-[33.5%] right-[2px] w-auto rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData.zip_data.split('/').pop(), 24)}</label>
                                                }
                                            </div>
                                        </div>
                                        {/* <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Region:</label>
                                                <div className="mt-1.5">
                                                    {
                                                        region && region.length > 0 &&
                                                        <MultiSelect
                                                            labelledBy='Select Country'
                                                            options={region}
                                                            value={multiRegion}
                                                            onChange={handleSelectMultiRegion}
                                                            hasSelectAll={false}
                                                            ClearSelectedIcon={null}
                                                        />
                                                    }
                                                </div>
                                            </div> */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                            <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                                {
                                                    country && country.length > 0 &&
                                                    <MultiSelect
                                                        options={country}
                                                        value={multiCountry}
                                                        onChange={handleSelectMultiCountry}
                                                        hasSelectAll={false}
                                                        ClearSelectedIcon={null}
                                                        isOpen={isOpenCountryDropDown}
                                                    />
                                                }
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                            </div>
                                        </div>
                                        {/* <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Badge:</label>
                                            <div className="mt-1.5">
                                                <Select name='badge' onChange={(e) => handleSingleSelect(`badge`, e)} value={formData?.badge}>
                                                    {tag.map((_ele, i) => <Option key={i} value={_ele.name.toLowerCase()}>{_ele.name}</Option>)}
                                                    <Option>New</Option>
                                                    <Option>Trending</Option>
                                                    <Option>Hot</Option>
                                                </Select>
                                            </div>
                                        </div> */}

                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isHot" className="block text-sm font-medium leading-6 text-gray-900">Hot:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isHot' name='isHot' onChange={handleSwitch} checked={formData?.isHot} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isTop" className="block text-sm font-medium leading-6 text-gray-900">Top:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isTop' name='isTop' onChange={handleSwitch} checked={formData?.isTop} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isLatest" className="block text-sm font-medium leading-6 text-gray-900">Latest:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isLatest' name='isLatest' onChange={handleSwitch} checked={formData?.isLatest} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isPremium" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isPremium' name='isPremium' onChange={handleSwitch} checked={formData?.isPremium} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={handleClose}
                                            className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            disabled={isModalLoading}
                                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-6">
                                            <label htmlFor="link" className="block text-lg font-medium leading-5 text-[#1E3354]">Link:</label>
                                            <div className="flex items-center justify-between">
                                                <div className="mt-2 flex items-center text-[#2288FF]">
                                                    <span className='mr-[5px]'><BiLink className='text-xl leading-none' /></span>
                                                    <Typography>{formData?.link || `None`}</Typography>
                                                </div>
                                                {/* <div className=""> */}
                                                <Button variant='text' disabled={!formData?.link} className='p-3 rounded-full' onClick={() => handleCopyData(`Link`, formData?.link)}><MdContentCopy className='text-xl leading-none' /></Button>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Name:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="from_flg" className="block text-lg font-medium leading-5 text-[#1E3354]">From Flag:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.from_flg || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Priority:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.priority}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Slider Tag:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.slider_tag || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Region:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.region && formData?.region?.length !== 0 ? formData?.region?.map((_ele, key) => key === _ele.region?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Country:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.country && formData?.country?.length !== 0 ? formData?.country?.map((_ele, key) => key === _ele.country?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="city" className="block text-lg font-medium leading-5 text-[#1E3354]">Orientation:</label>
                                            <div className="mt-2 grid grid-cols-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.orientation || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end'>
                                        <Button
                                            onClick={handleClose}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </DialogContent>

            </Dialog >
        </>
    )
}

export default StickerstoreModal
