import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { NewContext } from '../../Context/NewContext'
import { admin_signin, ldap_admin_signin } from '../../Controller/actions';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const SignIn = ({ title, socket }) => {
    const navigate = useNavigate();
    const { formData, setFormData, handleChange, handlePermissions } = useContext(NewContext);
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    //STANDARD LOGIN

    const handleStandardSubmit = async (e) => {
        e.preventDefault();
        try {
            const { status, token, message, name } = await admin_signin(formData);
            if (status) {
                localStorage.setItem("token", token);
                localStorage.setItem("loginType", 'standard')
                localStorage.setItem("userName", name);
                toast.success(message);
                navigate("/dashboard");
                setFormData({});
            } else {
                toast.error(message)
            }
        } catch (error) {
            console.error(error);
        };
    };

    //LDAP SIGNIN

    const handleLDAPSubmit = async (e) => {
        e.preventDefault();
        try {
            // const { status, token, message } = await admin_signin(signInData);
            const { status, token, message, name } = await ldap_admin_signin({ ...formData, projectId: process.env.REACT_APP_PROJECT_ID });
            if (status) {
                localStorage.setItem("token", token);
                // localStorage.setItem("permission", token);
                const object = JSON.parse(atob(token.split('.')[1]))

                // if (localStorage.getItem('loginType') === 'ldap' && token) {
                const [_, payload, _$] = token?.split('.');
                handlePermissions(JSON.parse(atob(payload))?.permission)
                // }
                socket.emit('join', { userId: JSON.parse(atob(payload))?._id })
                localStorage.setItem("loginType", 'ldap');
                localStorage.setItem("userName", name);
                toast.success(message);
                navigate("/");
                setFormData({});
            } else {
                toast.error(message)
                // toast.error(message);
            }
        } catch (error) {
            console.error(error);
        };
    };

    useEffect(() => {
        document.title = title;
    }, [title])

    return (
        // <section className="flex min-h-screen">
        //     <main className="w-7/12 bg-[#041645] p-7 space-y-5 hidden md:flex flex-col items-center justify-center">
        //         <div className='bg-center'>
        //             <img src="../../assets/images/logo.png" alt="reader" />
        //         </div>
        //     </main>

        //     <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        //         <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        //             <h2 className="mt-10 text-start text-2xl font-bold leading-9 tracking-tight text-gray-900">Login to your Account</h2>
        //         </div>

        //         <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
        //             <form className="space-y-6" onSubmit={handleSubmit}>
        //                 <div>
        //                     <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
        //                     <div className="mt-2">
        //                         <div className="flex bg-white rounded-lg border border-[#BCC2CC] overflow-hidden">
        //                             <input className="bg-white outline-none text-sm leading-none py-3.5 px-5 w-full" type="text" placeholder="Email" name='email' onChange={handleChange} required />
        //                         </div>
        //                     </div>
        //                 </div>

        //                 <div>
        //                     <div className="flex items-center justify-between">
        //                         <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
        //                     </div>
        //                     <div className="mt-2">
        //                         <div className="flex bg-white rounded-lg border border-[#BCC2CC] overflow-hidden">
        //                             <input className="bg-white outline-none text-sm leading-none py-3.5 px-5 w-full" type="password" placeholder="Password" name='password' onChange={handleChange} required />
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div>
        //                     <Button type="submit" className="flex w-full justify-center rounded-lg bg-[#0047FF] px-3 py-2.5 text-sm leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 capitalize font-medium">Login</Button>
        //                 </div>
        //             </form>
        //         </div>
        //     </div>

        // </section>




        <section className="grid grid-cols-12 min-h-screen">
            <main className="w-full bg-[#041645] p-7 space-y-5 hidden md:flex flex-col items-center justify-center col-span-7">
                <div className='bg-center'>
                    <img src="../../assets/images/admin-signin-logo.svg" alt="reader" />
                </div>
            </main>
            <div className='w-full col-span-5 grid grid-cols-12'>
                <div className="flex min-h-full items-center flex-col justify-center px-6 py-12 lg:px-8 col-span-12">
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <div className="sm:mx-auto sm:w-full flex flex-col items-center justify-center sm:max-w-sm">
                            <div>
                                <img src='../../assets/images/login_logo.svg' className='w-16 h-16' />
                            </div>
                            <div className='mt-3'>
                                <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">My Photo Keyboard</h2>
                            </div>
                        </div>
                        <div className='w-fit'>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                                <Tabs value={value} onChange={handleTabChange} className='mt-8 ' aria-label="basic tabs example" centered>
                                    <Tab label="LDAP" {...a11yProps(0)} className='!px-20' />
                                    <Tab label="Standard" {...a11yProps(1)} className='!px-20' />
                                </Tabs>
                            </Box>
                        </div>
                        <div className='w-full'>
                            <CustomTabPanel value={value} index={0}>

                                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                    <form className="space-y-6" onSubmit={handleLDAPSubmit}>
                                        <div>
                                            <label htmlFor="userId" className="block text-sm font-medium leading-6 text-gray-900">Username</label>
                                            <div className="mt-2">
                                                <div className="flex bg-white rounded-lg border border-[#BCC2CC] overflow-hidden">
                                                    <input className="bg-white outline-none text-sm leading-none py-3.5 px-5 w-full" type="text" placeholder="Username" name='userId' onChange={handleChange} required />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex items-center justify-between">
                                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                            </div>
                                            <div className="mt-2">
                                                <div className="flex bg-white rounded-lg border border-[#BCC2CC] overflow-hidden">
                                                    <input className="bg-white outline-none text-sm leading-none py-3.5 px-5 w-full" type="password" placeholder="Password" name='password' onChange={handleChange} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Button type="submit" className="flex w-full justify-center rounded-lg bg-[#0047FF] px-3 py-2.5 text-sm leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 capitalize font-medium">Login</Button>
                                        </div>
                                    </form>
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                    <form className="space-y-6" onSubmit={handleStandardSubmit}>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Username or email</label>
                                            <div className="mt-2">
                                                <div className="flex bg-white rounded-lg border border-[#BCC2CC] overflow-hidden">
                                                    <input className="bg-white outline-none text-sm leading-none py-3.5 px-5 w-full" type="text" placeholder="Email" name='email' onChange={handleChange} required />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex items-center justify-between">
                                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                            </div>
                                            <div className="mt-2">
                                                <div className="flex bg-white rounded-lg border border-[#BCC2CC] overflow-hidden">
                                                    <input className="bg-white outline-none text-sm leading-none py-3.5 px-5 w-full" type="password" placeholder="Password" name='password' onChange={handleChange} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Button type="submit" className="flex w-full justify-center rounded-lg bg-[#0047FF] px-3 py-2.5 text-sm leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 capitalize font-medium">Login</Button>
                                        </div>
                                    </form>
                                </div>
                            </CustomTabPanel>
                        </div>
                    </Box>
                </div>
            </div>
        </section >
    )
};

SignIn.defaultProps = {
    title: "My Photo Keyboard | Sign In"
};

export default SignIn;