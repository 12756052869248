import { useContext, useEffect, useState } from "react";
import { Button, Input, Option, Radio, Select, Spinner, Typography } from '@material-tailwind/react'
import { toast } from 'react-toastify'
import { Box, Modal } from '@mui/material'

import { NewContext } from "../../../Context/NewContext";
import { add_banners, add_custom_button, add_functionality_fonts, add_slider, add_tab, get_country_data, get_region_data, get_slider_tag, update_banners, update_custom_button, update_functionality_fonts, update_slider, update_tab } from '../../../Controller/actions'
import FileInput from '../../FileInput/FileInput';
import Switch from '../../Switch/Switch';
import { MultiSelect } from "react-multi-select-component";
import { getOneThemeOffensiveReport } from '../../../Controller/actions'


const style = {
    position: 'absolute',
    top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2, px: 4, pb: 3,
};

export const ReportChildModal = () => {
    const { formData, isLoading, setMultiCountry, updateStateAryData, handleSelectMultiCountry, capitalalizeFirstletter, setMultiRegion, multiRegion, multiCountry, handleSingleSelect, childFormData, childOpen, handleClose, handleChildClose, handleChildChange, handleChildSwitch, setIsLoading, setDeleteUpdateTag, deleteUpdateYes, addEditDeleteTag, childReportOpen, handleReportChildClose, currentHomeModalKey } = useContext(NewContext);

    const [Themereson, setThemereson] = useState([]);
    const themeId = formData?.reportOnThemeId?.map((item) => item._id)
    useEffect(() => {
        if (themeId) {
            getOneThemeOffensiveReport(themeId).then((res) => {
                setThemereson(res.data)
            })
        }
    }, [formData])

    return (
        <>
            <Modal
                open={childReportOpen}
                onClose={handleReportChildClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{
                    "& .MuiBox-root": {
                        width: "100%",
                        maxWidth: `885px`,
                        borderRadius: "12px"
                    },

                }}
            >
                <Box className='p-8 bg-white' sx={{ ...style, width: 200, border: "none" }}>
                    <div className="">
                        <div className="mb-[30px] text-[#1E1627] text-[28px] font-medium leading-none">
                            {'Report Details View'}
                        </div>

                        <div className="mb-4 bg-[#EAEDF3] p-2.5">
                            <div className="flex items-center justify-between">
                                <h6 className="text-[#1E3354] text-lg leading-none font-semibold ">{"Offensive Report Details"}</h6>
                                <div className="flex items-center gap-5">
                                    <p className='text-[#1E3354] text-lg leading-none font-semibold'>{`No. of Reports ${Themereson?.length || 0}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 grid grid-cols-12">
                            <div className="col-span-12" style={{ height: "200px", overflow: "auto" }}>
                                <table className='w-full max-w-max text-left table-auto'>
                                    <thead className='text-[#1E3354] text-lg leading-8' >
                                        <tr>
                                            <th className='w-64'>Name</th>
                                            <th className='w-64'>Email id</th>
                                            <th className='w-64'>Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-[17px] text-[#75869F] leading-8'>
                                        {
                                            Themereson?.map((item, i) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{i + 1} {item?.reportBy?.name || "None"}</td>
                                                            <td>{item?.reportBy?.email || "None"}</td>
                                                            <td>{item?.reasonId?.reason || "None"}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='flex flex-row justify-end mt-10 gap-3'>
                            <Button onClick={() => { handleReportChildClose(); }} className="px-5 py-2.5 bg-transparent shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-xs md:text-base text-center">
                                Cancel
                            </Button>

                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ReportChildModal
