import React, { useContext, useEffect, useState } from 'react'

import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";

import { BiEdit } from 'react-icons/bi';
import { IoEyeOutline, IoClose } from 'react-icons/io5';

import { Pagination, SearchAndAddBtn, Switch, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_Community_Reason } from '../../../Controller/actions';
import { RiDeleteBinLine } from 'react-icons/ri';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ReasonModel from '../../../Components/Modals/ReasonModel';


export const Reason = ({ title }) => {
    const { permissions, tokenType, setDeleteUpdateTag, setDeleteUpdateItem, setAddEditDeleteTag, setFormData, confirmationModalShow, setConfirmationModalShow, handleSwitchUpdate, deleteUpdateTag, isLoading, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitch, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Reason", sortable: true, field: 'Reason' },
    ];
    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });
    const dropdownFields = [{ name: "Active", }];
    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const getFontListData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getFontList = await get_Community_Reason(_currentPage, perPage, search, filterDropdown);
            if (getFontList) {
                const { status, data, currentPage, perPage, totalPage } = getFontList;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(currentPage);
                    setPerPage(perPage);
                    setTotalPage(totalPage || 1);
                } else {
                    // toast.error(message);
                    setCurrentPageData([]);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getFontListData(currentPage);
        setDeleteUpdateTag("")
    }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Reason');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])


    useEffect(() => {
        document.title = title;
    }, [title])


    return (
        <>
            <SearchAndAddBtn title={`community Reson`} addBtn={pagePermissions?.CU} deleteUpdateTag='all-delete-communityReason' countryFilter={false} />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        <THead dropdownFields={dropdownFields} simpleFields={simpleFields} countryFilter={false} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            value={`${_ele._id}`}
                                                            disabled={!pagePermissions?.CU}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>

                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele?.reason && `text-gray-500`}`}>{_ele?.reason || `none`}</span>
                                                    </td>

                                                    {
                                                        ["isActive"].map((_is_ele, i) => (
                                                            <td className={classes} key={i}>
                                                                <div className="ml-1">
                                                                    {/* <Switch id={_ele._id} name={_is_ele} color="green" onChange={(e) => { setDeleteUpdateYes("updateslider"); handleSwitch(e) }} checked={_ele[_is_ele]} /> */}
                                                                    <Switch disabled={!pagePermissions?.CU} id={_ele._id} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-Reason"); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                                                </div>
                                                            </td>
                                                        ))
                                                    }
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-Reason"); setAddEditDeleteTag(`update`); setFormData(_ele); handleOpen() }}>
                                                                <BiEdit />
                                                            </IconButton>
                                                            {/* <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                                                <IoEyeOutline />
                                                            </IconButton> */}
                                                            <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-reason"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            {
                totalPage > 1 &&
                <Pagination currentPage={currentPage} totalPage={totalPage} getData={getFontListData} />
            }
            <ReasonModel />
        </>
    )
}
Reason.defaultProps = {
    title: "My Photo Keyboard | community Reson"
};
export default Reason;
