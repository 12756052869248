import React, { useContext, useEffect, useState } from 'react'
import { Typography, Button, Input, Spinner } from "@material-tailwind/react";
import { toast } from 'react-toastify';
import { get_blog_categories, update_blog, add_blog, add_blog_categories } from '../../../Controller/actions';
import { NewContext } from '../../../Context/NewContext';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MultiSelect } from 'react-multi-select-component';
import FileInput from '../../../Components/FileInput/FileInput';
import { addBlogCategories } from '../../../Controller/api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CreateSelect from 'react-select/creatable';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import Switch from '../../../Components/Switch/Switch';

export const NewBlogs = ({ title }) => {
  const navigate = useNavigate();

  const { setFormDataBoolean, currentHomeModalKey, isLoading, setFormData, updateStateAryData, capitalalizeFirstletter, setMultiRegion, setDeleteUpdateTag, deleteUpdateYes, setIsLoading, handleClose, handleChange, formData, open, addEditDeleteTag, handleSwitch } = useContext(NewContext);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [pendingCategory, setPendingCategory] = useState('');

  const [content, setContent] = useState('');

  // const onChange = (evt) => {
  //   const newContent = evt.editor.getData();
  //   setContent(newContent);
  // };

  function onChange(e) {
    setFormData({ ...formData, blogDetails: e })
  }

  const handleCategoryChange = async () => {
    const getCategoryOptions = await get_blog_categories();
    const options = getCategoryOptions.data
      .filter(item => item.isActive)
      .map(item => ({
        _id: item._id,
        value: item.name,
        label: item.name,
        name: 'categories'
      }));
    setCategory(options);
    if (pendingCategory) {
      const latestTag = options.find(opt => opt.label === pendingCategory);
      if (latestTag) {

        setSelectedCategory(latestTag._id)
      }
      setPendingCategory('');
    }
  };
  useEffect(() => {
    handleCategoryChange();
  }, []);
  useEffect(() => {
    if (currentHomeModalKey === 'update-c') {
      if (formData?.categories?.length) {
        const categoryToSet = category.find(cat => cat._id === formData.categories[0]);
        if (categoryToSet) {
          setSelectedCategory(categoryToSet._id);
        }
      }
    } else {
      if (formData?.categoriesName) {
        const categoryToSet = category.find(cat => cat.label === formData.categoriesName);
        if (categoryToSet) {
          setSelectedCategory(categoryToSet._id);
        }
      }
    }

  }, [addEditDeleteTag, currentHomeModalKey, category, formData?.categories]);

  const handleImageUpload = (blobInfo, success, failure) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      success(reader.result);
    };
    reader.onerror = () => {
      failure('Failed to upload image');
    };
    reader.readAsDataURL(blobInfo.blob());
  };
  const handleCreateOption = inputString => {
    setPendingCategory(inputString);
    const addCategoriesPromise = add_blog_categories({
      name: inputString,
      isActive: true
    });
    addCategoriesPromise.then(() => get_blog_categories());
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formData.blogDetails;
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    const trimmedBlogDetails = textContent.trim();

    if (trimmedBlogDetails === "" && !tempDiv.innerHTML.includes("<img")) {
      toast.error("Blog details cannot be empty.");
      setIsLoading(false);
      return;
    }

    let response;

    if (currentHomeModalKey === 'update-c') {
      response = await update_blog({ ...formData, categories: selectedCategory }, formData?._id);
    } else {
      response = await add_blog({ ...formData, categories: selectedCategory });
    }
    if (response) {
      const { message, status } = response;
      if (status) {
        toast.success(message);
        handleClose();
        setDeleteUpdateTag(deleteUpdateYes);
        setSelectedCategory(null);
        setCategory([]);
        setMultiRegion([]);
        setFormData({});
        setFormDataBoolean(false);
        navigate('/blogs');
      } else {
        toast.error(message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (addEditDeleteTag === `add`) setFormData({ ...formData, isActive: true })
  }, [addEditDeleteTag, open]);

  var modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video", "formula"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [
        { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "#0000ee", 'custom-color'] },
        { "background": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }
      ]
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "background", "bullet", "indent",
    "link", "image", "video", "formula", "align", "size",
  ];

  useEffect(() => {
    document.title = title;
  }, [title])


  function onChange123(e) {
    setFormData({ ...formData, blogDetails: e })
  }

  return (
    <>
      <div
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1085px",
              borderRadius: "12px"
            },
          },
        }}
        className='bg-gray-50'
        style={{ backgroundColor: "#fff" }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627] flex items-center justify-between'>
            {
              addEditDeleteTag === 'view' ? 'Details  View' : currentHomeModalKey === `update-c` ? `Edit Blog` : `Add New Blog`
            }
            <div>
              <Button
                disabled={Object.keys(formData).length === 0}
                onClick={() => {
                  setFormData({ ...formData })

                  // dispatch(setFormData({ ...formData, blogDetails: blogDetailsData, categoriesname: selectedCategories?.label }))
                  navigate("/previewBlogs")
                }}
                className="inline-flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                type='button'
              >
                <span className={isLoading && 'ml-4'}>Preview</span>
              </Button>

              <Button
                disabled={isLoading}

                className="inline-flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                type='submit'
              >
                {isLoading && <Spinner className="h-4 w-4" />}
                <span className={isLoading && 'ml-4'}>{currentHomeModalKey === `update-c` ? `Update` : `Publish`}</span>
              </Button>
            </div>
          </DialogTitle>

          <DialogContent>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 relative">
            </div>
            <div>
              {
                addEditDeleteTag !== 'view' ?
                  <>
                    <div className="mt-1 grid grid-cols-6 lg:grid-cols-12 gap-5 add-edit-form">
                      <div className='lg:col-span-4'>
                        <Typography className="text-lg text-[#1E1627] font-medium">Preview (1074 x 566):</Typography>
                        <div className='w-auto'>
                          <FileInput name={`blogPreview`} imgClass={`h-full`} previewFields={'preview'} accept={'image/*'} width={1074} height={566} acceptedExtensions={/\.(jpg|png|webp)$/} required={true} />
                        </div>
                      </div>
                      <div className="col-span-6 lg:col-span-4">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">Title:</label>
                        <div className="my-2">
                          <Input id='title' placeholder='Enter title' name='title' onChange={handleChange} value={formData.title || ''} required={true} />
                        </div>
                        <label htmlFor="categories" className="block text-sm font-medium leading-6 text-gray-900 -z-10">Category:</label>
                        <div className="my-1.5 category-multiselect">
                          <CreateSelect
                            name='category'
                            options={category}
                            onCreateOption={handleCreateOption}
                            onChange={e => {
                              setSelectedCategory(e ? e._id : null)
                              setFormData({ ...formData, categories: [e._id], categoriesName: e.label })
                            }}
                            value={category.find(cat => cat._id === selectedCategory)}
                          />
                        </div>
                        <label htmlFor="showStartDate" className="block text-sm font-medium leading-6 text-gray-900">Description:</label>
                        <div className="my-1.5">
                          <textarea onChange={handleChange} value={formData.description || ''} id="description" name='description' rows="3" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..." required={true}></textarea>
                        </div>
                      </div>
                      <div className="col-span-6 lg:col-span-4">
                        <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">Slug:</label>
                        <div className="my-2">
                          <Input id='slug' placeholder='Enter slug' name='slug' onChange={handleChange} value={formData.slug || ''} required={true} />
                        </div>
                        <label htmlFor="metaTitle" className="block text-sm font-medium leading-6 text-gray-900">Meta Title:</label>
                        <div className="my-1.5">
                          <Input id='metaTitle' placeholder='Enter meta title' name='metaTitle' onChange={handleChange} value={formData.metaTitle || ''} required={true} />
                        </div>
                        <label htmlFor="metaDescription" className="block text-sm font-medium leading-6 text-gray-900">Meta Description:</label>
                        <div className="my-1.5">
                          <textarea onChange={handleChange} value={formData.metaDescription || ''} id="metaDescription" name='metaDescription' rows="3" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..." required={true}></textarea>
                        </div>
                      </div>

                      <div className="col-span-6 lg:col-span-4">
                        <label htmlFor="alterText" className="block text-sm font-medium leading-6 text-gray-900">Alter Image text:</label>
                        <div className="my-1.5">
                          <Input id='alterText' placeholder='Enter alter text' name='alterText' autoComplete="off" onChange={handleChange} value={formData?.alterText || ''} required={true} />
                        </div>
                      </div>
                      <div className="col-span-6 lg:col-span-4">
                        <label htmlFor="scheduleDate" className="block text-sm font-medium leading-6 text-gray-900">scheduleDate:</label>
                        <div className="my-2">
                          <Input type='date' id='scheduleDate' placeholder='Enter scheduleDate' onChange={handleChange} name='scheduleDate' value={moment(formData?.scheduleDate || '').format("YYYY-MM-DD")} required={true} />
                        </div>
                      </div>
                      <div className="col-span-1 mt-10 flex lg:col-span-2">
                        <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                        <div className="ml-4">
                          <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12 mb-10">
                        <label htmlFor="showStartDate" className="block text-sm font-medium leading-6 text-gray-900">Blog Details:</label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          style={{ height: "600px" }}
                          value={formData.blogDetails || ""}
                          onChange={onChange}
                        >
                        </ReactQuill>
                        {/* <Editor
                          apiKey='i1f8zfhhbw59h4xu0jhyt3h5y22irzw91oi4jx8vzdr0r5l5'
                          init={{
                            branding: false,
                            min_height: 600,
                            menubar: true,
                            sandbox: 'allow-scripts allow-same-origin',
                            plugins:
                              "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                            toolbar:
                              "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                            image_advtab: true,
                            images_upload_handler: handleImageUpload,
                          }}
                          value={formData.blogDetails || ""}
                          onEditorChange={onChange}
                        /> */}
                      </div>


                    </div>
                  </>
                  :
                  <div>
                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                      <div className="sm:col-span-3">
                        <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Title:</label>
                        <div className="mt-2">
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.title || `None`}</Typography>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Created At:</label>
                        <div className="mt-2">
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.createdAt?.slice(0, 10) || `None`}</Typography>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Category:</label>
                        <div className="mt-2">
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.categories || `None`}</Typography>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                        <div className="mt-2">
                          {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "Off" : "On"}</Typography>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Description:</label>
                        <div className="mt-2">
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.description || `None`}</Typography>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">View:</label>
                        <div className="mt-2">
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.view || `0`}</Typography>
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Blog Details:</label>
                        <div className="mt-2">
                          <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.blogDetails || `none`}</Typography>
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-end'>
                      <Button
                        onClick={handleClose}
                        className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                      >
                        <span>Cancel</span>
                      </Button>
                    </div>
                  </div>
              }
            </div>
          </DialogContent>
        </form>
      </div>
    </>
  )
}

NewBlogs.defaultProps = {
  title: "My Photo Keyboard | Add Blog"
};

export default NewBlogs