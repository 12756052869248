import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip, Button } from "@material-tailwind/react";
import { Dropdowns, Pagination, SearchAndAddBtn, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_storage_data } from '../../Controller/actions';
import { RiDeleteBinLine } from 'react-icons/ri';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { MdOutlineCategory, MdOutlineTimer } from 'react-icons/md';
import StorageModal from '../../Components/Modals/StorageModal';

const FilterDropdowns = () => {
  const { permissions, tokenType, handleDeleteAll } = useContext(NewContext)
  const statusFilters = [{ id: "pending", label: "Pending" }, { id: "approved", label: "Approved" }, { id: "reject", label: "Reject" }]
  const typeFilters = [{ id: "db", label: "DB" }, { id: "valid-storage", label: "Valid Storage" }, { id: "extra-storage", label: "Extra Storage" }]
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Community Storage');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])
  return (
    <>
      <div className='flex gap-4'>
        <Dropdowns title={`type`} name={`type`} Icon={MdOutlineCategory} dropData={typeFilters} />
        <Dropdowns title={`Theme Status`} name={`status`} Icon={MdOutlineCategory} dropData={statusFilters} />
        <Dropdowns title={`Date and time`} name={`dateTime`} Icon={MdOutlineTimer} dateRange={true} />
      </div>
      {pagePermissions?.D ? (<div className='flex gap-4'>
        <Button className='bg-[#F22F42] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3'
          onClick={() => handleDeleteAll("remove-all-community-extra-files")}
        >
          Delete All Extra Files
        </Button>
      </div>) : <></>}
    </>
  )
}
export const CommunityStorage = ({ title }) => {
  const { permissions, tokenType, isLoading, checkedValues, handleCheckboxChange, filterDropdown, search, totalPage, currentPage, perPage, setTotalPage, setPerPage, setCurrentPage, setIsLoading, currentPageData, setCurrentPageData, handleCopyData, checkType, setCheckType, budge, setBudge, setDeleteUpdateYes, setDeleteUpdateItem, setConfirmationModalShow, confirmationModalShow, deleteUpdateTag } = useContext(NewContext);
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  const dbStorageFields = [
    { name: "Theme Status" },
    { name: "Theme Zip" },
    { name: "Preview" },
    { name: "Small Preview" },
    { name: "Gif File" },
    { name: "Small Gif" },
    { name: "Gif BgPath" },
  ];

  const fileStorageFields = [
    { name: "Name" },
    { name: "Size" },
    { name: "Date" },
  ];

  const totalDbFields = dbStorageFields.length + 2;
  const totalFilesFields = fileStorageFields.length + 2;

  const getStorageData = async (_currentPage = 1) => {
    setIsLoading(true);
    try {

      const getStorage = await get_storage_data(_currentPage, perPage, search, filterDropdown);

      if (getStorage) {
        const { status, themes, files, currentPage, perPage, totalPage, type } = getStorage;
        if (status) {
          if (type === "db") {
            setCurrentPageData(themes);
          } else {
            setCurrentPageData(files);
          }
          setCurrentPage(currentPage);
          setPerPage(perPage);
          setTotalPage(totalPage || 1);
          setCheckType(type);
        } else {
          // toast.error(message);
          setCurrentPageData([]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStorageData(currentPage);
    // setDeleteUpdateTag("");
  }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Community Storage');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])

  useEffect(() => {
    document.title = title;
  }, [title])



  return (
    <>
      {/* recommendedLoading={true}  */}
      <SearchAndAddBtn title={`Storage`} FilterDropdowns={<FilterDropdowns />} addBtn={false} countryFilter={false} deleteUpdateTag={checkType === "db" ? 'all-delete-approvedTheme' : 'all-delete-storage'} infoBtn={true} />
      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
          <table className="w-full min-w-max table-auto text-left">
            <THead simpleFields={checkType === "db" ? dbStorageFields : fileStorageFields} actionField={checkType === "valid-storage" ? false : true} isSelectAll={checkType === "valid-storage" ? false : true} />
            <tbody className='h-'>
              {
                isLoading ?
                  <tr>
                    <td colSpan={checkType === "db" ? totalDbFields : totalFilesFields} className='py-12'>
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                  : currentPageData?.length === 0 ?
                    <tr>
                      <td colSpan={checkType === "db" ? totalDbFields : totalFilesFields} className='py-12 bg-gray-200'>
                        <div className="flex justify-center items-center">
                          No Data Found
                        </div>
                      </td>
                    </tr>
                    : currentPageData?.map((_ele, i) => {
                      const isLast = i === currentPageData?.length - 1;
                      const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;
                      return (
                        checkType === "db" ?
                          <tr key={i} className='border-b border-blue-gray-50'>
                            <td className={classes}>
                              <Checkbox
                                className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                id={`slider-${_ele._id}`}
                                value={`${_ele._id}`}
                                disabled={!pagePermissions?.CU}
                                checked={checkedValues.includes(`${_ele._id}`)}
                                onChange={() => handleCheckboxChange(`${_ele._id}`)}
                              />
                            </td>
                            <td className={classes}>
                              <span className={`font-normal text-[17px] ${!_ele.themeStatus && `text-gray-500`}`}>{_ele.themeStatus || `None`}</span>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center">
                                <span
                                  className={`font-normal text-[17px] !w-40 truncate ${!_ele.themeZip && `text-gray-500`} cursor-pointer`}
                                  onClick={() => handleCopyData(`Theme Zip`, _ele.themeZip)}
                                >
                                  {_ele.themeZip || `None`}
                                </span>
                                {
                                  _ele.themeZip &&
                                  <Tooltip placement="right-start" content={
                                    <div className="p-2 w-[300px] h-20 2xl:w-[400px] overflow-y-auto ">
                                      {_ele.themeZip}
                                    </div>
                                  }>
                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                  </Tooltip>
                                }
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center">
                                <span
                                  className={`font-normal text-[17px] !w-40 truncate ${!_ele.preview && `text-gray-500`}  cursor-pointer`}
                                  onClick={() => handleCopyData(`Preview`, _ele.preview)}
                                >{_ele.preview || `None`}</span>
                                {
                                  _ele.preview &&
                                  <Tooltip placement="right-start" content={
                                    <div className="p-2 w-[300px] h-20 2xl:w-[400px] overflow-y-auto ">
                                      {_ele.preview}
                                    </div>
                                  }>
                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                  </Tooltip>
                                }
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center">
                                <span
                                  className={`font-normal text-[17px] !w-40 truncate ${!_ele.smallPreview && `text-gray-500`}  cursor-pointer`}
                                  onClick={() => handleCopyData(`Small Preview`, _ele.smallPreview)}
                                >{_ele.smallPreview || `None`}</span>
                                {
                                  _ele.smallPreview &&
                                  <Tooltip placement="right-start" content={
                                    <div className="p-2 w-[300px] h-20 2xl:w-[400px] overflow-y-auto ">
                                      {_ele.smallPreview}
                                    </div>
                                  }>
                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                  </Tooltip>
                                }
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center">
                                <span
                                  className={`font-normal text-[17px] !w-40 truncate ${!_ele.gifFile && `text-gray-500`}  cursor-pointer`}
                                  onClick={() => handleCopyData(`Gif File`, _ele.gifFile)}
                                >{_ele.gifFile || `None`}</span>
                                {
                                  _ele.gifFile &&
                                  <Tooltip placement="right-start" content={
                                    <div className="p-2 w-[300px] h-20 2xl:w-[400px] overflow-y-auto ">
                                      {_ele.gifFile}
                                    </div>
                                  }>
                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                  </Tooltip>
                                }
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center">
                                <span
                                  className={`font-normal text-[17px] !w-40 truncate ${!_ele.smallGif && `text-gray-500`} cursor-pointer`}
                                  onClick={() => handleCopyData(`Small Gif`, _ele.smallGif)}
                                >{_ele.smallGif || `None`}</span>
                                {
                                  _ele.smallGif &&
                                  <Tooltip placement="right-start" content={
                                    <div className="p-2 w-[300px] h-20 2xl:w-[400px] overflow-y-auto ">
                                      {_ele.smallGif}
                                    </div>
                                  }>
                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                  </Tooltip>
                                }
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center">
                                <span
                                  className={`font-normal text-[17px] !w-40 truncate ${!_ele.gifBgPath && `text-gray-500`} cursor-pointer`}
                                  onClick={() => handleCopyData(`Gif BgPath`, _ele.gifBgPath)}
                                >{_ele.gifBgPath || `None`}</span>
                                {
                                  _ele.gifBgPath &&
                                  <Tooltip placement="right-start" content={
                                    <div className="p-2 w-[300px] h-20 2xl:w-[400px] overflow-y-auto ">
                                      {_ele.gifBgPath}
                                    </div>
                                  }>
                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                  </Tooltip>
                                }
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-2">
                                <IconButton
                                  className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D}
                                  onClick={() => { setDeleteUpdateYes("delete-approvedTheme"); setDeleteUpdateItem({ id: _ele?._id }); setConfirmationModalShow(!confirmationModalShow) }}
                                >
                                  <RiDeleteBinLine />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                          :
                          <tr key={i} className='border-b border-blue-gray-50'>
                            {
                              checkType !== "valid-storage" ?
                                <td className={classes}>
                                  <Checkbox
                                    className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                    id={`slider-${_ele._id}`}
                                    value={`${_ele._id}`}
                                    disabled={!pagePermissions?.CU}
                                    checked={checkedValues.includes(`${_ele._id}`)}
                                    onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                  />
                                </td>
                                : null
                            }
                            <td className={classes}>
                              <span
                                className={`font-normal text-[17px] ${!_ele.name && `text-gray-500`} cursor-pointer`}
                                onClick={() => handleCopyData(`Name`, _ele.name)}
                              >{_ele.name || `None`}</span>
                            </td>
                            <td className={classes}>
                              <span
                                className={`font-normal text-[17px] ${!_ele.size && `text-gray-500`}`}
                              >{_ele.size || `None`}</span>
                            </td>
                            <td className={classes}>
                              <span className={`font-normal text-[17px] ${!_ele.createDate && `text-gray-500`}`}>{_ele.createDate || `None`}</span>
                            </td>
                            {
                              checkType !== "valid-storage" ?
                                <td className={classes}>
                                  <div className="flex items-center gap-2">
                                    <IconButton
                                      className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D}
                                      onClick={() => { setDeleteUpdateYes("delete-storage"); setDeleteUpdateItem({ id: _ele?._id }); setConfirmationModalShow(!confirmationModalShow) }}
                                    >
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  </div>
                                </td>
                                : null
                            }
                          </tr>
                      );

                    })

              }
            </tbody>
          </table>
        </CardBody>
      </Card >
      {
        totalPage > 1 &&
        <Pagination currentPage={currentPage} totalPage={totalPage} getData={getStorageData} />
      }
      <StorageModal />

    </>
  )
}
CommunityStorage.defaultProps = {
  title: "My Photo Keyboard | Storage"
};


export default CommunityStorage
