import React, { useContext, useEffect, useState } from 'react'
import { NewContext } from '../../Context/NewContext';
import { add_background_category, add_tags, add_theme_category, get_country_data, get_region_data } from '../../Controller/actions';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Dialog, Input, Spinner, Typography } from '@material-tailwind/react';
import FileInput from '../FileInput/FileInput';
import { Box, Modal } from '@mui/material';
import THead from '../Table/THead';
import { MultiSelect } from 'react-multi-select-component';
import Switch from '../Switch/Switch';

const style = {
    position: 'absolute',
    top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2, px: 4, pb: 3,
};

const BackgroundChildModal = () => {
    const { homeActivityRef, setCurrentHomeModalKey, updateStateAryChildData, capitalalizeFirstletter, childOpen, isSubModalLoading, setIsSubModalLoading, currentHomeModalKey, childFormData, handleChildClose, setDeleteUpdateTag, deleteUpdateYes, addEditDeleteTag, setAddEditDeleteTag, handleChildChange, multiCountry, setMultiCountry, handleSelectMultiCountry, handleChildSwitch } = useContext(NewContext);
    const [country, setCountry] = useState([]);
    const [isUpdateSubModalLoading, setIsUpdateSubModalLoading] = useState(false);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const getDataFunc = async () => {
        setIsUpdateSubModalLoading(true);
        try {
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (countryData.status && regionData.status) {
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
            } else {
                toast.error(countryData.message || regionData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsUpdateSubModalLoading(false);
    };
    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag, currentHomeModalKey]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let createUpdateData;
        setIsSubModalLoading(true);

        createUpdateData = await add_background_category(childFormData)
        // if (currentHomeModalKey === `addCategory`) {
        //     createUpdateData = await add_theme_category(childFormData)
        // } else if (currentHomeModalKey === `addTag`) {
        //     createUpdateData = await add_tags(childFormData)
        // }
        if (createUpdateData) {
            const { message, status } = createUpdateData;
            if (status) {
                toast.success(message);
                handleChildClose();
                setDeleteUpdateTag(deleteUpdateYes);
                setCurrentHomeModalKey("");
            } else {
                toast.error(message);
            };
        }
        setIsSubModalLoading(false);
    }
    return (
        <Modal
            open={childOpen}
            onClose={() => { handleChildClose(); setAddEditDeleteTag(`add`); }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            sx={{
                "& .MuiBox-root": {
                    width: "100%",
                    maxWidth: `${currentHomeModalKey === 'slider' ? "800px" : "567px"}`,
                    borderRadius: "12px"
                },
            }}
        >
            <Box className='p-8 bg-white' sx={{ ...style, width: 200, border: "none" }}>
                <form onSubmit={handleSubmit}>
                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                        <div className="sm:col-span-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                            <div className="mt-2">
                                <Input id='name' placeholder='Enter Name' name='name' onChange={handleChildChange} value={childFormData?.name} autoComplete='false' required />
                            </div>
                        </div>
                        {/* <div className="sm:col-span-6">
                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">Region:</label>
                            <div className="mt-1.5">
                                {
                                    region && region.length > 0 &&
                                    <MultiSelect
                                        labelledBy='Select Region'
                                        options={region}
                                        value={multiRegion}
                                        onChange={handleSelectMultiRegion}
                                        hasSelectAll={false}
                                        ClearSelectedIcon={null}
                                    />
                                }
                            </div>
                        </div> */}
                        <div className="sm:col-span-6">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                            <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                {
                                    country && country.length > 0 &&
                                    <MultiSelect
                                        labelledBy='Select Country'
                                        options={country}
                                        value={multiCountry}
                                        onChange={handleSelectMultiCountry}
                                        hasSelectAll={false}
                                        ClearSelectedIcon={null}
                                        isOpen={isOpenCountryDropDown}
                                    />
                                }
                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                            </div>
                        </div>
                        <div className="sm:col-span-6">
                            <label htmlFor="priority" className="block text-sm font-medium leading-6 text-gray-900">Priority:</label>
                            <div className="mt-2">
                                <Input id='priority' placeholder='Enter Priority' name='priority' onChange={handleChildChange} value={childFormData?.priority} required />
                            </div>
                        </div>
                        <div className="sm:col-span-6">
                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                            <div className="mt-1.5">
                                <Switch color='green' id='isActive' name='isActive' onChange={handleChildSwitch} checked={childFormData?.isActive} />
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end mt-10'>
                        <Button
                            onClick={() => { handleChildClose(); setAddEditDeleteTag(`add`); }}
                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button
                            disabled={isSubModalLoading || isUpdateSubModalLoading}
                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                            type='submit'
                        >
                            {(isSubModalLoading || isUpdateSubModalLoading) && <Spinner className="h-4 w-4" />}
                            <span className={(isSubModalLoading || isUpdateSubModalLoading) && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                        </Button>
                    </div>
                </form >
            </Box >
        </Modal>
    )
}

export default BackgroundChildModal