import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Input, Spinner } from "@material-tailwind/react";
import CreatableSelect from 'react-select/creatable';
import { NewContext } from '../../Context/NewContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BackgroundChildModal from './BackgroundChildModal';
import { add_asset, update_asset } from '../../Controller/actions';
import { toast } from 'react-toastify';

export const AssetsModel = () => {
    const { isModalLoading, handleClose, handleChange, formData, open, addEditDeleteTag, setIsModalLoading, setFormData, selectedOption, setSelectedOption } = useContext(NewContext);

    const options = [
        { value: 'Privacy-policy', label: 'Privacy-policy' },
        { value: 'Terms-Conditions', label: 'Terms-Conditions' },
        { value: 'PlayStore', label: 'PlayStore' },
        { value: 'Webpages', label: 'Webpages' },
        { value: 'Delete Account', label: 'Delete Account' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        let createupdateAsset;
        setIsModalLoading(true);

        if (addEditDeleteTag === "update") {
            createupdateAsset = await update_asset({ ...formData, title: selectedOption.value }, formData?._id);
        } else {
            createupdateAsset = await add_asset({ ...formData, title: selectedOption.value });
        }
        setIsModalLoading(false);
        if (createupdateAsset) {
            const { message, status } = createupdateAsset;
            if (status) {
                toast.success(message);
                setFormData([]);
                handleClose();
                setSelectedOption(null);
            } else {
                toast.error(message);
            }
        }
        handleClose();
    };

    useEffect(() => {
        setFormData({ ...formData });
    }, [setFormData]);

    useEffect(() => {
        if (formData && formData.title) {
            setSelectedOption({ value: formData.title, label: formData.title });
        }
    }, [addEditDeleteTag]);

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setSelectedOption(null); }}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            height: "100%",
                            maxWidth: "400px",
                            maxHeight: "400px",
                            borderRadius: "12px"
                        },
                    },
                }} >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {
                        addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Assets` : `Add Assets`
                    }
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-6">
                                            <div className='mt-2'>
                                                <label htmlFor="Title" name='title' className="block text-sm font-medium leading-6 text-gray-900">Title:</label>
                                                <CreatableSelect
                                                    className='capitalize'
                                                    required
                                                    options={options}
                                                    value={selectedOption}
                                                    onChange={(selectedOption) => {
                                                        setSelectedOption(selectedOption);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="description" className="block text-lg font-medium leading-5 text-[#1E3354] mt-4">Description:</label>
                                            <div className="mt-2">
                                                <Input id='description' required placeholder='Enter Description' name='description' onChange={handleChange} value={formData?.description} autoComplete='off' />
                                            </div>


                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-24'>
                                        <Button onClick={() => { handleClose() }} className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-6">
                                            <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Title:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5" autoComplete="off">{formData?.title || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <label htmlFor="description" className="block text-lg font-medium leading-5 text-[#1E3354]">Description:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5 capitalize break-words">{formData?.description || `None`}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-5'>
                                        <Button
                                            onClick={() => { handleClose(); setSelectedOption(null); setFormData({}) }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base mt-24"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            <BackgroundChildModal />
        </>
    )
}

export default AssetsModel;
