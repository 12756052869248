import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Input, Spinner, } from "@material-tailwind/react";
import { add_keys, get_country_data, get_region_data, get_slider_tag, update_keys } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileInput from '../FileInput/FileInput';

export const KeysModal = () => {
    const { truncateString, homeActivityRef, isModalLoading, setDeleteUpdateTag, deleteUpdateYes, handleSelectMultiCountry, handleSwitch, handleFileInput, setIsModalLoading, handleClose, handleChange, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, multiCountry, setMultiCountry, setMultiRegion } = useContext(NewContext);

    const [tag, setTag] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const tagData = await get_slider_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (tagData.status && countryData.status && regionData.status) {
                setTag(tagData.data);
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                }
            } else {
                toast.error(tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };

    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setDeleteUpdateTag(deleteUpdateYes);
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_keys({ ...formData, country: countryIdAry || [], region: formData?.region || [] }, formData?._id)
        } else {
            createUpdateSlider = await add_keys(formData)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                // setValidated(false);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "520px",
                            borderRadius: "12px"
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {
                        addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Keys` : `Add New Keys`
                    }
                </DialogTitle>
                <DialogContent>
                    <div className="">
                        <Typography className="text-lg text-[#1E1627] font-medium">Preview (109 x 108):</Typography>
                        <div className='w-auto'>
                            <FileInput name={`keyPreview`} imgClass={`h-full`} previewFields={'preview_img'} accept={'image/*'} width={109} height={108} acceptedExtensions={/\.(jpg|png|webp)$/} />
                        </div>
                    </div>
                    <div>
                        {
                            <form onSubmit={handleSubmit}>
                                <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                        <div className="mt-2">
                                            <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name} autoComplete='false' />
                                        </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="keyZip" className="block text-sm font-medium leading-6 text-gray-900">Keys File:</label>
                                        <div className="mt-1.5 relative">
                                            <input id='keyZip' type='file' accept='application/x-zip-compressed' name='keyZip' onChange={handleFileInput} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' required={addEditDeleteTag !== `update`} />
                                            {
                                                addEditDeleteTag === `update` && formData.key_data && typeof formData.key_data === 'string' && !formData.keyZip &&
                                                <label htmlFor="bigPreviewStickerStore" className='absolute sm:left-[22%] left-[33.5%] right-[2px] w-auto rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData.key_data.split('/').pop(), 24)}</label>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                        <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                            {
                                                country && country.length > 0 &&
                                                <MultiSelect
                                                    labelledBy='Select Country'
                                                    options={country}
                                                    value={multiCountry}
                                                    onChange={handleSelectMultiCountry}
                                                    hasSelectAll={false}
                                                    ClearSelectedIcon={null}
                                                    isOpen={isOpenCountryDropDown}
                                                />
                                            }
                                            <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                        </div>
                                    </div>

                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="isPremium" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='isPremium' name='isPremium' onChange={handleSwitch} checked={formData?.isPremium} />
                                        </div>
                                    </div>

                                    <div className="col-span-2 sm:col-span-2">
                                        <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end mt-10'>
                                    <Button
                                        onClick={handleClose}
                                        className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                    <Button
                                        disabled={isModalLoading}
                                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                        type='submit'
                                    >
                                        {isModalLoading && <Spinner className="h-4 w-4" />}
                                        <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                    </Button>
                                </div>
                            </form>
                        }
                    </div>
                </DialogContent>

            </Dialog>
        </>
    )
}

export default KeysModal
