import React, { useContext, useEffect, useState } from 'react'
import { Typography, Button, Card, CardBody } from '@material-tailwind/react'
import { FileInput, SearchAndAddBtn } from '../../Components'
import { NewContext } from '../../Context/NewContext';
import { zip_validation } from '../../Controller/actions';
import { toast } from 'react-toastify';

const ZipValidation = () => {
  const { handleFileInput, addEditDeleteTag, formData, tokenType, permissions } = useContext(NewContext);
  const [respMessage, setRespMessage] = useState({})



  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Zip Validation');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])


  const handleSubmit = async () => {
    const zipValidationData = await zip_validation(formData)
    if (zipValidationData) {
      let { message, status } = zipValidationData;
      if (status) {
        // toast.success(message);
        setRespMessage(zipValidationData)
      } else {
        if (Array.isArray(message)) {
          // zipValidationData.message = `Below Filenames are missing from the ZIP file. <br/> ${message.map(fName => (`\u2022 ${fName} <br/>`))} `
          zipValidationData.message = <>
            Below Filenames are missing from the ZIP file.

            <div className='ml-6'>
              <ul className='list-disc'>{
                message.map(fName => (<li className=' ml-3'>{fName}</li>))
              }</ul >
            </div>
          </>
        }
        // toast.error(message);
        setRespMessage(zipValidationData)
      };
    }
  }
  return (
    <>
      <SearchAndAddBtn title={`Theme Zip Validation`} addBtn={false} perPageCount={false} countryFilter={false} isSearch={false} />

      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-5 card-body-scrollbar">
          <div className='flex items-center mt-5 mb-5'>
            <Typography className="block text-sm font-medium leading-6 text-gray-900 mr-2">Upload Zip </Typography>
            <div className='w-auto'>
              <input id='zipFile' type='file' accept='application/x-zip-compressed' name='zipFile' onChange={handleFileInput} className='w-[200 px] border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' required={addEditDeleteTag !== `update`} />
            </div>
            <Button
              // disabled={isModalLoading}
              onClick={handleSubmit}
              className=" items-center ml-5  px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
              type='button'
            >

              <span> Validate Zip</span>
            </Button>
          </div>
          <div className={`mt-2 font-semibold ${respMessage?.status ? 'text-green-700' : 'text-red-700'}`}>
            <span className='px-4'>{respMessage?.message}</span>
          </div>

        </CardBody>
      </Card>
    </>
  )
}

export default ZipValidation
