import React, { useContext, useEffect } from 'react'

import { Card, CardBody, IconButton, Checkbox, Spinner } from "@material-tailwind/react";

import { BiEdit, BiLink } from 'react-icons/bi';
import { IoEyeOutline, IoClose } from 'react-icons/io5';

import { Dropdowns, Pagination, SearchAndAddBtn, Switch, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_wallpaper_categories_data } from '../../../Controller/actions';
import { MdOutlineCategory } from 'react-icons/md';
import WallpaperCategoriesModal from '../../../Components/Modals/WallpaperCategoriesModal';


const FilterDropdowns = () => {
    const countryFilterData = ["Afghanistan", "India", "Pakistan"]
    return (
        <div className='flex gap-4'>
            {/* <Dropdowns name={`Country`} Icon={TbWorld} dropData={countryFilterData} /> */}
            <Dropdowns name={`Categories`} Icon={MdOutlineCategory} dropData={countryFilterData} />
        </div>
    )
}

export const WallpaperCategories = ({ title }) => {
    const { deleteUpdateTag, isLoading, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitch, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Name", },
        { name: "Priority", },
    ];
    const dropdownFields = [{ name: "Active", }];
    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const getWallpaperCategoriesData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getWallpaperCategories = await get_wallpaper_categories_data(_currentPage, perPage, search, filterDropdown);
            if (getWallpaperCategories) {
                const { status, data, currentPage, perPage, totalPage } = getWallpaperCategories;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(currentPage);
                    setPerPage(perPage);
                    setTotalPage(totalPage || 1);
                } else {
                    setCurrentPageData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getWallpaperCategoriesData(currentPage);
    }, [deleteUpdateTag, search, currentPage, perPage]);

    useEffect(() => {
        document.title = title;
    }, [title])

    return (
        <>
            <SearchAndAddBtn title={`Wallpaper Categories`} addBtn={true} FilterDropdowns={false} deleteUpdateTag='all-delete-sliders' />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        {/* <thead className='bg-white'>
                            <tr className='border-b border-transparent'>
                                {TABLE_HEAD.map(({ name, sortable = true }, i) => (
                                    <th key={name} className="cursor-pointer px-4 transition-colors hover:bg-blue-gray-50">
                                        <span className="flex items-center gap-1.5 font-medium text-lg leading-none text-[#1E1627]">
                                        {name}
                                            {sortable && i !== TABLE_HEAD.length - 0 && (
                                                <FaSort className="w-4 h-4 text-[#75869F]" />
                                                )}
                                        </span>
                                    </th>
                                ))}          
                                </tr>
                        </thead> */}
                        <THead
                            simpleFields={simpleFields}
                            dropdownFields={dropdownFields}
                        />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            value={`${_ele._id}`}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px] ${!_ele.name && `text-gray-500`}`}>{_ele.name || `None`}</span>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele.priority && `text-gray-500`}`}>{_ele.priority || `None`}</span>
                                                    </td>
                                                    {
                                                        ["isActive"].map((_is_ele, i) => (
                                                            <td className={classes} key={i}>
                                                                <div className="ml-1">
                                                                    <Switch id={_ele._id} name={_is_ele} color="green" onChange={(e) => { setDeleteUpdateYes("updateslider"); handleSwitch(e) }} checked={_ele[_is_ele]} />
                                                                </div>
                                                            </td>
                                                            // <td key={i}>
                                                            //     <Badge bg={_is_ele ? "success" : "danger"} className="fw-normal px-2">
                                                            //     {_is_ele ? `On` : `Off`}
                                                            // </Badge>
                                                            //     <Form.Check type="switch" id={_ele._id} name={_is_ele} onChange={handleSwitch} checked={_ele[_is_ele]} role="button" />
                                                            // </td>
                                                        ))
                                                    }
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton onClick={() => handleOpen()} className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'>
                                                                <BiEdit />
                                                            </IconButton>
                                                            <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'>
                                                                <IoEyeOutline />
                                                            </IconButton>
                                                            <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none'>
                                                                <IoClose />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
                {
                    totalPage > 1 &&
                    <Pagination currentPage={currentPage} totalPage={totalPage} getData={getWallpaperCategoriesData} />
                }
                <WallpaperCategoriesModal />
            </Card>
        </>
    )
}
WallpaperCategories.defaultProps = {
    title: "My Photo Keyboard | Wallpaper Categories"
};
export default WallpaperCategories
