import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './index.css'
import NewContextProvider from './Context/NewContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NewContextProvider>
        <App />
    </NewContextProvider>
);