import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";
import Zoom from 'react-medium-image-zoom';
import { BiEdit } from 'react-icons/bi';
import { IoEyeOutline, IoImageOutline } from 'react-icons/io5';
import { TbWorld } from 'react-icons/tb';

import { CommunityBtn, Dropdowns, Pagination, SearchAndAddBtn, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_CommunityUserReport_data } from '../../../Controller/actions';
import { MdOutlineCategory } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';
import ReportModal from '../../../Components/Modals/ReportModal/ReportModal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
// import { Zoom } from 'react-toastify';


const FilterDropdowns = ({ dropData }) => {
  return (
    <div className='flex gap-4'>
      <Dropdowns title={`Total Downloads`} name={"download"} Icon={HiDownload} dropData={[]} download={true} />
    </div>
  )
}

export const Report = ({ title }) => {
  const { permissions, tokenType, setDeleteUpdateItem, setConfirmationModalShow, confirmationModalShow, isLoading, deleteUpdateTag, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, setFormData, handleSwitchUpdate, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen, setAddEditDeleteTag } = useContext(NewContext);
  const simpleFields = [
    { name: "Theme", sortable: true, field: 'Theme' },
    { name: "Title", sortable: true, field: 'Title' },
    { name: "User Name", sortable: true, field: 'name' },
    { name: "Likes", sortable: true, field: 'like' },
    { name: "Dislike", sortable: true, field: 'dilike' },
    { name: "Downloads", sortable: true, field: 'downloads' },
    { name: "Report", sortable: true, field: 'report' },
  ];
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  const dropdownFields = [{ name: "Active", }];
  const totalFields = simpleFields?.length + dropdownFields?.length + 2;

  const getCommunityUserReport_data = async (_currentPage = 1) => {
    setIsLoading(true);
    try {
      const getThemeCategories = await get_CommunityUserReport_data(_currentPage, perPage, search, filterDropdown);
      if (getThemeCategories) {
        const { status, data, currentPage, perPage, totalPage } = getThemeCategories;
        if (status) {
          setCurrentPageData(data);
          setCurrentPage(currentPage);
          setPerPage(perPage);
          setTotalPage(totalPage || 1);
        } else {
          // toast.error(message);
          setCurrentPageData([])
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCommunityUserReport_data(currentPage);
  }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Report');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])

  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
      <SearchAndAddBtn title={`Report`} addBtn={false} deleteUpdateTag='all-delete-communityReport' FilterDropdowns={<FilterDropdowns dropData={[{ id: 1, label: "All" }]} />} countryFilter={false} />
      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
          <table className="w-full min-w-max table-auto text-left ">
            <THead simpleFields={simpleFields} dropdownFields={false} />
            <tbody>
              {
                isLoading ?
                  <tr>
                    <td colSpan={totalFields} className='py-12'>
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                  : currentPageData?.length === 0 ?
                    <tr>
                      <td colSpan={totalFields} className='py-12 bg-gray-200'>
                        <div className="flex justify-center items-center">
                          No Data Found
                        </div>
                      </td>
                    </tr>
                    : currentPageData?.map((_ele, i) => {
                      const isLast = i === currentPageData?.length - 1;
                      const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                      return (
                        <tr key={i} className='border-b border-blue-gray-50'>


                          {
                            _ele?.reportOnThemeId?.length > 0 && _ele?.reportOnThemeId?.map((reportOnThemeIdData, i) => {
                              const getUseraName = _ele?.reportBy?.map((item) => item?.name)
                              return (
                                <>
                                  <td className={classes}>
                                    <Checkbox
                                      className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                      id={`slider-${_ele?.reportOnThemeId[0]?._id}`}
                                      value={`${_ele?.reportOnThemeId[0]?._id}`}
                                      disabled={!pagePermissions?.CU}
                                      checked={checkedValues.includes(`${_ele?.reportOnThemeId[0]?._id}`)}
                                      onChange={() => handleCheckboxChange(`${_ele?.reportOnThemeId[0]?._id}`)}
                                    />
                                  </td>
                                  {/* <td className={`${classes}`}>
                                      <img src={reportOnThemeIdData?.preview || `../../../assets/images/no-image.png`} alt={`Theme Image`} className='mr-0 h-[55px]  truncate rounded-lg' />
                                    </td> */}
                                  <td className={`${classes} relative`}>
                                    <img src={reportOnThemeIdData.preview || `../../../assets/images/no-image.png`} alt={reportOnThemeIdData.title} className='mr-0 h-[55px] rounded-lg block absolute' />
                                    <Zoom>
                                      {
                                        _ele?.backgroundType == "Gif" ?
                                          <div className='relative'>
                                            <img src={reportOnThemeIdData?.preview || `../../../assets/images/no-image.png`} alt={reportOnThemeIdData?.title} className='mr-0 h-[55px] rounded-lg relative z-10' />
                                            <img src={reportOnThemeIdData?.gifFile} alt="/" className='mr-0 h-[55px] rounded-lg absolute top-0' />
                                          </div> :
                                          <img src={reportOnThemeIdData?.preview || `../../../assets/images/no-image.png`} alt={reportOnThemeIdData?.title} className='mr-0 h-[55px] rounded-lg relative z-10' />
                                      }
                                    </Zoom>
                                  </td>
                                  <td className={`${classes}`}>
                                    <div className="flex items-center">
                                      <span className={`font-normal text-[17px] !w-28 truncate ${!reportOnThemeIdData?.title && `text-gray-500`}`}>{reportOnThemeIdData?.title || `None`}</span>
                                      {
                                        reportOnThemeIdData?.title && reportOnThemeIdData?.title?.length > 12 &&
                                        <Tooltip placement="right-start" content={
                                          <div className="w-auto">
                                            {reportOnThemeIdData?.title || `None`}
                                          </div>
                                        }>
                                          <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                        </Tooltip>
                                      }
                                    </div>
                                  </td>
                                  <td className={classes}>
                                    <span className={`font-normal text-[17px] ${!getUseraName && `text-gray-500`}`}>{getUseraName || `none`}</span>
                                  </td>
                                  <td className={classes}>
                                    <span className={`font-normal text-[17px] ${!reportOnThemeIdData?.like?.length && `text-gray-500`}`}>{reportOnThemeIdData?.like?.length || `0`}</span>
                                  </td>
                                  <td className={classes}>
                                    <span className={`font-normal text-[17px] ${!reportOnThemeIdData?.dislike_count && `text-gray-500`}`}>{reportOnThemeIdData?.dislike_count || `0`}</span>
                                  </td>
                                  <td className={classes}>
                                    <span className={`font-normal text-[17px] ${!reportOnThemeIdData?.downloadCount && `text-gray-500`}`}>{reportOnThemeIdData?.downloadCount || `0`}</span>
                                  </td>
                                  <td className={classes}>
                                    <span className={`font-normal text-[17px] ${!_ele?.reasonId[0]?.reason && `text-gray-500`}`}>{_ele?.reasonId[0]?.reason || `None`}</span>
                                  </td>
                                  <td className={classes}>
                                    <div className="flex items-center gap-2">
                                      <CommunityBtn violationBtn={pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-Report"); setDeleteUpdateItem({ id: reportOnThemeIdData?._id }); setConfirmationModalShow(!confirmationModalShow) }} />
                                      <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                        <IoEyeOutline />
                                      </IconButton>
                                      <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-Report"); setDeleteUpdateItem({ id: reportOnThemeIdData?._id }); setConfirmationModalShow(!confirmationModalShow) }}>
                                        <RiDeleteBinLine />
                                      </IconButton>
                                    </div>
                                  </td>
                                </>
                              )
                            })
                          }

                        </tr>
                      );
                    })
              }
            </tbody>
          </table>
        </CardBody>
      </Card>
      {
        totalPage > 1 &&
        <Pagination currentPage={currentPage} totalPage={totalPage} getData={getCommunityUserReport_data} />
      }
      <ReportModal />
    </>
  )
}

Report.defaultProps = {
  title: "My Photo Keyboard | Report"
};
export default Report
