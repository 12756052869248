import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";
import { BiEdit } from 'react-icons/bi';
import { IoEyeOutline } from 'react-icons/io5';
import Zoom from 'react-medium-image-zoom';
import { Pagination, SearchAndAddBtn, Switch, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_blog } from '../../Controller/actions';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AddEditorModal from '../../Components/Modals/AddEditorModal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const Blogs = ({ title }) => {
    const navigate = useNavigate();
    const { permissions, tokenType, setFormDataBoolean, setCurrentHomeModalKey, setDeleteUpdateTag, deleteUpdateYes, confirmationModalShow, setConfirmationModalShow, setFormData, setAddEditDeleteTag, setDeleteUpdateItem, isLoading, deleteUpdateTag, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitchUpdate, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Preview", },
        { name: "Title", sortable: true, field: 'title' },
        { name: "categories", sortable: true, field: 'categories' },
        { name: "Total View", sortable: true, field: 'view' },
        { name: "Created At", sortable: true, field: 'createAt' }
    ];
    const dropdownFields = [{ name: "Active", }];
    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const getBlogData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getBlog = await get_blog(_currentPage, perPage, search, filterDropdown);
            if (getBlog) {
                const { status, data, page, perPage, totalPage } = getBlog;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(page);
                    setPerPage(perPage);
                    setTotalPage(totalPage);
                } else {
                    setCurrentPageData([]);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });

    useEffect(() => {
        getBlogData(currentPage);
        setDeleteUpdateTag("");
    }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, deleteUpdateYes, pagePermissions]);

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Blog List');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])


    useEffect(() => {
    }, [pagePermissions])

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <SearchAndAddBtn title={`Blogs`} addBtn={false} addLink={true} countryFilter={false} deleteUpdateTag='all-delete-blogs' destinationUrl='/addBlogs' />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        <THead simpleFields={simpleFields} dropdownFields={dropdownFields} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            value={`${_ele._id}`}
                                                            disabled={!pagePermissions?.CU}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>
                                                    <td className={`${classes}`}>
                                                        <div className='imgTd relative'>
                                                            <img src={_ele?.preview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0  w-[150px]  h-[80px] rounded-lg block absolute' />
                                                            <Zoom>
                                                                <img src={_ele?.preview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 w-[150px] h-[80px] rounded-lg block' />
                                                            </Zoom>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px]  !w-40 truncate ${!_ele?.title && `text-gray-500`}`}>{_ele?.title || `None`}</span>
                                                            {
                                                                _ele?.title && _ele?.title?.length > 12 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-36 2xl:w-[400px] overflow-y-auto ">
                                                                        {_ele?.title}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-10 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px]  !w-40 truncate ${!_ele?.categoriesName && `text-gray-500`}`}>{_ele?.categoriesName || `None`}</span>
                                                            {
                                                                _ele?.categoriesName && _ele?.categoriesName?.length > 20 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-36 2xl:w-[400px] overflow-y-auto ">
                                                                        {_ele?.categoriesName}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-10 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele.view && `text-gray-500`}`}>{_ele.view || `0`}</span>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele?.createdAt && `text-gray-500`}`}>{moment(_ele?.createdAt).format('DD/MM/YYYY, hh:mm A')}</span>
                                                    </td>

                                                    {
                                                        ["isActive"].map((_is_ele, i) => (
                                                            <td className={classes} key={i}>
                                                                <Switch id={_ele._id} disabled={!pagePermissions?.CU} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-blogs"); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                                            </td>
                                                        ))
                                                    }

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton disabled={!pagePermissions?.CU} className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={() => { setDeleteUpdateYes("update-blogs"); setCurrentHomeModalKey('update-c'); setAddEditDeleteTag('update-c'); setFormDataBoolean(true); setFormData(_ele); navigate(`/editBlogs`) }}>
                                                                <BiEdit />
                                                            </IconButton>
                                                            <IconButton disabled={!pagePermissions?.CU} className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                                                <IoEyeOutline />
                                                            </IconButton>
                                                            <IconButton disabled={!pagePermissions?.D} className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' onClick={() => { setDeleteUpdateYes("delete-blogs"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>

                </CardBody>
            </Card>
            <Pagination currentPage={currentPage} totalPage={totalPage} getData={getBlogData} />
            <AddEditorModal />
        </>
    )
}

Blogs.defaultProps = {
    title: "My Photo Keyboard | Blogs"
};

export default Blogs
