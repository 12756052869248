import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Select, Option, Input, Radio, Spinner } from "@material-tailwind/react";
import { add_banners, add_slider, add_slider_tags, get_country_data, get_region_data, get_slider_tag, update_banners, update_slider } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import { BiLink } from 'react-icons/bi';
import { MdContentCopy } from 'react-icons/md';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileInput from '../FileInput/FileInput';

const BannersModal = () => {
    const { isModalLoading, homeActivityRef, setFormData, setDeleteUpdateTag, deleteUpdateYes, handleSwitch, setIsModalLoading, handleClose, handleChange, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, multiCountry, setMultiCountry, setMultiRegion } = useContext(NewContext);

    const [tag, setTag] = useState([]);
    const [multiTag, setMultiTag] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);

    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const tagData = await get_slider_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (tagData.status && countryData.status && regionData.status) {
                setTag(tagData.data);
                // tagData.data.map(_ele => {
                //     if (tag.find(_e => _e._id === _ele._id)) {
                //         if (tag.find(_e => _e.name !== _ele.name)) {
                //             let filterData = tag.map(_t => ({ ..._t, name: 'banner_view_type' }))
                //             setTag(filterData);
                //         }
                //     } else {
                //         _ele.name && setTag(_prev => [..._prev, { _id: _ele._id, value: _ele.name && _ele.name.toLowerCase(), label: _ele.name, name: 'banner_view_type' }])
                //     }
                // })
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    // let oldTagData = updateStateAryData([formData['banner_view_type']], tagData.data, 'banner_view_type');
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    // setMultiTag(oldTagData);
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                    // if (oldTagData.length == 1) {
                    //     let selectedSliderTag = tag.filter(_ele => oldTagData[0]._id === _ele._id);
                    //     let nonSelectedSliderTag = tag.filter(_ele => oldTagData[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                    //     setTag(selectedSliderTag.concat(nonSelectedSliderTag));
                    // }
                }
            } else {
                toast.error(tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };

    const handleSelectMultiTags = (e) => {
        if (e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item.value);
                // if (_ele.name === 'slider_tag') {
                setMultiTag([{ ...e[0], disabled: false }]);
                setFormData({ ...formData, [_ele.name]: multiSelectAry[0] });
                if (e.length == 1) {
                    let selectedSliderTag = tag.filter(_ele => e[0]._id === _ele._id);
                    let nonSelectedSliderTag = tag.filter(_ele => e[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                    setTag(selectedSliderTag.concat(nonSelectedSliderTag));
                }
                // }
            })
        } else {
            let tags = tag.map(_ele => ({ ..._ele, disabled: false }));
            setTag(tags);
            setMultiTag([]);
            setFormData({ ...formData, slider_tag: [] })
        }
        homeActivityRef.current.focus();
    }

    const handleNewField = async (_value) => {
        let tagName = 'banner_view_type';
        let { status, data } = await add_slider_tags({ name: _value });
        if (status) {
            tag.unshift({ _id: data._id, value: data.name, label: data.name, name: tagName, disabled: false });
            homeActivityRef.current.focus();
            return {
                _id: data._id,
                name: tagName,
                disabled: false,
                label: data.name,
                value: data.name,
            }
        }
    };

    useEffect(() => {
        if (open) {
            getDataFunc();
        }
    }, [addEditDeleteTag, open]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setDeleteUpdateTag(deleteUpdateYes);
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            // let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_banners({ ...formData }, formData?._id)
        } else {
            createUpdateSlider = await add_banners(formData)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                // setValidated(false);
                setDeleteUpdateTag(deleteUpdateYes);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setTag([]); setMultiTag([]); }}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "550px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Banners` : `Add New Banners`}
                </DialogTitle>
                <DialogContent>
                    <div className="">
                        <Typography className="text-lg text-[#1E1627] font-medium">Preview:</Typography>
                        <div className='w-auto'>
                            <FileInput name={`homescreenBanneres`} imgClass={`h-full`} previewFields={'banner_image_url'} accept={'image/*'} validation={false} width={1080} height={342} acceptedExtensions={/\.(jpg|png|webp|PNG)$/} />
                        </div>
                    </div>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="col-span-6 sm:col-span-6">
                                            <label htmlFor="banner_view_type" className="block text-sm font-medium leading-6 text-gray-900">Redirect To:</label>
                                            <div className="mt-1.5 relative">
                                                <Input id='banner_view_type' placeholder='Enter View Type' name='banner_view_type' onChange={handleChange} value={formData?.banner_view_type || ''} autoComplete='off' required />
                                                {/* <MultiSelect
                                                    labelledBy='Select Slider Tag'
                                                    options={tag}
                                                    value={multiTag}
                                                    onChange={handleSelectMultiTags}
                                                    hasSelectAll={false}
                                                    isCreatable={true}
                                                    onCreateOption={handleNewField}
                                                />
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' /> */}
                                            </div>
                                        </div>
                                        <div className="col-span-3 sm:col-span-1">
                                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={() => { handleClose(); setTag([]); setMultiTag([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            disabled={isModalLoading}
                                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Name:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Priority:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.priority}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                            <div className="mt-2 ml-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "On" : "Off"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={() => { handleClose(); setTag([]); setMultiTag([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </DialogContent>
            </Dialog >
        </>
    )
}

export default BannersModal
