import React, { useContext, useState } from 'react'
import { Button, Checkbox, Input, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react'
import { FaChevronDown } from 'react-icons/fa'
import { NewContext } from '../../Context/NewContext'
import { toast } from 'react-toastify'

export const Dropdowns = ({ Icon, title, name, dropData, download = false, dateTime = false, dateRange = false }) => {
    const { homeActivityRef, selectedOptions, setSelectedOptions, range, setRange, filterDate, setFilterDate, setCurrentPage, filterDropdown, setFilterDropdown, filterStartDate, setFilterStartDate, filterEndDate, setFilterEndDate } = useContext(NewContext);

    const handleRange = (e) => setRange(_prev => ({ ..._prev, [e.target.name]: e.target.value }));
    const handleFilterDate = (e) => setFilterDate(e.target.value);
    const handleStartFilterDate = (e) => setFilterStartDate(e.target.value);
    const handleEndFilterDate = (e) => setFilterEndDate(e.target.value);

    const handleSubmitFilter = () => {
        if (range.min || range.max) {
            if (!range.min || !range.max || range.min < range.max || range.min !== range.max) {
                setFilterDropdown(_prev => ({ ..._prev, ...range }))
            } else {
                toast.error(`Invalid Range!`)
            }
        }
        if (filterDate) {
            setFilterDropdown(_prev => ({ ..._prev, filterDate }))
        }
        if (filterStartDate && filterEndDate) {
            setFilterDropdown(_prev => ({ ..._prev, filterStartDate, filterEndDate }))
        }
    }
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchTermChange = (e) => setSearchTerm(e.target.value);
    const handleOptionClick = (e, _optionId) => {
        setCurrentPage(1);
        const isSelected = filterDropdown[name]?.includes(_optionId);
        if (isSelected) {
            let selectedOptionsFilter = filterDropdown[name].filter((o) => o !== _optionId);
            setSelectedOptions(selectedOptionsFilter);
            if (selectedOptionsFilter.length > 0) {
                setFilterDropdown({ ...filterDropdown, [e.target.name]: selectedOptionsFilter })
            } else {
                setFilterDropdown({ ...filterDropdown, [e.target.name]: [] })
                // delete filterDropdown[e.target.name];
            }
        } else {
            let oldData = (!download && !dateTime && !dateRange) && (filterDropdown[name] || []);
            let newAry = [...oldData, _optionId];
            setSelectedOptions(newAry);
            setFilterDropdown({ ...filterDropdown, [e.target.name]: newAry })
        }
    }
    const filteredOptions1 = dropData && dropData.filter((_option) => _option.label?.toLowerCase().includes(searchTerm?.toLowerCase()));
    const filteredOptions = filteredOptions1 && filteredOptions1.map(_ele => ({ label: _ele.label }));
    const data = name === 'country' ? filteredOptions1 : filteredOptions;

    function close() {
        let el = document.getElementById("filterDate");
        if (el) { el.blur() }
    }

    return (
        <Menu placement='bottom-start' dismiss={{ itemPress: !dateRange && !dateTime && true, isRequired: {} }}>
            <MenuHandler>
                <Button variant='filled' color='white' className='text-darkblue hover:shadow-none shadow-none border border-[#B4BCC9] bg-gray-100 flex items-center px-2.5 lg:px-2'>
                    <div className='flex mr-2 md:mr-[25px] lg:mr-1 2xl:mr-[25px] items-center'>
                        <Icon className='text-[14px] md:text-[18px] 2xl:text-[22px]' />
                        <span className='ml-2.5 text-[14px] md:text-[16px] lg:text-[14px] 2xl:text-[16px] capitalize font-normal'>
                            {title}
                            {(!download && !dateTime && !dateRange) && filterDropdown[name]?.length > 0 && ` : ${filterDropdown[name].length}`}
                        </span>
                    </div>
                    <FaChevronDown className='text-[14px] md:text-[16px] lg:text-[12px] 2xl:text-[16px]' />
                </Button>
            </MenuHandler>
            <MenuList className='border-[#B4BCC9] min-w-[140px] lg:min-w-[180px] max-h-80 overflow-y-auto'>
                {
                    download &&
                    <>
                        <Typography className="text-[18px] font-normal text-darkblue leading-none focus-visible:outline-0">{`Type Number`}</Typography>
                        <div className='flex items-center my-2.5'>
                            <Input label="Min" name='min' type='number' containerProps={{ className: "!w-[80px] !min-w-[70px]" }} value={range.min} onChange={handleRange} />
                            <Typography className='font-normal text-[#151515] p-2.5'>To</Typography>
                            <Input label="Max" name='max' type='number' containerProps={{ className: "!w-[80px] !min-w-[70px]" }} value={range.max} onChange={handleRange} />
                        </div>
                        <Button className="px-5 py-3 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-sm w-full leading-none" type='button' onClick={handleSubmitFilter}>Go</Button>
                    </>
                }{
                    dateTime &&
                    <span className='add-edit-form'>
                        {/* onInput={close} */}
                        <Input id="filterDate" name='filterDate' type='date' value={filterDate} onChange={handleFilterDate} />
                        <Button className="mt-2.5 px-5 py-3 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-sm w-full leading-none" type='button' onClick={handleSubmitFilter}>Go</Button>
                    </span>
                }
                {
                    dateRange &&
                    <span className='add-edit-form w-10'>
                        <div className="flex gap-2">
                            <Input id="filterStartDate" name='filterStartDate' type='date' value={filterStartDate} onChange={handleStartFilterDate} />
                            <Input id="filterEndDate" name='filterEndDate' type='date' value={filterEndDate} onChange={handleEndFilterDate} />
                        </div>
                        <Button className="mt-2.5 px-5 py-3 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-sm w-full leading-none" type='button' onClick={handleSubmitFilter}>Go</Button>
                    </span>
                }
                {
                    (!dateRange && !download && !dateTime) &&
                    <>
                        <Input label="Search" containerProps={{ className: "mb-4", }} value={searchTerm} onChange={handleSearchTermChange} />
                        {data && data.map((_ele, i) => (
                            <MenuItem className="p-0" key={i}>
                                <label
                                    htmlFor={_ele.id}
                                    className="flex cursor-pointer items-center gap-2 p-2"
                                >
                                    <Checkbox
                                        name={name}
                                        ripple={false}
                                        id={_ele.id}
                                        containerProps={{ className: "p-0" }}
                                        className="hover:before:content-none checked:bg-[#0047FF] checked:border-[#0047FF]"
                                        // checked={selectedOptions.includes(_ele.id || _ele.label?.toLowerCase())}
                                        checked={filterDropdown[name]?.includes(_ele.id || _ele.label?.toLowerCase())}
                                        onChange={(e) => handleOptionClick(e, _ele.id || _ele.label?.toLowerCase())}
                                    />
                                    {_ele.label}
                                </label>
                            </MenuItem>
                        ))}
                        {data.length === 0 && (
                            <div className="px-4 py-2 text-sm text-gray-500">No options found.</div>
                        )}
                    </>
                }
            </MenuList>
        </Menu >
    )
}

export default Dropdowns
