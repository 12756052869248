import React, { createContext, useRef, useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { add_setting } from "../Controller/actions";
export const NewContext = createContext();

const NewContextProvider = ({ children }) => {
  const homeActivityRef = useRef(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [tokenType, setTokenType] = useState(
    localStorage.getItem("loginType") || "standard"
  );
  useEffect(() => {
    setTokenType(localStorage.getItem("loginType"));
  }, [localStorage.getItem("loginType")]);

  const [permissions, setPermissions] = useState(
    tokenType === "ldap" && token?.split(".")[1]
      ? JSON.parse(atob(token?.split(".")[1]))?.permission
      : {}
  );

  const [deleteUpdateYes, setDeleteUpdateYes] = useState("");
  const [deleteUpdateTag, setDeleteUpdateTag] = useState("");
  const [search, setSearch] = useState("");
  const [addEditDeleteTag, setAddEditDeleteTag] = useState("add");
  const [currentHomeModalKey, setCurrentHomeModalKey] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentThemePage, setCurrentThemePage] = useState(1);
  const [isSingleThemeRejected, setSingleThemeRejected] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [childModalCurrentPage, setChildModalCurrentPage] = useState(1);
  const [childModalTotalPage, setchildModalTotalPage] = useState(1);
  const [childModalperPage, setChildModalPerPage] = useState(8);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isSubModalLoading, setIsSubModalLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [currentModalTableData, setCurrentModalTableData] = useState([]);
  const [childModalTableData, setChildModalTableData] = useState([]);
  const [homeScreenData, setHomeScreenData] = useState([]);
  const [homeTableData, setHomeTableData] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [range, setRange] = useState({});
  const [filterDate, setFilterDate] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [formDataBoolean, setFormDataBoolean] = useState(true);

  const [multiCountry, setMultiCountry] = useState([]);
  const [multiRegion, setMultiRegion] = useState([]);
  const [formData, setFormData] = useState({});
  const [imgObj, setImgObj] = useState({});
  const [deleteUpdateItem, setDeleteUpdateItem] = useState({});
  const [filterDropdown, setFilterDropdown] = useState({});
  const [isLoadMore, setIsLoadMore] = useState("");
  const [openmod, setOpenMod] = useState(false);
  const [childFormData, setChildFormData] = useState({});
  const [childOpen, setChildOpen] = useState(false);
  const [childReportOpen, setReportChildOpen] = useState(false);
  const [openReason, setopenReason] = useState(false);
  const [checkType, setCheckType] = useState([]); // This is use for storage type.its use dynamic head fields
  const [budge, setBudge] = useState([]); // This is use for storage info
  // Survey Section 
  const [showSurveyModel, setShowSurveyModel] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  //   FAQ section
  const [saveMedia, setSaveMedia] = useState(0);

  const [questions, setQuestions] = useState([
    {
      id: uuidv4(),
      question: "",
      questionType: "",
      answerOptions: [{ id: uuidv4(), text: "" }],
    },
  ]);

  // asset
  const [selectedOption, setSelectedOption] = useState(null);

  const handlePermissions = (permissionData) => setPermissions(permissionData);
  const [disapproveReason, setDisapproveReason] = useState();
  const handleChildOpen = () => setChildOpen(!childOpen);
  const handleChildClose = () => {
    setChildOpen(false);
    setChildFormData({});
    setDeleteUpdateYes("");
    setDeleteUpdateTag("");
    setDeleteUpdateItem({});
    setCheckedValues([]);
    setMultiCountry([]);
    setMultiRegion([]);
  };
  const handleReportChildOpen = () => setReportChildOpen(true);
  const handleReportChildClose = () => {
    setReportChildOpen(false);
  };
  const handleOpenReason = () => setopenReason(!openReason);
  const handleopenReasonClose = () => {
    setopenReason(false);
    setDisapproveReason("");
  };
  const handleChildChange = (e) => {
    if (e.target.name === "country" || e.target.name === "region") {
      let countryRegionAry = [].slice
        .call(e.target.selectedOptions)
        .map((item) => item.value);
      setChildFormData({ ...childFormData, [e.target.name]: countryRegionAry });
      setDeleteUpdateItem({ ...formData, [e.target.name]: countryRegionAry });
    } else {
      setChildFormData({ ...childFormData, [e.target.name]: e.target.value });
      setDeleteUpdateItem({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleChildSwitch = (e) =>
    setChildFormData({
      ...childFormData,
      [e.target.name]: childFormData[e.target.name] ? false : true,
    });
  const handleOpenbtn = () => {
    setOpenMod(true);
  };
  const handleClosebtn = () => {
    setOpenMod(false);
  };
  const handleCopyData = (_tag, _string) => {
    copy(_string);
    toast.success(`${_tag} Copied.`);
  };
  const handleFilterChange = (e) => {
    setFilterDropdown({
      ...filterDropdown,
      [e.target.name]:
        e.target.value === "true"
          ? true
          : e.target.value === "false"
            ? false
            : "",
    });
    setCheckedValues([]);
  };
  const handleCheckboxChange = (value) => {
    checkedValues.includes(value)
      ? setCheckedValues(checkedValues.filter((val) => val !== value))
      : setCheckedValues([...checkedValues, value]);
  };
  const handleSelectAll = (e) => {
    const path = window.location.href.split("/").pop();
    const data = path === "homescreen" ? homeScreenData : currentPageData;
    e.target.checked
      ? setCheckedValues([...data.map((_ele) => _ele._id)])
      : setCheckedValues([]);
  };
  const handleDeleteAll = (_deleteUpdateYes) => {
    setDeleteUpdateYes(_deleteUpdateYes);
    setDeleteUpdateItem({ id: checkedValues });
    setConfirmationModalShow(!confirmationModalShow);
  };
  const handleIsLoadMoreSwitch = () => {
    setIsLoadMore(!isLoadMore);
    add_setting({ isLoadMore: !isLoadMore });
  };
  const handleChange = (e) => {
    if (e.target.name === "country" || e.target.name === "region") {
      let countryRegionAry = [].slice
        .call(e.target.selectedOptions)
        .map((item) => item.value);
      setFormData({ ...formData, [e.target.name]: countryRegionAry });
      setDeleteUpdateItem({ ...formData, [e.target.name]: countryRegionAry });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setDeleteUpdateItem({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    toast.success("Logout successfully.");
    window.location.reload();
    window.location.href = "/";
  };
  const handleSwitch = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: formData[e.target.name] ? false : true,
    });
  const handleSwitchUpdate = (e) => {
    setDeleteUpdateItem({
      id: e.target.id,
      [e.target.name]: !e.target.checked ? false : true,
    });
    setConfirmationModalShow(!confirmationModalShow);
  };
  const handleOpen = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
    setDeleteUpdateYes("");
    setDeleteUpdateTag("");
    setAddEditDeleteTag(`add`);
    setCurrentHomeModalKey(``);
    setDeleteUpdateItem({});
    setFormData({});
    setChildFormData({});
    setImgObj({});
    setCheckedValues([]);
    setMultiCountry([]);
    setMultiRegion([]);
    setSaveMedia(0);
    setCurrentSlide(0);
    setShowSurveyModel(false);
    setSelectedOption(null)
  };
  const capitalalizeFirstletter = (_str) =>
    _str.charAt(0).toUpperCase() + _str.slice(1);
  const updateStateAryData = (stateAry, fieldAllData, name) => {
    let blankAry = [];
    stateAry &&
      stateAry.length &&
      stateAry?.map((_state) => {
        let stateDefaultValue;
        if (
          name === "country" ||
          (window.location.href.split("/").pop() == "textart" &&
            name === "category")
        ) {
          stateDefaultValue = fieldAllData?.filter(
            (_field_all) => _field_all._id === (_state?._id || _state)
          )[0];
        } else {
          stateDefaultValue = fieldAllData?.filter(
            (_field_all) =>
              (_field_all?.name && _field_all?.name?.toLowerCase()) ===
              (_state && _state?.toLowerCase())
          )[0];
        }
        blankAry.push(stateDefaultValue);
      });
    if (
      window.location.href.split("/").pop() == "textart" &&
      name === "category"
    ) {
      return blankAry?.map((_ele) => ({
        _id: _ele._id,
        value: _ele._id,
        label: capitalalizeFirstletter(_ele.name),
        name: name,
      }));
    } else {
      return blankAry?.map((_ele) => ({
        _id: _ele._id,
        value:
          name === "country" ? _ele.code : _ele.name && _ele.name?.toLowerCase(),
        label: capitalalizeFirstletter(_ele.name),
        name: name,
      }));
    }
  };
  const handleModalClose = (e, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
  };
  const updateStateAryChildData = (stateAry, fieldAllData, name) => {
    let blankAry = [];
    stateAry &&
      stateAry.length &&
      stateAry?.map((_state) => {
        let stateDefaultValue;
        if (name === "country") {
          stateDefaultValue = fieldAllData?.filter(
            (_field_all) => _field_all._id === (_state?._id || _state)
          )[0];
        } else {
          stateDefaultValue = fieldAllData?.filter(
            (_field_all) =>
              (_field_all.name && _field_all.name?.toLowerCase()) ===
              (_state && _state?.toLowerCase())
          )[0];
        }
        blankAry.push(stateDefaultValue);
      });
    return blankAry?.map((_ele) => ({
      _id: _ele && _ele._id,
      value: name === "country" ? _ele.code : _ele.name?.toLowerCase(),
      label: capitalalizeFirstletter(_ele.name),
      name: name,
    }));
  };
  const handleSelectMultiCountry = (e) => {
    if (e.length > 0) {
      e.map((_ele) => {
        let multiSelectAry = [].slice.call(e).map((item) => item._id);
        if (_ele.name === "country") {
          setMultiCountry(e);
          if (childOpen) {
            setChildFormData({ ...childFormData, [_ele.name]: multiSelectAry });
          } else {
            setFormData({ ...formData, [_ele.name]: multiSelectAry });
          }
        }
      });
    } else {
      setMultiCountry([]);
      if (childOpen) {
        setChildFormData({ ...childFormData, country: [] });
      } else {
        setFormData({ ...formData, country: [] });
      }
    }
    homeActivityRef.current && homeActivityRef.current.focus();
  };
  const handleSelectMultiRegion = (e) => {
    if (e.length > 0) {
      e.map((_ele) => {
        let multiSelectAry = [].slice.call(e).map((item) => item._id);
        if (_ele.name === "region") {
          setMultiRegion(e);
          if (childOpen) {
            setChildFormData({ ...childFormData, [_ele.name]: multiSelectAry });
          } else {
            setFormData({ ...formData, [_ele.name]: multiSelectAry });
          }
        }
      });
    } else {
      setMultiRegion([]);
      if (childOpen) {
        setChildFormData({ ...childFormData, country: [] });
      } else {
        setFormData({ ...formData, region: [] });
      }
    }
    homeActivityRef.current.focus();
  };
  const handleFileInput = (e, width, height, index) => {
    if (
      e.target.name === "bigPreviewStickerStore"
      // ||
      // e.target.name === "faqImages"
    ) {
      let bigPreviewAry = [];
      let blobURLS = [];
      for (let fileObject of e.target.files) {
        if (!fileObject.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
          return toast.error("Please select valid image.");
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(fileObject);
          reader.onload = function (_e) {
            const image = new Image();
            const imageContent = reader.result;
            const decodedString = atob(imageContent.split(",")[1]);
            image.src = imageContent.split(",")[0] + "," + btoa(decodedString);
            image.onerror = function () {
              setFormData({ ...formData, [e.target.name]: undefined });
              setImgObj({ ...imgObj, [e.target.name]: undefined });
              return toast.error("Please select valid image.");
            };
            image.onload = function () {
              const imgHeight = this.height,
                imgWidth = this.width;
              if (width || height) {
                if (imgWidth > width || imgHeight > height) {
                  return toast.error(
                    `Width and Height size must be ${width}x${height}.`
                  );
                }
              } else {
                bigPreviewAry.push(fileObject);
                blobURLS.push(URL.createObjectURL(fileObject));

                if (e.target.files.length === blobURLS.length) {
                  setFormData({ ...formData, [e.target.name]: bigPreviewAry });
                  setImgObj({ ...imgObj, [e.target.name]: blobURLS });
                } else {
                  setFormData({ ...formData, [e.target.name]: undefined });
                  setImgObj({ ...imgObj, [e.target.name]: undefined });
                }
              }
            };
          };
        }
      }
    } else if (
      e.target.name.includes("video") ||
      e.target.name.includes("videoPreview") ||
      e.target.name.includes("faqImages")

    ) {
      if (formData?.[e.target.name] === undefined) {
        formData[e.target.name] = [];
        formData[e.target.name][index] = e.target.files[0]
        setFormData({ ...formData });
        if (e.target.name === "video" || e.target.name === "faqImages") {
          formData.media[index].url = e?.target?.files?.[0]?.name
        } else {
          formData.media[index].preview = e?.target?.files?.[0]?.name
        }
      } else {
        if ((formData.media[index].url !== undefined && (['video', 'faqImages'].includes(e.target.name)))) {
          formData[e.target.name][index] = e.target.files[0]
        } else if (formData.media[index].preview !== undefined && (['preview'].includes(e.target.name))) {
          formData[e.target.name][index] = e.target.files[0]
        } else {
          formData[e.target.name].push(e.target.files[0]);
        }
        if (e.target.name === "video" || e.target.name === "faqImages") {
          formData.media[index].url = e?.target?.files?.[0]?.name
        } else {
          formData.media[index].preview = e?.target?.files?.[0]?.name
        }
        setFormData({ ...formData });
      }
    } else {
      const inputFile = e.target.files[0];
      if (["themeZip", "keyZip", "stickerZip"].includes(e.target.name)) {
        if (!inputFile.name.match(/\.(zip)$/)) {
          return toast.error("Please select valid zip.");
        }
      } else if (["fontFile"].includes(e.target.name)) {
        if (!inputFile.name.match(/\.(ttf|otf|TTF|OTF)$/)) {
          return toast.error("Please select valid font file.");
        }
      } else if (["mp3file"].includes(e.target.name)) {
        if (!inputFile.name.match(/\.(mp3)$/)) {
          return toast.error("Please select valid sound file.");
        }
      } else if (["categoryBinFile"].includes(e.target.name)) {
        if (!inputFile.name.match(/\.(bin)$/)) {
          return toast.error("Please select valid bin file.");
        }
      }

      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      setImgObj({
        ...imgObj,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };
  const handleChildFileInput = (e, width, height) => {
    if (e.target.name == "bigPreviewStickerStore") {
      let bigPreviewAry = [];
      let blobURLS = [];
      for (let fileObject of e.target.files) {
        if (!fileObject.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
          return toast.error("Please select valid image.");
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(fileObject);
          reader.onload = function (_e) {
            const image = new Image();
            const imageContent = reader.result;
            const decodedString = atob(imageContent.split(",")[1]);
            image.src = imageContent.split(",")[0] + "," + btoa(decodedString);
            image.onerror = function () {
              setFormData({ ...formData, [e.target.name]: undefined });
              setImgObj({ ...imgObj, [e.target.name]: undefined });
              return toast.error("Please select valid image.");
            };
            image.onload = function () {
              const imgHeight = this.height,
                imgWidth = this.width;
              if (width || height) {
                if (imgWidth > width || imgHeight > height) {
                  return toast.error(
                    `Width and Height size must be ${width}x${height}.`
                  );
                }
              } else {
                bigPreviewAry.push(fileObject);
                blobURLS.push(URL.createObjectURL(fileObject));

                if (e.target.files.length === blobURLS.length) {
                  setFormData({ ...formData, [e.target.name]: bigPreviewAry });
                  setImgObj({ ...imgObj, [e.target.name]: blobURLS });
                } else {
                  setFormData({ ...formData, [e.target.name]: undefined });
                  setImgObj({ ...imgObj, [e.target.name]: undefined });
                }
              }
            };
          };
        }
      }
    } else {
      setImgObj({
        ...imgObj,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
      setChildFormData({
        ...childFormData,
        [e.target.name]: e.target.files[0],
      });
    }
  };
  const handleSingleSelect = (name, e) =>
    setFormData({ ...formData, [name]: e });
  const handleSingleSelectChild = (name, e) =>
    setChildFormData({ ...childFormData, [name]: e });
  const truncateString = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const truncationLength = maxLength; // Math.floor((maxLength - 3) / 2);
    const start = text.slice(0, truncationLength);
    const end = text.slice(text.length - truncationLength);
    return `${start}...${end}`;
  };

  const value = {
    homeActivityRef,
    token,
    setToken,
    expiryTime,
    setExpiryTime,
    tokenType,
    setTokenType,
    deleteUpdateYes,
    setDeleteUpdateYes,
    deleteUpdateTag,
    setDeleteUpdateTag,
    addEditDeleteTag,
    setAddEditDeleteTag,
    currentHomeModalKey,
    setCurrentHomeModalKey,
    search,
    setSearch,
    currentUserId,
    setCurrentUserId,
    currentPage,
    setCurrentPage,
    currentThemePage,
    setCurrentThemePage,
    totalPage,
    setTotalPage,
    perPage,
    setPerPage,
    permissions,
    setPermissions,
    handlePermissions,
    childModalCurrentPage,
    setChildModalCurrentPage,
    childModalTotalPage,
    setchildModalTotalPage,
    childModalperPage,
    setChildModalPerPage,
    toggleSidebar,
    setToggleSidebar,
    confirmationModalShow,
    setConfirmationModalShow,
    isLoading,
    setIsLoading,
    isModalLoading,
    setIsModalLoading,
    isSubModalLoading,
    setIsSubModalLoading,
    open,
    setOpen,
    openModal,
    setOpenModal,
    handleModalClose,
    childReportOpen,
    setReportChildOpen,
    openReason,
    setopenReason,
    currentPageData,
    setCurrentPageData,
    currentModalTableData,
    setCurrentModalTableData,
    childModalTableData,
    setChildModalTableData,
    homeScreenData,
    setHomeScreenData,
    homeTableData,
    setHomeTableData,
    checkedValues,
    setCheckedValues,
    selectedOptions,
    setSelectedOptions,
    formDataBoolean,
    setFormDataBoolean,
    checkType,
    setCheckType,
    budge,
    setBudge,
    range,
    setRange,
    filterDate,
    setFilterDate,
    multiCountry,
    setMultiCountry,
    multiRegion,
    setMultiRegion,
    formData,
    setFormData,
    imgObj,
    setImgObj,
    deleteUpdateItem,
    setDeleteUpdateItem,
    filterDropdown,
    setFilterDropdown,
    isLoadMore,
    setIsLoadMore,
    openmod,
    setOpenMod,
    childFormData,
    setChildFormData,
    childOpen,
    setChildOpen,
    handleChildOpen,
    handleReportChildOpen,
    handleReportChildClose,
    handleopenReasonClose,
    handleChildClose,
    handleChildChange,
    handleChildSwitch,
    handleCopyData,
    handleFilterChange,
    handleCheckboxChange,
    handleSelectAll,
    handleDeleteAll,
    handleIsLoadMoreSwitch,
    handleChange,
    handleLogout,
    handleSwitch,
    handleSwitchUpdate,
    handleOpen,
    handleOpenReason,
    handleClose,
    handleOpenbtn,
    handleClosebtn,
    capitalalizeFirstletter,
    updateStateAryData,
    updateStateAryChildData,
    handleSelectMultiCountry,
    handleSelectMultiRegion,
    handleFileInput,
    handleChildFileInput,
    handleSingleSelect,
    handleSingleSelectChild,
    truncateString,
    isSingleThemeRejected,
    setSingleThemeRejected,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    disapproveReason,
    setDisapproveReason,
    setSaveMedia,
    saveMedia,
    setQuestions,
    questions,
    setShowSurveyModel,
    showSurveyModel,
    setMenuOpen,
    menuOpen,
    setCurrentSlide,
    currentSlide,
    // asset
    setSelectedOption,
    selectedOption
  };
  return <NewContext.Provider value={value}>{children}</NewContext.Provider>;
};

export default NewContextProvider;
