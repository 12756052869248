import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Input, Spinner } from "@material-tailwind/react";
import { add_theme, get_country_data, get_region_data, get_tag, get_theme_categories_data, update_theme } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileInput from '../FileInput/FileInput';
import ThemeChildModal from './ThemeChildModal';

export const ThemeModal = () => {
    const { truncateString, homeActivityRef, isModalLoading, setIsModalLoading, currentHomeModalKey, childOpen, setCurrentHomeModalKey, handleChildOpen, setDeleteUpdateTag, deleteUpdateYes, setFormData, handleSelectMultiCountry, handleSelectMultiRegion, handleSwitch, handleFileInput, handleClose, handleChange, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, multiCountry, setMultiCountry, multiRegion, setMultiRegion, deleteUpdateItem } = useContext(NewContext);

    const [modalTag, setModalTag] = useState("");
    const [tag, setTag] = useState([]);
    const [category, setCategory] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [multiThemeCategories, setMultiThemeCategories] = useState([]);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const [isOpenTagDropDown, setIsOpenTagDropDown] = useState(false);
    const [isOpenCategoryDropDown, setIsOpenCategoryDropDown] = useState(false);
    const [multiThemeTags, setMultiThemeTags] = useState([]);
    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const categoryData = await get_theme_categories_data(1, "", "", { isActive: true });
            const tagData = await get_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();

            if (categoryData.status && tagData.status && countryData.status && regionData.status) {
                setCategory(categoryData?.data?.map((_ele) => ({ _id: _ele?._id, value: _ele?.name?.toLowerCase(), label: capitalalizeFirstletter(_ele?.name), name: 'themeCategory' })))
                setTag(tagData?.data?.map((_ele) => ({ _id: _ele?._id, value: _ele?.name?.toLowerCase(), label: capitalalizeFirstletter(_ele?.name), name: 'themeTag' })))
                setCountry(countryData?.data?.map((_ele) => ({ _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' })))
                if (addEditDeleteTag === `update`) {
                    let oldCategoryData = updateStateAryData(formData?.themeCategory, categoryData.data, 'themeCategory');
                    let oldTagData = updateStateAryData(formData?.themeTag, tagData.data, 'themeTag');
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiThemeCategories(oldCategoryData);
                    setMultiThemeTags(oldTagData);
                    setMultiCountry(oldCountryData);
                }
            } else {
                toast.error(tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };
    useEffect(() => {
        getDataFunc();
        if (currentHomeModalKey !== `` && currentHomeModalKey !== `addTag` && currentHomeModalKey !== `addCategory` && addEditDeleteTag === `add`) {
            setMultiThemeCategories([]);
            setMultiThemeTags([]);
        }
    }, [addEditDeleteTag, currentHomeModalKey]);
    const handleSelectMultiThemeCategories = (e) => {

        if (e.length > 0) {
            e?.map(_ele => {
                let themeMultiSelectAry = [].slice.call(e).map(item => item && item.value);
                if (_ele && _ele.name === 'themeCategory') {
                    e = e.filter(function (element) {
                        return element !== undefined;
                    });
                    setMultiThemeCategories(e);
                    setFormData({ ...formData, [_ele.name]: themeMultiSelectAry });
                }
            })
        } else {
            setMultiThemeCategories([]);
            setFormData({ ...formData, themeCategory: [] });
        }
        // homeActivityRef.current && homeActivityRef.current.focus();
    }
    const handleSelectMultiThemeTags = (e) => {
        if (e.length > 0) {
            e?.map(_ele => {
                let themeMultiSelectAry = [].slice.call(e).map(item => item && item.value);
                if (_ele && _ele.name === 'themeTag') {
                    e = e.filter(function (element) {
                        return element !== undefined;
                    });
                    setMultiThemeTags(e);
                    setFormData({ ...formData, [_ele.name]: themeMultiSelectAry });
                }
            });
        } else {
            setMultiThemeTags([]);
            setFormData({ ...formData, themeTag: [] });
        }
        // homeActivityRef.current && homeActivityRef.current.focus();
    }
    const handleNewCategoryField = async (_value) => { setCurrentHomeModalKey('addCategory'); handleChildOpen(); };
    const handleNewTagField = async (_value) => { setCurrentHomeModalKey('addTag'); handleChildOpen(); };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateThemes;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateThemes = await update_theme({ ...formData, themeCategory: formData?.themeCategory || [], themeTag: formData?.themeTag || [], country: countryIdAry || [], region: formData?.region || [] }, formData?._id)
        } else {
            createUpdateThemes = await add_theme(formData)
        }
        if (createUpdateThemes) {
            const { message, status } = createUpdateThemes;
            if (status) {
                toast.success(message);
                handleClose();
                // setValidated(false);
                setDeleteUpdateTag(deleteUpdateYes);
                setMultiThemeCategories([]);
                setMultiThemeTags([]);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
        setIsModalLoading(false);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setCategory([]); setMultiThemeCategories([]); setTag([]); setMultiThemeTags([]); }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Themes` : `Add New Themes`}
                </DialogTitle>
                <DialogContent>
                    {/* <div className="">
                        {
                            imgObj?.bigPreview || formData?.bigPreview ?
                                <img src={addEditDeleteTag === 'update' ? formData?.bigPreview : imgObj?.bigPreview} alt={formData?.name} className='w-36 h-[98px]' />
                                : <img src="../../assets/images/no-image.png" alt="No Image" className='w-36 h-[98px]' />
                        }
                    </div> */}
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                        <div className='lg:col-span-1'>
                            <Typography className="text-lg text-[#1E1627] font-medium">Small Preview (720 x 491):</Typography>
                            <div className='w-auto'>
                                <FileInput name={`smallPreviewTheme`} imgClass={`h-full`} previewFields={'smallPreview'} accept={'image/*'} width={720} height={491} acceptedExtensions={/\.(jpg|png|webp)$/} />
                            </div>
                        </div>
                        <div className='lg:col-span-1'>
                            <Typography className="text-lg text-[#1E1627] font-medium">Big Preview (1080 x 737):</Typography>
                            <div className='w-auto'>
                                <FileInput name={`bigPreviewTheme`} imgClass={`h-full`} previewFields={'bigPreview'} accept={'image/*'} width={1080} height={737} acceptedExtensions={/\.(jpg|png|webp)$/} />
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                            <div className="mt-1.5">
                                                <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name} autoComplete='off' />
                                            </div>
                                        </div>
                                        {/* <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="userHit" className="block text-sm font-medium leading-6 text-gray-900">User Hit:</label>
                                            <div className="mt-1.5">
                                                <Input type='number' id='userHit' placeholder='Enter User Hit' name='userHit' onChange={handleChange} autoComplete='off' />
                                            </div>
                                        </div> */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="pkg_name" className="block text-sm font-medium leading-6 text-gray-900">Package Name:</label>
                                            <div className="mt-1.5">
                                                <Input id='pkg_name' placeholder='Enter Package Name' name='pkg_name' onChange={handleChange} value={formData?.pkg_name} autoComplete='off' />
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="Category" className="block text-sm font-medium leading-6 text-gray-900">Category:</label>
                                            <div className="mt-1.5" onFocus={() => { setIsOpenCategoryDropDown(true) }} onBlur={() => { setIsOpenCategoryDropDown(false) }}>
                                                {
                                                    !childOpen && category && category.length > 0 &&
                                                    <MultiSelect
                                                        options={category}
                                                        value={multiThemeCategories}
                                                        onChange={handleSelectMultiThemeCategories}
                                                        hasSelectAll={true}
                                                        ClearSelectedIcon={null}
                                                        isCreatable={true}
                                                        onCreateOption={handleNewCategoryField}
                                                        isOpen={isOpenCategoryDropDown}
                                                    />
                                                }
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="tag" className="block text-sm font-medium leading-6 text-gray-900">Tag:</label>
                                            <div className="mt-1.5" onFocus={() => { setIsOpenTagDropDown(true) }} onBlur={() => { setIsOpenTagDropDown(false) }}>
                                                {
                                                    !childOpen && tag && tag.length > 0 &&
                                                    <MultiSelect
                                                        options={tag}
                                                        value={multiThemeTags}
                                                        onChange={handleSelectMultiThemeTags}
                                                        hasSelectAll={true}
                                                        ClearSelectedIcon={null}
                                                        isCreatable={true}
                                                        onCreateOption={handleNewTagField}
                                                        isOpen={isOpenTagDropDown}
                                                    />
                                                }
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                            </div>
                                        </div>
                                        {/* <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="tag" className="block text-sm font-medium leading-6 text-gray-900">Region:</label>
                                                <div className="mt-1.5">
                                                    {
                                                        region && region.length > 0 &&
                                                        <MultiSelect
                                                            options={region}
                                                            value={multiRegion}
                                                            onChange={handleSelectMultiRegion}
                                                            hasSelectAll={false}
                                                            ClearSelectedIcon={null}
                                                        />
                                                    }
                                                </div>
                                            </div> */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                            <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                                {
                                                    country && country.length > 0 &&
                                                    <MultiSelect
                                                        options={country}
                                                        value={multiCountry}
                                                        onChange={handleSelectMultiCountry}
                                                        hasSelectAll={false}
                                                        ClearSelectedIcon={null}
                                                        isOpen={isOpenCountryDropDown}
                                                    />
                                                }
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="themeZip" className="block text-sm font-medium leading-6 text-gray-900">Theme Zip:</label>
                                            <div className="mt-1.5 relative">
                                                <input id='themeZip' type='file' accept='application/x-zip-compressed' name='themeZip' onChange={e => { handleFileInput(e) }} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' required={addEditDeleteTag !== `update`} />
                                                {
                                                    addEditDeleteTag === `update` && formData.themeZip && typeof formData.themeZip == 'string' &&
                                                    <label htmlFor="bigPreviewStickerStore" className='absolute sm:left-[38.5%] left-[33.5%] w-auto rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData.themeZip.split('/').pop(), 9)}</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="showStartDate" className="block text-sm font-medium leading-6 text-gray-900">Start Date:</label>
                                            <div className="mt-1.5">
                                                <Input id='showStartDate' type='date' name='showStartDate' onChange={handleChange} value={formData?.showStartDate?.split('T')[0]} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' />
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="showEndDate" className="block text-sm font-medium leading-6 text-gray-900">End Date:</label>
                                            <div className="mt-1.5">
                                                <Input id='showEndDate' type='date' name='showEndDate' onChange={handleChange} value={formData?.showEndDate?.split('T')[0]} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' />
                                            </div>
                                        </div>
                                        {/* <div className="sm:col-span-2">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Badge:</label>
                                            <div className="mt-1.5">
                                                <Select name='sliderTag' onChange={(e) => handleSingleSelect(`sliderTag`, e)} value={formData?.slider_tag}>
                                                    {tag.map((_ele, i) => <Option key={i} value={_ele.name.toLowerCase()}>{_ele.name}</Option>)}
                                                </Select>
                                            </div>
                                        </div> */}
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isPremium" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isPremium' name='isPremium' onChange={handleSwitch} checked={formData?.isPremium} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isTop" className="block text-sm font-medium leading-6 text-gray-900">Top:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isTop' name='isTop' onChange={handleSwitch} checked={formData?.isTop} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isAnimated" className="block text-sm font-medium leading-6 text-gray-900">Animated:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isAnimated' name='isAnimated' onChange={handleSwitch} checked={formData?.isAnimated} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isHot" className="block text-sm font-medium leading-6 text-gray-900">Hot:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isHot' name='isHot' onChange={handleSwitch} checked={formData?.isHot} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isLatest" className="block text-sm font-medium leading-6 text-gray-900">Latest:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isLatest' name='isLatest' onChange={handleSwitch} checked={formData?.isLatest} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isLiveToday" className="block text-sm font-medium leading-6 text-gray-900">Live Today:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isLiveToday' name='isLiveToday' onChange={handleSwitch} checked={formData?.isLiveToday} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={() => { handleClose(); setCategory([]); setMultiThemeCategories([]); setTag([]); setMultiThemeTags([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            disabled={isModalLoading}
                                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Name:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="from_flg" className="block text-lg font-medium leading-5 text-[#1E3354]">Category:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.themeCategory && formData?.themeCategory?.length !== 0 ? formData?.themeCategory?.map((_ele, key) => key === _ele.themeCategory?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Country:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5 truncate w-full">
                                                    {formData?.country && formData?.country?.length !== 0 && formData?.country?.length > 1 ? formData?.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + formData?.country[formData?.country.length - 1].name : formData?.country && formData?.country?.length !== 0 && formData?.country[formData?.country.length - 1].name || `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        {/* <div className="sm:col-span-2">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Region:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.region && formData?.region?.length !== 0 && formData?.region?.length > 1 ? formData?.region.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + formData?.region[formData?.region.length - 1].name : formData?.region && formData?.region?.length !== 0 && formData?.region[formData?.region.length - 1].name || `None`}
                                                </Typography>
                                            </div>
                                        </div> */}
                                        <div className="sm:col-span-2">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Tag:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.themeTag && formData?.themeTag?.length !== 0 ? formData?.themeTag?.map((_ele, key) => key === _ele.themeTag?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Zip Name:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5 truncate w-full">
                                                    {formData?.themeZip ? formData?.themeZip.split('/').pop() : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="city" className="block text-lg font-medium leading-5 text-[#1E3354]">Start Date:</label>
                                            <div className="mt-2 grid grid-cols-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.showStartDate?.split('T')[0] || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">End Date:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.showEndDate?.split('T')[0] || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Badge:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.orientation || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Animated:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isAnimated ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Top:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isTop ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Hot:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isHot ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isActive ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Premium:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isPremium ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Latest:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isLatest ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Available Diy key:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isAvaiableDiffKey ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-[30px]'>
                                        <Button
                                            onClick={() => { handleClose(); setCategory([]); setMultiThemeCategories([]); setTag([]); setMultiThemeTags([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div >
                </DialogContent >
            </Dialog >
            <ThemeChildModal />
        </>
    )
}

export default ThemeModal
