import React, { useContext, useEffect } from 'react'
import { Card, CardBody, IconButton, Switch, Checkbox } from "@material-tailwind/react";
import { BiEdit, BiLink } from 'react-icons/bi';
import { IoEyeOutline, IoClose } from 'react-icons/io5';
import { TbWorld } from 'react-icons/tb';

import { Dropdowns, Pagination, SearchAndAddBtn, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_sliders_data } from '../../../Controller/actions';
import { MdOutlineCategory } from 'react-icons/md';


const FilterDropdowns = () => {
    const countryFilterData = ["Afghanistan", "India", "Pakistan"]
    return (
        <div className='flex gap-4'>
            <Dropdowns name={`Country`} Icon={TbWorld} dropData={countryFilterData} />
            <Dropdowns name={`Categories`} Icon={MdOutlineCategory} dropData={countryFilterData} />
        </div>
    )
}
export const ThemeBadges = ({ title }) => {
    const { checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitch, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Badge", },
    ];
    // const dropdownFields = [{ name: "Active", }];

    const getSliderData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getSliders = await get_sliders_data(_currentPage, perPage, search, filterDropdown);
            if (getSliders) {
                const { status, data, message, currentPage, perPage, totalPage } = getSliders;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(currentPage);
                    setPerPage(perPage);
                    setTotalPage(totalPage || 1);
                } else {
                    setCurrentPageData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getSliderData(currentPage);
    }, [search]);


    useEffect(() => {
        document.title = title;
    }, [title])

    return (
        <>
            <SearchAndAddBtn title={`Theme Badges`} addBtn={true} FilterDropdowns={false} deleteUpdateTag='all-delete-sliders' />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        {/* <thead className='bg-white'>
                            <tr className='border-b border-transparent'>
                                {TABLE_HEAD.map(({ name, sortable = true }, i) => (
                                    <th key={name} className="cursor-pointer px-4 transition-colors hover:bg-blue-gray-50">
                                        <span className="flex items-center gap-1.5 font-medium text-lg leading-none text-[#1E1627]">
                                        {name}
                                            {sortable && i !== TABLE_HEAD.length - 0 && (
                                                <FaSort className="w-4 h-4 text-[#75869F]" />
                                                )}
                                        </span>
                                    </th>
                                ))}          
                                </tr>
                        </thead> */}
                        <THead
                            simpleFields={simpleFields}
                        // dropdownFields={dropdownFields}
                        />
                        <tbody>
                            {
                                currentPageData?.map(({ _id, preview_img, name, link, country, category, slider_tag, priority, isActive }, i) => {
                                    const isLast = i === currentPageData?.length - 1;
                                    const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                    return (
                                        <tr key={i} className='border-b border-blue-gray-50'>
                                            <td className={classes}>
                                                <Checkbox
                                                    className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                    id={`slider-${_id}`}
                                                    value={`${_id}`}
                                                    checked={checkedValues.includes(`${_id}`)}
                                                    onChange={() => handleCheckboxChange(`${_id}`)}
                                                />
                                            </td>
                                            <td className={classes}>
                                                <div className="flex items-center">
                                                    <span className={`font-normal text-[17px] ${!name && `text-gray-500`}`}>{name || `None`}</span>
                                                    {
                                                        link && <button onClick={() => handleCopyData(link)}><BiLink className='text-[#2288FF] ml-2.5' size={20} /></button>
                                                    }
                                                </div>
                                            </td>

                                            <td className={classes}>
                                                <div className="flex items-center gap-2">
                                                    <IconButton onClick={() => handleOpen()} className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'>
                                                        <BiEdit />
                                                    </IconButton>
                                                    <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'>
                                                        <IoEyeOutline />
                                                    </IconButton>
                                                    <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none'>
                                                        <IoClose />
                                                    </IconButton>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </CardBody>
                {
                    totalPage > 1 &&
                    <Pagination currentPage={currentPage} totalPage={totalPage} getData={getSliderData} />
                }
            </Card>
        </>
    )
}

ThemeBadges.defaultProps = {
    title: "My Photo Keyboard | ThemeBadges"
};

export default ThemeBadges
