import React, { useContext } from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { NewContext } from "../../Context/NewContext";

export const Pagination = ({ totalPage, currentPage, getData }) => {
    const { checkedValues, setCheckedValues } = useContext(NewContext);
    const getItemProps = (_currentPage) => ({
        variant: _currentPage ? "filled" : "text",
        className: `w-8 h-8 ${_currentPage ? `bg-[#0047FF]` : `text-[#1E1627]`}`,
    });

    return (
        <>
            {
                (totalPage > 1) &&
                <div className="flex items-center justify-center md:justify-end gap-2 lg:gap-4 p-2 bg-gray-100 sticky bottom-0 z-20">
                    <Button
                        variant="text"
                        className={`hidden md:flex items-center gap-0 md:gap-2 text-[#1E1627]${currentPage !== 1 && `text-[#C0BBCA]`}`}
                        onClick={() => { setCheckedValues([]); getData(currentPage - 1) }}
                        disabled={currentPage === 1 ? true : false}
                    >
                        Previous Page
                    </Button>
                    <div className="flex items-center gap-3">
                        {
                            (currentPage > 2) && (totalPage !== 3) &&
                            // <Pagination.Ellipsis onClick={() => getData(currentPage - 2)} />
                            <IconButton {...getItemProps()} onClick={() => { setCheckedValues([]); getData(currentPage - 2) }}>...</IconButton>
                        }
                        {
                            (currentPage !== 1) && (totalPage < (currentPage - 1)) &&
                            <IconButton
                                {...getItemProps(currentPage === (currentPage - 2))}
                                onClick={() => { setCheckedValues([]); getData(currentPage - 2) }}
                            >
                                {currentPage - 2}
                            </IconButton>
                        }
                        {
                            (currentPage !== 1) &&
                            <IconButton
                                {...getItemProps(currentPage === (currentPage - 1))}
                                onClick={() => { setCheckedValues([]); getData(currentPage - 1) }}
                            >
                                {currentPage - 1}
                            </IconButton>
                        }
                        <IconButton {...getItemProps(currentPage)} onClick={() => { setCheckedValues([]); getData(currentPage) }}>{currentPage}</IconButton>
                        {
                            (currentPage !== totalPage) &&
                            <IconButton
                                {...getItemProps(currentPage === (currentPage + 1))}
                                onClick={() => { setCheckedValues([]); getData(currentPage + 1) }}
                            >
                                {currentPage + 1}
                            </IconButton>
                        }
                        {
                            (currentPage === 1) && (totalPage > (currentPage + 1)) &&
                            <IconButton
                                {...getItemProps(currentPage === (currentPage + 2))}
                                onClick={() => { setCheckedValues([]); getData(currentPage + 2) }}
                            >
                                {currentPage + 2}
                            </IconButton>
                        }
                        {
                            (totalPage > (currentPage + 1)) && (totalPage !== 3) &&
                            <IconButton {...getItemProps()} onClick={() => { setCheckedValues([]); getData(currentPage + 2) }}>...</IconButton>
                        }
                        {
                            (currentPage < 3) && (totalPage > (currentPage + 1)) && (totalPage !== 3) &&
                            <IconButton
                                {...getItemProps(currentPage === totalPage)}
                                onClick={() => { setCheckedValues([]); getData(totalPage) }}
                            >
                                {totalPage}
                            </IconButton>
                        }
                    </div>
                    <Button
                        variant="text"
                        className={`hidden md:flex items-center gap-0 md:gap-2 text-[#1E1627]${currentPage !== totalPage && `text-[#C0BBCA]`}`}
                        onClick={() => { setCheckedValues([]); getData(currentPage + 1) }}
                        disabled={currentPage === totalPage ? true : false}
                    >
                        Next Page
                    </Button>
                </div >
            }
        </>
    );
};

export default Pagination;
