import React, { useContext } from 'react'
import { Typography, Button } from "@material-tailwind/react";
import { NewContext } from '../../Context/NewContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileInput from '../FileInput/FileInput';

export const AddEditorModal = () => {
    const { currentHomeModalKey, handleClose, formData, open, addEditDeleteTag } = useContext(NewContext);

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); }}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {
                        addEditDeleteTag === 'view' ? 'Details  View' : currentHomeModalKey === 'update-c' ? `Edit Blog` : `Story Preview`
                    }
                </DialogTitle>
                <DialogContent>
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
                        <div className='lg:col-span-1'>
                            <Typography className="text-lg text-[#1E1627] font-medium">Preview:</Typography>
                            <div className='w-auto'>
                                <FileInput name={`blogPreview`} imgClass={`h-full`} previewFields={'preview'} accept="image/png, image/jpeg" width={360} height={192} />
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            <div>
                                <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Title:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.title || `None`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">View:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.view || `0`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Category:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.categoriesName || `None`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{!formData?.isActive ? "Off" : "On"}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Description:</label>
                                        <div className="mt-2 mb-2 max-h-[140px] h-[100%] overflow-auto">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.description || `None`}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <Button
                                        onClick={() => { handleClose(); }}
                                        className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default AddEditorModal;
