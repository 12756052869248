import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner } from "@material-tailwind/react";
import { BiEdit, BiLink } from 'react-icons/bi';
import { IoEyeOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';

import { Pagination, SearchAndAddBtn, Switch, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_store } from '../../Controller/actions';
import Zoom from 'react-medium-image-zoom';
import StoreModal from '../../Components/Modals/StoreModel';
import copy from 'copy-to-clipboard';
import { FcDocument } from "react-icons/fc";
import { toast } from 'react-toastify';

export const Store = ({ title }) => {
    const { permissions, tokenType, setDeleteUpdateTag, isLoading, confirmationModalShow, setConfirmationModalShow, deleteUpdateTag, setAddEditDeleteTag, setDeleteUpdateItem, setFormData, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitchUpdate, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Preview" },
        { name: "Name" },
        { name: "Copy" }
    ];
    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });
    const dropdownFields = [];

    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const getSliderData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getSliders = await get_store(_currentPage, perPage, search, filterDropdown);
            if (getSliders) {
                const { status, data, currentPage, perPage, totalPage } = getSliders;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(currentPage || 1);
                    setPerPage(perPage || 15);
                    setTotalPage(totalPage || 1);
                } else {
                    setCurrentPageData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const extractFileName = (url) => {
        const match = url.match(/\/([^/]+)\.[^/.]+$/);
        return match ? match[1] : null;
    }

    useEffect(() => {
        getSliderData(currentPage);
        setDeleteUpdateTag("");
    }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Asset store');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])

    useEffect(() => {
        document.title = title;
    }, [title]);
    return (
        <>
            <SearchAndAddBtn title={`Store`} isSearch={false} addBtn={pagePermissions?.CU} deleteUpdateTag='all-delete-store' countryFilter={false} />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        <THead simpleFields={simpleFields} dropdownFields={dropdownFields} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;
                                            const isImage = /\.(jpeg|jpg|gif|png|webp|bmp|svg|tiff)$/i.test(_ele.preview || "");
                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            value={`${_ele._id}`}
                                                            disabled={!pagePermissions?.CU}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>
                                                    <td className={`${classes}`}>
                                                        <div className='imgTd relative'>
                                                            {isImage ? (
                                                                <img
                                                                    src={_ele.preview}
                                                                    alt={_ele.name}
                                                                    className="mr-0 h-[55px] rounded-lg block absolute"
                                                                />
                                                            ) : (
                                                                <FcDocument className="mr-0 h-[55px] w-10" />
                                                            )}
                                                            {isImage && <Zoom>
                                                                <img src={_ele.preview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 h-[55px] rounded-lg block' />
                                                            </Zoom>}
                                                        </div>
                                                    </td>
                                                    <td> {
                                                        _ele.preview &&
                                                        <div className="flex items-center">
                                                            <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{extractFileName(_ele.preview)}</h1>
                                                        </div>
                                                    }
                                                    </td>
                                                    <td>
                                                        <button onClick={() => {
                                                            copy(_ele.preview);
                                                            toast.success(`link Copied.`);
                                                        }}><BiLink className='text-[#2288FF] ml-2.5' size={20} /></button>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-store"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            {
                totalPage > 1 &&
                <Pagination currentPage={currentPage} totalPage={totalPage} getData={getSliderData} />
            }
            <StoreModal />
        </>
    )
}
Store.defaultProps = {
    title: "My Photo Keyboard | Creative Fonts"
};
export default Store
