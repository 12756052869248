import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useContext } from 'react'
import { NewContext } from '../../Context/NewContext';
import { Button, Spinner, Typography } from '@material-tailwind/react';
import { CommunityBtn } from '../../Components';
import moment from 'moment';


export const RejectThemesModal = () => {
    const { open, isLoading, handleClose, formData, setDeleteUpdateItem, setDeleteUpdateYes, confirmationModalShow, setConfirmationModalShow } = useContext(NewContext);
    return (
        <>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "885px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627] !text-[28px] font-medium leading-none'>
                    {"Details View"}
                </DialogTitle>

                <DialogContent>
                    <div className="">
                        <div className="mb-4 bg-[#EAEDF3] p-2.5">
                            <h6 className="text-[#1E3354] text-lg leading-none font-semibold ">{"Themes Details"}</h6>
                        </div>
                        <div className="mb-4 grid grid-cols-12 gap-2">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Thumbnail:</label>
                                <div className="mt-2">
                                    <img src={formData.preview} alt="" className='rounded-lg' />
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Preview:</label>
                                <div className="mt-2">
                                    <img src={formData.smallPreview} alt="" className='rounded-lg' />
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 grid grid-cols-12 border-b border-[#B4BDCE] pb-3 gap-2">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Title:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.title}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Likes:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.like?.length}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-6">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Dislikes:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.dislike_count}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 grid grid-cols-12 border-b border-[#B4BDCE] pb-3 gap-2">
                            <div className="col-span-6 sm:col-span-6">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Downloads:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.downloadCount}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-6">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Status:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.themeStatus}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="mb-[30px] grid grid-cols-12 gap-2">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Uploaded date:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{moment(formData?.createdAt).format("DD/MM/YYYY")}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-9">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Rejected by:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.updatedBy?.name}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 bg-[#EAEDF3] p-2.5 gap-2">
                            <h6 className="text-[#1E3354] text-lg leading-none font-semibold ">{"User Details"}</h6>
                        </div>
                        <div className="mb-2 grid grid-cols-12 border-b border-[#B4BDCE] pb-3">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Profile:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.name}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">User Name:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.name}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Contact No:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.mobile}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Gender:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.Gender}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 grid grid-cols-12 gap-2">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Login From:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.loginType}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Profile Type:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.profileImgType}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Login Key:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.userId?.loginKey}</Typography>
                                </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Email id:</label>
                                <div className="mt-2">
                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5 truncate">{formData?.userId?.email}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-center sm:justify-end mt-10 gap-2'>
                            <Button onClick={() => { handleClose(); }}
                                className="px-4 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                            >
                                Cancel
                            </Button>
                            <Button className='bg-[#0047FF] text-white px-5 py-2.5 rounded-lg text-xs sm:text-base leading-none font-medium shadow-none hover:shadow-none capitalize' onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: formData._id, themeStatus: 'pending' }); setConfirmationModalShow(!confirmationModalShow) }}>Move to pending</Button>
                            <Button className='bg-[#2BC127] text-white px-5 py-2.5 rounded-lg text-xs sm:text-base leading-none font-medium shadow-none hover:shadow-none capitalize' onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: formData._id, themeStatus: 'approved' }); setConfirmationModalShow(!confirmationModalShow) }}>Approved</Button>

                            <Button
                                disabled={isLoading}
                                className="px-4 py-2.5 bg-transparent shadow-none text-[#DD2B2B] font-medium border border-[#DD2B2B] hover:shadow-none capitalize text-base"
                                type='submit'
                                onClick={() => { setDeleteUpdateYes("delete-approvedTheme"); setDeleteUpdateItem({ id: formData._id }); setConfirmationModalShow(!confirmationModalShow) }}
                            >
                                {isLoading && <Spinner className="h-4 w-4" />}
                                <span className={isLoading ? 'ml-4' : ''}>{"Delete"}</span>
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RejectThemesModal
