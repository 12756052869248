import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { AiFillCaretDown } from "react-icons/ai";
import { FaSort } from "react-icons/fa";
import { NewContext } from "../../Context/NewContext";
import { useLocation, useNavigate } from "react-router-dom";

export const THead = ({ simpleFields = [], dropdownFields = [], rangeFilterFields = [], actionField = true, isSelectAll = true, }) => {
  let newObject;
  const [theadTop, setTHeadTop] = useState(0);
  let { state, pathname } = useLocation();
  let navigate = useNavigate();
  const { setAddEditDeleteTag, setCurrentPageData, filterDropdown, setFilterDropdown, homeScreenData, currentPageData, checkedValues, handleFilterChange, handleSelectAll, currentHomeModalKey, handleOpen, } = useContext(NewContext);

  const [isAscending, setIsAscending] = useState(true);
  const sortData = (e) => {
    let sortedData = [...currentPageData];
    sortedData.sort((a, b) => {
      const nameA = a[e]?.toString().toUpperCase();
      const nameB = b[e]?.toString().toUpperCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      }
      return 0;
    });
    if (!isAscending) {
      sortedData.reverse();
    }
    setIsAscending(!isAscending);
    setCurrentPageData(sortedData.length > 0 ? sortedData : currentPageData);
  };

  useEffect(() => {
    if (dropdownFields && currentHomeModalKey == "add") {
      newObject = Object.assign({}, ...dropdownFields.map((item) => ({ [`is${item.name}`]: "" })));
      if (state && state.isAdd) {
        setFilterDropdown({ ...newObject, isActive: true });
        setAddEditDeleteTag(`add`);
        handleOpen();
        navigate(pathname, {});
      } else {
        setFilterDropdown(newObject);
      }
    }
  }, []);

  useEffect(() => {
    let mainHeader = document && document.getElementById("main-header") && document.getElementById("main-header").offsetHeight;
    let searchAndAddBar = document && document.getElementById("searchAndAddBar") && document.getElementById("searchAndAddBar").offsetHeight;
    setTHeadTop(mainHeader + searchAndAddBar);
  }, [document]);

  return (
    <>
      {theadTop > 0 && (
        <thead className={``}>
          <tr className="align-middle">
            {isSelectAll && (
              <th className="select-all-th px-4 text-[#1E1627] w-7">
                <Checkbox
                  className="w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent"
                  checked={
                    (currentPageData?.length > 0 &&
                      currentPageData?.length === checkedValues?.length) ||
                    (homeScreenData?.length > 0 &&
                      homeScreenData?.length === checkedValues?.length)
                  }
                  onChange={handleSelectAll}
                />
              </th>
            )}
            {simpleFields &&
              simpleFields?.map(({ name, sortable = false, field }, i) => (
                <th key={name} className="cursor-pointer px-4 transition-colors hover:bg-blue-gray-50">
                  <span className="gap-1.5 font-medium text-lg leading-none text-[#1E1627]" onClick={() => sortable && sortData(field)}>
                    {name}
                    {sortable && i !== simpleFields?.length - 0 && (
                      <FaSort className="inline ml-1 w-4 h-4 text-[#75869F]" />
                    )}
                  </span>
                </th>
              ))}
            {dropdownFields &&
              dropdownFields?.map((_dropdown, i) => (
                <th key={i}>
                  {dropdownFields.includes(_dropdown) && (
                    <div className="table-dropdown relative inline-block">
                      <Menu placement="bottom-start">
                        <MenuHandler>
                          <Button variant="text" className="px-4 py-3 capitalize flex items-center gap-1.5 font-medium text-lg leading-none text-[#1E1627] hover:bg-transparent active:bg-transparent focus:bg-transparent focus-within:bg-transparent">
                            {_dropdown.name}
                            <AiFillCaretDown className="ml-1 drop-carat" size={"14px"} />
                          </Button>
                        </MenuHandler>
                        <MenuList className="border-[#B4BCC9] p-0 overflow-hidden">
                          {[{ label: `On`, value: true }, { label: `Off`, value: false }, { label: `All`, value: "" },].map((_ele, i) => (
                            <MenuItem
                              name={`is${_dropdown.name}`}
                              value={_ele.value}
                              key={i}
                              onClick={handleFilterChange}
                              className={`rounded-none ${filterDropdown[`is${_dropdown.name}`] === _ele.value ? `bg-[#0047FF] text-white hover:bg-[#0047FF] hover:text-white focus:bg-[#0047FF] focus:text-white active:bg-[#0047FF] active:text-white` : ``}`}
                            >
                              {_ele.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </div>
                  )}
                </th>
              ))}
            {actionField && <th className="text-[#1E1627] px-4">Actions</th>}
          </tr>
        </thead>
      )}
    </>
  );
};

export default THead;
