import React, { useContext, useEffect, useState } from 'react';
import { Card, Typography, List, ListItem, ListItemPrefix, AccordionHeader, AccordionBody, Button, Accordion, } from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NewContext } from '../../Context/NewContext';
import './sidebar.css';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';
import pkg from '../../../package.json'
// import Accordion from '../Accordian/Accordion';

export const Sidebar = () => {
  const [open, setOpen] = useState(0);
  const [activeAccordian, setActiveAccordian] = useState(`/${window.location.hash.split('/')[1]}`);
  const { permissions, toggleSidebar, setToggleSidebar, setSearch, setCurrentPage, setCheckedValues, setPerPage } = useContext(NewContext);
  const [currentRoutes, setCurrentRoutes] = useState(routes.sidebarRoutes);
  const [currentPath, setCurrentPath] = useState('')
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  useEffect(() => {
    if (permissions?.subPermissions) {
      const updatedRoutes = routes?.sidebarRoutes?.filter((route) => permissions?.subPermissions?.some((permission) => {
        if (route?.items) {
          route.items = route?.items?.filter((route) => permissions?.subPermissions?.some((itemPermission) => route?.key === itemPermission?.key))
          if (route.items.length) {
            route.path = route.items[0].path
            return route;
          }
        }
        if (route?.key === permission?.key) return permission
      }));
      setCurrentRoutes(updatedRoutes);
    }
  }, [permissions])

  useEffect(() => {
    // console.log(currentPath)
  }, [currentPath])

  return (
    <>
      <div className={`bg-black opacity-25 fixed h-screen w-screen  ${toggleSidebar ? `block z-20` : `hidden`}`} onClick={() => setToggleSidebar(!toggleSidebar)}></div>
      <Card className={`${toggleSidebar ? "translate-x-0" : "-translate-x-80"} fixed h-full w-72 max-w-[20rem] rounded-none shadow-none border-r border-[#BCC2CC] transition-transform duration-300 2xl:translate-x-0 z-50`}>
        <div className="flex items-center justify-center gap-4 py-[25px] px-[27px]">
          <Typography variant="h5" color="blue-gray" className='flex flex-row items-end'>
            <img src="../../assets/images/MPK_Admin_logo.svg" alt="logo_dashboard" className='w-48' />
            <div className='font-extrabold text-gray-400 text-[10px] ml-1 mb-1'>v{pkg.version}</div>
          </Typography>
        </div>
        <List className='py-0 px-4 sidebar overflow-y-auto'>
          {
            currentRoutes?.map(({ name, path, icon, items }, i) => (
              <span key={i} className={``}>
                {
                  items ?
                    <>
                      <Accordion open={open === i + 1} icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform" ${open === i + 1 ? "rotate-180" : ""}`} />}                      >
                        <ListItem className={`p-0 rounded-none hover:bg-transparent focus:bg-transparent focus:bg-opacity-0 active:bg-blue-gray-0 active:bg-opacity-0 hover:text-white focus:text-transparent active:text-transparent bg-transparent border-[#E3E8F1] border-b`} selected={open === i + 1}>
                          {/*  setToggleSidebar(false); navigate(path); */}
                          <AccordionHeader onClick={() => { setSearch(""); setCheckedValues([]); handleOpen(i + 1); setPerPage(15); setActiveAccordian(path); setCurrentPage(1); }} className={`border-b-0 px-[20px] py-[16px] hover:text-white hover:bg-[#0047FF] ${items.find(_ele => _ele.path === activeAccordian) || open === i + 1 ? `text-white bg-[#0047FF]` : ``}`}>
                            <ListItemPrefix><span>{icon}</span></ListItemPrefix>
                            <Typography className="mr-auto font-normal">{name}</Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                          <List className="p-0">
                            {
                              items?.map(({ name, path }, j) => (
                                <NavLink to={path} key={j} className="flex flex-row items-center justify-between" onClick={() => { setPerPage(15); setSearch(""); setCheckedValues([]); setToggleSidebar(false); setActiveAccordian(path); setCurrentPage(1); }}>
                                  {
                                    ({ isActive }) => (
                                      <ListItem className={`hover:bg-transparent rounded-none  hover:text-[#0047FF] active:bg-transparent active:text-[#0047FF] focus:bg-transparent focus:text-[#0047FF] border-b ${isActive ? `text-[#0047FF]` : `text-darkblue`} `}>
                                        <div className='ml-9'>- {name}</div>
                                      </ListItem>
                                    )
                                  }
                                </NavLink>
                              ))
                            }
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </>
                    :
                    <>
                      <NavLink to={path} key={i} className="flex flex-row items-center justify-between border-[#E3E8F1] border-b" onClick={() => { setPerPage(15); setSearch(""); setCheckedValues([]); setOpen(0); setToggleSidebar(false); setActiveAccordian(false); setCurrentPage(1); }}>
                        {
                          ({ isActive }) => (
                            <Button className={`w-full px-[20px] py-[16px] flex items-center text-sm font-normal shadow-none hover:shadow-none rounded-none capitalize hover:text-white hover:bg-[#0047FF]  ${isActive ? `text-white bg-[#0047FF]` : `text-darkblue bg-[#fff]`} `}>
                              <span className='mr-[15px]'>{icon}</span>
                              <span className='text-capitalize leading-none'>{name}</span>
                            </Button>
                          )
                        }
                      </NavLink>
                    </>
                }
              </span>
            ))
          }
          {/* <Accordion /> */}
        </List>
      </Card>
    </>
  )
}

export default Sidebar  