import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, IconButton, Checkbox, Typography, Spinner } from "@material-tailwind/react";
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { RxHamburgerMenu } from 'react-icons/rx';
import { BsFillPlayFill, BsFillStarFill } from 'react-icons/bs';
import { TbDownload } from 'react-icons/tb';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Slider from "react-slick";

import { HomeActivityModal, SearchAndAddBtn, Switch, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_home_activity_data, get_home_screen_data, get_wallpaper_home_screen_data } from '../../Controller/actions';
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';
import HomeActivityPDF from '../../Components/Modals/HomeActivityPDF';

export const HomeActivity = ({ title }) => {
    const { permissions, tokenType, deleteUpdateYes, homeScreenData, setHomeScreenData, setCurrentHomeModalKey, setCurrentModalTableData, setDeleteUpdateTag, confirmationModalShow, setConfirmationModalShow, deleteUpdateTag, setAddEditDeleteTag, setDeleteUpdateItem, setFormData, checkedValues, handleCheckboxChange, currentPage, perPage, search, setDeleteUpdateYes, handleSwitchUpdate, isLoading, setIsLoading, filterDropdown, handleOpen, handleOpenbtn } = useContext(NewContext);

    const [devicePreviewData, setDevicePreviewData] = useState({});
    const [wallpaperCount, setWallpaperCount] = useState(6);

    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });

    const simpleFields = [
        { name: "Name", },
        // { name: "Priority", },
        { name: "Data limit", },
        { name: "Show Name Home Screen" },
        { name: "Active" }
    ];
    const dropdownFields = [
        // { name: "Show Name Home Screen" }, { name: "Active" }
    ];
    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const getSliderData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getHomeSectionSettings = await get_home_activity_data();
            const getHomeActivityPreview = await get_home_screen_data();
            if (getHomeActivityPreview) {
                if (getHomeActivityPreview.status) {
                    setHomeScreenData(getHomeActivityPreview.section_setting);
                    getHomeActivityPreview.section_setting.map((_ele) => {
                        if (_ele.key === 'wallpaper') {
                            setWallpaperCount(_ele.limit);
                        }
                    });
                    const getWallpapers = await get_wallpaper_home_screen_data(wallpaperCount);
                    setDevicePreviewData(_prev => ({ ...getHomeActivityPreview, wallpaper_list: getWallpapers }));
                    setCurrentModalTableData(_prev => ({ ...getHomeActivityPreview, wallpaper_list: getWallpapers }));
                } else {
                    setHomeScreenData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        getSliderData(currentPage);
        setDeleteUpdateTag("");
    }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, wallpaperCount, pagePermissions]);

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Home Screen');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])

    const handleDragEnd = (e) => {
        if (!pagePermissions?.CU) return;
        if (!e.destination) return;
        let tempData = Array.from(homeScreenData);
        let [source_data] = tempData.splice(e.source.index, 1);
        source_data.priority = e.destination.index + 1;
        tempData.splice(e.destination.index, 0, source_data);
        let newData = tempData.map((_ele, i) => ({ ..._ele, priority: i + 1 }));
        setHomeScreenData(newData);
    };

    useEffect(() => {
        document.title = title;
    }, [title]);

    const handleDragStart = (e) => e.preventDefault();
    const percent = 0.3;
    const section = useRef(null);
    const responsive = { 0: { items: 3 } }
    const [padding, setPadding] = useState(0);
    const syncState = () => {
        const { current } = section;
        if (current) { setPadding(current.offsetWidth * percent); }
    };

    const settings = {
        // className: "center",
        // centerMode: true,
        infinite: false,
        slidesToShow: 3,
        slideToScroll: 1,
        autoplay: false,
        speed: 500,
        // autoplaySpeed: 500,
        cssEase: "linear",
    };

    const settings1 = {
        className: "center",
        // centerMode: true,
        infinite: false,
        slidesToShow: 1,
        slideToScroll: 1,
        autoplay: true,
        speed: 1200,
        autoplaySpeed: 3000,
        cssEase: "linear",
        dots: false
    };

    let itemsSlider = devicePreviewData?.slider_list?.map((_ele, i) => <img key={i} src={_ele.preview_img} alt={_ele.name} onDragStart={handleDragStart} role="presentation" />)
    let itemsWallpaper = devicePreviewData?.wallpaper_list?.length > 0 ? devicePreviewData?.wallpaper_list?.map((_ele, i) => <div key={i} className='w-24 h-40 2xl:w-[118px] 2xl:h-[170px] overflow-hidden'><img src={_ele.urls.regular} alt={_ele.alt_description} role="presentation" className='p-1 2xl:p-2 w-full h-full rounded-3xl' /></div>) : []
    //w-24 h-40 2xl:w-[118px] 2xl:h-[170px] overflow-hidden rounded-2xl !flex
    let itemsTopCreators = devicePreviewData?.top_creator_theme_list?.length > 0 ? devicePreviewData?.top_creator_theme_list?.map((_ele, i) => (
        <div className="p-2.5 bg-[#FFFFFF] rounded-xl" key={i}>
            <div className="grid grid-cols-12 gap-1">
                <div className="col-span-6">
                    <img src={_ele.thumbnail_small_path} alt={_ele.title} className='bg-black rounded-xl' />
                </div>
                <div className="col-span-6 p-2">
                    <div className="mb-10">
                        <h2 className="font-semibold text-[#1E3354] text-md sm:text-lg truncate">{_ele.title}</h2>
                        <p className='font-medium text-[10px] sm:text-sm text-[#7588A7] my-1.5'>By {_ele.username}</p>
                    </div>
                    <div className="w-full flex items-center">
                        <div className="flex items-center gap-1">
                            <span className='text-[#7588A7] text-xs sm:text-base'>{_ele.likeCount}</span>
                            <AiOutlineLike className='text-xs sm:text-lg' />
                        </div>
                        <div className="flex items-center gap-1 mx-4">
                            <span className='text-[#7588A7] text-xs sm:text-base'>{_ele.dislikeCount}</span>
                            <AiOutlineDislike className='text-xs sm:text-lg' />
                        </div>
                        <div className="flex items-center gap-1">
                            <span className='text-[#7588A7] text-xs sm:text-base'>{_ele.downloadCount}</span>
                            <TbDownload className='text-xs sm:text-lg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )) : []

    return (
        <>
            <SearchAndAddBtn title={`Home Activity`} addBtn={pagePermissions?.CU} deleteUpdateTag='all-delete-homeactivity' countryFilter={false} isSearch={false} updateBtn={pagePermissions?.CU} redirectionBtn={false} perPageCount={false} infoBtn={true} />
            <Card className="w-auto h-auto rounded-md shadow-none">
                {/* <Button onClick={() => { setCurrentHomeModalKey('slider'); handleOpenbtn(); }}>Open modal</Button> */}
                <CardBody className="w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <div className="md:grid grid-cols-12 gap-1 flex flex-wrap">
                        <div className="col-span-6 lg:col-span-8 overflow-x-auto w-100 pr-3">
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <table className="!w-full min-w-max table-auto text-left">
                                    <THead simpleFields={simpleFields} dropdownFields={dropdownFields} />
                                    <Droppable droppableId='droppable-1'>
                                        {
                                            (provider) => (
                                                <tbody className='table-scroll' ref={provider.innerRef} {...provider.droppableProps}>

                                                    {
                                                        isLoading ?
                                                            <tr>
                                                                <td colSpan={totalFields} className='py-12'>
                                                                    <div className="flex justify-center items-center">
                                                                        <Spinner />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : homeScreenData.length === 0 ?
                                                                <tr>
                                                                    <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                                        <div className="flex justify-center items-center">
                                                                            No Data Found
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                : homeScreenData?.map((_ele, i) => {
                                                                    const isLast = i === homeScreenData?.length - 1;
                                                                    const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                                                    return (
                                                                        <Draggable draggableId={_ele._id} key={_ele._id} index={i}>
                                                                            {
                                                                                (provider) => (
                                                                                    <tr className='border-b border-blue-gray-50' ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
                                                                                        <td className={classes}>
                                                                                            <Checkbox
                                                                                                className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                                                                id={`slider-${_ele._id}`}
                                                                                                value={`${_ele._id}`}
                                                                                                disabled={!pagePermissions?.CU}
                                                                                                checked={checkedValues.includes(`${_ele._id}`)}
                                                                                                onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className={classes}>
                                                                                            <div className="flex items-center">
                                                                                                <span className={`font-normal text-[17px] ${!_ele.name && `text-gray-500`}`}>{_ele.name || `None`}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        {/* <td className={classes}>
                                                                                            <span className={`font-normal text-[17px] ${!_ele.key && `text-gray-500`}`}>{_ele.key || `None`}</span>
                                                                                        </td> */}
                                                                                        {/* <td className={classes}>
                                                                                            <span className={`font-normal text-[17px] ${!_ele.priority && `text-gray-500`}`}>{_ele.priority || `None`}</span>
                                                                                        </td> */}
                                                                                        <td className={classes}>
                                                                                            <span className={`font-normal text-[17px] ${!_ele.limit && `text-gray-500`}`}>{_ele.limit}</span>
                                                                                        </td>
                                                                                        {
                                                                                            ["isShowNameHomeScreen", "isActive"].map((_is_ele, i) => (
                                                                                                <td className={classes} key={i}>
                                                                                                    {/* <Switch color="green" id={_ele._id} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-homeactivity"); handleSwitchUpdate(e) }} checked={_ele[_is_ele]} /> */}
                                                                                                    <Switch id={_ele._id} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-homeactivity"); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} disabled={(_is_ele !== "isShowNameHomeScreen" && _ele.key === 'theme') || !pagePermissions?.CU} />
                                                                                                </td>
                                                                                            ))
                                                                                        }
                                                                                        <td className={classes}>
                                                                                            <div className="flex items-center gap-2">
                                                                                                <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-homeactivity"); setAddEditDeleteTag('update'); setCurrentHomeModalKey(_ele.key); setFormData(_ele); handleOpen(); }}>
                                                                                                    <BiEdit />
                                                                                                </IconButton>
                                                                                                {/* <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={() => { setAddEditDeleteTag('view'); setCurrentHomeModalKey(_ele.key); handleOpen(); }}>
                                                                                                    <IoEyeOutline />
                                                                                                </IconButton> */}
                                                                                                <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-homeactivity"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                                                    <RiDeleteBinLine />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className={classes} {...provider.dragHandleProps}>
                                                                                            {/* <span className='text-2xl leading-none'> */}
                                                                                            <RxHamburgerMenu size={24} />
                                                                                            {/* </span> */}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </Draggable>
                                                                    );
                                                                })
                                                    }
                                                    {provider.placeholder}
                                                </tbody>
                                            )
                                        }
                                    </Droppable>
                                </table>
                            </DragDropContext>
                        </div>

                        <div className="col-span-6 lg:col-span-4 p-2 md:p-5 w-full xs:w-full md:w-full">
                            <div className="px-2 lg:px-5 py-3.5 bg-[#F0F2F6]">
                                <div className="">
                                    <Typography className="text-lg font-medium text-[#1E3354] leading-none">Device Preview</Typography>
                                </div>
                                <div className="device-preview mt-4 py-4 bg-white flex flex-col rounded-md h-[700px] overflow-y-auto">
                                    {
                                        homeScreenData?.map((_ele, i) => (
                                            <div key={i}>
                                                {
                                                    _ele.key === 'slider' && itemsSlider?.length > 0 && _ele.isActive &&
                                                    <div className={`mx-3 lg:mx-5 order-${_ele.priority} ${_ele.priority !== 1 && `mt-4`}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <AliceCarousel
                                                            infinite
                                                            autoPlay={true}
                                                            autoPlayInterval={1500}
                                                            animationDuration={2000}
                                                            mouseTracking
                                                            items={itemsSlider}
                                                            disableButtonsControls={true}
                                                            disableDotsControls={true}
                                                            innerWidth={1}
                                                            onResized={syncState}
                                                            paddingRight={padding}
                                                        />
                                                    </div>
                                                }{
                                                    _ele.key === 'custom_second_sec' && _ele.isActive && devicePreviewData?.custom_second_sec_list?.filter(_ele => _ele.isActive).length > 0 &&
                                                    <div className={`mx-3 lg:mx-5 mt-4 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="grid grid-cols-2 gap-2 lg:gap-2.5">
                                                            {
                                                                devicePreviewData?.custom_second_sec_list?.map((_ele, i) => (
                                                                    <span key={i}>
                                                                        {
                                                                            _ele.isActive &&
                                                                            <div className="" key={i}>
                                                                                <img src={_ele.preview} alt={_ele.name} className='w-[213px]' />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'functionality' && devicePreviewData?.functionality_list?.length > 0 && _ele.isActive &&
                                                    <div className={`mx-3 lg:mx-5 mt-4 p-1 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-5 gap-2 lg:gap-2 justify-center">
                                                            {
                                                                devicePreviewData?.functionality_list?.map((_ele, i) => (
                                                                    <div className="flex-wrap justify-center mx-auto md:mx-0 p-2" key={i}>
                                                                        <div className="text-center">
                                                                            <img src={_ele.preview_img} alt={_ele.name} className='mx-auto w-10 mb-2.5' />
                                                                            <span className='text-center text-[10px] xl:text-xs leading-none text-ellipsis overflow-hidden'>{_ele.name}</span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'theme' && devicePreviewData?.theme_list?.length > 0 && _ele.isActive &&
                                                    <div className={`mx-2 xl:mx-5 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }{
                                                            devicePreviewData?.theme_list?.map((_ele, i) => (
                                                                <div className='mb-6' key={i}>
                                                                    <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                        {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                        <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.theme_type}</h1>
                                                                    </div>
                                                                    <div className="gap-2.5 grid grid-cols-2 lg:grid-cols-2">
                                                                        {
                                                                            _ele.theme?.slice(0, _ele.data_limit_for_homescreen || 4).map((_ele, i) => (
                                                                                <div className="" key={i}>
                                                                                    <img src={_ele.smallPreview} alt={_ele.name} className='w-full md:w-[213px]' />
                                                                                    <div className="flex items-center justify-between text-[10px] md:text-[12px] py-1 lg:text-[15px] leading-none mt-0.5 font-medium">
                                                                                        <div className="">
                                                                                            <p>{_ele.name}</p>
                                                                                        </div>
                                                                                        <div className="flex gap-1 items-center">
                                                                                            <span>{_ele.userHit} </span><TbDownload className='text-[15px]' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }{
                                                    _ele.key === 'Game' && devicePreviewData?.game_list?.length > 0 && _ele.isActive &&
                                                    <div className={`mx-2 xl:mx-5 mt-4 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="grid grid-cols-6 lg:grid-cols-3 xl:grid-cols-6 gap-2.5">
                                                            {
                                                                devicePreviewData?.game_list?.map((_ele, i) => (
                                                                    <div className="col-span-3" key={i}>
                                                                        <div className="border-[1.5px] border-[#E5E7EF] p-[5px] rounded-2xl">
                                                                            <article className="group">
                                                                                <img alt={_ele.name} src={_ele.mediumPreview640} className="h-100 w-full rounded-t-xl object-cover transition" />
                                                                                <div className="p-2">
                                                                                    <div className="flex items-center justify-between">
                                                                                        <div className="">
                                                                                            <a href="#">
                                                                                                <h3 className="text-base leading-none font-bold text-[#1E3354] truncate w-24">{_ele.name}</h3>
                                                                                            </a>
                                                                                            <div className="flex items-center gap-2">
                                                                                                <BsFillStarFill className='text-[#FFD40E] text-[16px]' />
                                                                                                <span className='text-[#7588A7] text-[16px]'>{_ele.game_rating}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <Link type='button' className='bg-[#6245F5] p-2 rounded-lg' to={_ele.link} target='_blank'>
                                                                                                <BsFillPlayFill className='text-white text-[22px] shadow-xl' />
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </article>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'wallpaper' && devicePreviewData?.wallpaper_list?.length > 0 && _ele.isActive &&
                                                    <div className={`mx-3 xl:mx-9 mt-4 order-${_ele.priority} home_wallpaper `}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center justify-between">
                                                                <div className='flex items-center'>
                                                                    {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                    <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                                </div>
                                                                <div className="">
                                                                    <Link to={"#"} className='text-base leading-none text-[#6245F5]'> See More</Link>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div>
                                                            <Slider {...settings}>
                                                                {itemsWallpaper}
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'sticker' && devicePreviewData?.sticker_list?.length > 0 && _ele.isActive &&
                                                    <div className={`mx-2 xl:mx-5 mt-4 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="gap-2.5 grid grid-cols-2 lg:grid-cols-2 mt-4">
                                                            {
                                                                devicePreviewData?.sticker_list?.map((_ele, i) => (
                                                                    <div className="rounded-lg overflow-hidden" key={i}>
                                                                        <img src={_ele.small_preview} alt={_ele.name} className='w-full md:w-[213px]' />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'top_creator_theme' && devicePreviewData?.top_creator_theme_list?.length > 0 && _ele.isActive &&
                                                    <div className={`bg-[#EEF1F5] py-4 my-4 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="overflow-hidden">
                                                            <Slider {...settings1}>
                                                                {itemsTopCreators}
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                }

                                                {/* BANNERS */}
                                                {
                                                    _ele.key === 'font' && _ele.isActive && devicePreviewData?.font?.length > 0 && devicePreviewData?.font[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.font[0]?.banner_image_url} alt={devicePreviewData?.font[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    _ele.key === 'gameBanner' && _ele.isActive && devicePreviewData?.gameBanner?.length > 0 && devicePreviewData?.gameBanner[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.gameBanner[0]?.banner_image_url} alt={devicePreviewData?.gameBanner[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    _ele.key === 'subscription' && _ele.isActive && devicePreviewData?.subscription?.length > 0 && devicePreviewData?.subscription[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.subscription[0]?.banner_image_url} alt={devicePreviewData?.subscription[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'kaomoji' && _ele.isActive && devicePreviewData?.kaomoji?.length > 0 && devicePreviewData?.kaomoji[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.kaomoji[0]?.banner_image_url} alt={devicePreviewData?.kaomoji[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'textart' && _ele.isActive && devicePreviewData?.textart?.length > 0 && devicePreviewData?.textart[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.textart[0]?.banner_image_url} alt={devicePreviewData?.textart[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'sound' && _ele.isActive && devicePreviewData?.sound?.length > 0 && devicePreviewData?.sound[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.sound[0]?.banner_image_url} alt={devicePreviewData?.sound[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'instafont' && _ele.isActive && devicePreviewData?.instafont?.length > 0 && devicePreviewData?.instafont[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        {
                                                            _ele.isShowNameHomeScreen && _ele.name &&
                                                            <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <img src={devicePreviewData?.instafont[0]?.banner_image_url} alt={devicePreviewData?.instafont[0]?.banner_view_type} className='w-full' />
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'effect' && _ele.isActive && devicePreviewData?.effect?.length > 0 && devicePreviewData?.effect[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        <div className="p-1 mb-1.5 font-bold">
                                                            {
                                                                _ele.isShowNameHomeScreen && _ele.name &&
                                                                <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                    {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                    <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                                </div>
                                                            }
                                                            <div className="mt-3">
                                                                <img src={devicePreviewData?.effect[0]?.banner_image_url} alt={devicePreviewData?.effect[0]?.banner_view_type} className='w-full' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }{
                                                    _ele.key === 'emojimaker' && _ele.isActive && devicePreviewData?.emojimaker?.length > 0 && devicePreviewData?.emojimaker[0]?.isActive &&
                                                    <div className={`mx-2 xl:mx-9 my-2 order-${_ele.priority}`}>
                                                        <div className="p-1 mb-1.5 font-bold">
                                                            {
                                                                _ele.isShowNameHomeScreen && _ele.name &&
                                                                <div className="p-1 mb-1.5 font-bold flex items-center">
                                                                    {(_ele.preview || _ele.preview_img) && <img src={(_ele.preview || _ele.preview_img)} alt={_ele.name} className='h-4 mr-1.5' />}
                                                                    <h1 className='text-[14px] lg:text-[16px] text-[#1E3354] leading-none'>{_ele.name}</h1>
                                                                </div>
                                                            }
                                                            <div className="">
                                                                <img src={devicePreviewData?.emojimaker[0]?.banner_image_url} alt={devicePreviewData?.emojimaker[0]?.banner_view_type} className='w-full' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card >
            <HomeActivityModal />
            {/* <NestedModal /> */}
            {/* <Pagination currentPage={currentPage} totalPage={totalPage} getData={getSliderData} /> */}
            {/* <HomeActivityFormModal /> */}
        </>
    )
}

HomeActivity.defaultProps = {
    title: "My Photo Keyboard | Home Activity"
};

export default HomeActivity
