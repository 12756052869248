import React, { useContext, useEffect, useState } from 'react'
import { NewContext } from '../../Context/NewContext';
import { get_common_storage_data } from '../../Controller/actions';
import CanvasJSReact from '@canvasjs/react-charts';
import { Button, Spinner } from '@material-tailwind/react';
import { RiDeleteBinLine } from 'react-icons/ri';

export const Storage = ({ title }) => {
  const { permissions, tokenType, isLoading, setIsLoading, currentPageData, setDeleteUpdateItem, setCurrentPageData, handleDeleteAll, deleteUpdateItem } = useContext(NewContext);
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  // const [demo, setdemo] =useState([])
  const getCommonStorageData = async () => {
    setIsLoading(true);
    try {

      const getStorage = await get_common_storage_data();

      if (getStorage) {
        const { status, data } = getStorage;
        if (status) {
          setCurrentPageData(data);
          // setdemo(demo1 => [...demo1,data[0]])
          // setdemo(demo1 => [...demo1,data[0]])
        } else {
          setCurrentPageData([]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCommonStorageData();
  }, [deleteUpdateItem, pagePermissions]);

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Storage');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])

  useEffect(() => {
    document.title = title;
  }, [title])
  return (
    <>
      {

        !isLoading ?
          <div className="grid grid-cols-12 lg:grid-cols-12 gap-2 mt-5">
            {
              currentPageData?.map((_ele, i) => {
                return (
                  <>
                    <div className="col-span-4" >
                      <div className="bg-white rounded-xl p-5 shadow-lg flex flex-col justify-center">
                        {pagePermissions?.D ?
                          <div className="">
                            <Button className='bg-transparent p-2 outline-1 outline outline-red-400 text-red-400 shadow-none hover:shadow-none font-medium text-base capitalize lg:text-[14px]'
                              onClick={
                                () => {
                                  handleDeleteAll("remove-common-storage")
                                  setDeleteUpdateItem({ label: _ele?.label });
                                }

                              }><RiDeleteBinLine className='text-xl' /></Button>
                          </div> : ''}
                        <CanvasJSChart options={{
                          animationEnabled: true,
                          backgroundColor: "transparent",
                          width: 450,
                          height: 400,
                          title: {
                            text: _ele.label
                          },
                          subtitles: [{
                            text: _ele && _ele?.data?.title,
                            verticalAlign: "center",
                            fontSize: 18,
                            maxWidth: 120,
                            wrap: true,
                            dockInsidePlotArea: true
                          }],
                          data: [{
                            type: "doughnut",
                            showInLegend: false,
                            indexLabel: "{name}: {y}",
                            yValueFormatString: "#,###'%'",
                            dataPoints: [
                              { name: _ele && _ele.data?.extra?.value, y: _ele && (Math.round(_ele.data?.extra.y) === 0 ? Math.ceil(_ele.data?.extra.y) : Math.round(_ele.data?.extra.y)) },
                              { name: _ele && _ele.data?.inActive?.value, color: "red", y: _ele && (Math.round(_ele.data?.inActive.y) === 0 ? Math.ceil(_ele.data?.inActive.y) : Math.round(_ele.data?.inActive.y)) },
                              { name: _ele && _ele.data?.active?.value, color: "green", y: _ele && (Math.round(_ele.data?.active.y) === 0 ? Math.ceil(_ele.data?.active.y) : Math.round(_ele.data?.active.y)) },
                            ]
                          }]
                        }}
                        />

                      </div>
                    </div>
                  </>
                )
              })
            }
          </div> : <Spinner className="h-4 w-4" />
      }
    </>
  )
}
Storage.defaultProps = {
  title: "My Photo Keyboard | Storage"
};


export default Storage
